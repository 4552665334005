import gql from 'graphql-tag';

export type PeriodType = 'hourly' | 'daily' | 'weekly' | 'monthly';

// #region Sales Trend Types

// #region Trend Order Count Types
export type TrendOrderCount = {
  new_count: number;
  received_count: number;
  canceled_count: number;
  in_process_count: number;
  __typename?: string;
};

export type GetTrendOrderCountsParams = {
  startDate?: string;
  endDate?: string;
  segment?: string;
  depot?: string;
  sku?: string[];
  periodType?: PeriodType;
};
// #endregion Trend Order Count Types

// #region Trend User Transaction Types
export type TrendUserTransactionItem = {
  transaction_price: number;
  date: string;
  transaction_price_avg: number;
  qty_transaction: number;
  qty_transaction_avg: number;
};

export type TrendUserTransaction = {
  online: TrendUserTransactionItem[];
  offline: TrendUserTransactionItem[];
  __typename?: string;
};

export type GetTrendOrderCountsResult = {
  trendOrderCounts: TrendOrderCount;
};

export type GetTrendUserTransactionResult = {
  trendUserTransactions: TrendUserTransaction;
};

export type GetTrendUserTransactionParams = {
  startDate?: string;
  endDate?: string;
  segment?: string;
  depot?: string;
  sku?: string[];
  periodType?: PeriodType;
};
// #endregion Trend User Transaction Types

// #region Sales Percentage Based On Segmentation Types
export type SalesPercentageBasedOnSegmentation = {
  productID: string;
  title: string;
  percentage: number;
  sales_amount: number;
};

export type GetSalesPercentageBasedOnSegmentationParams = Omit<
  GetTrendUserTransactionParams,
  'periodType'
>;

export type GetSalesPercentageBasedOnSegmentation = {
  salesPercentageBasedOnSegmentation: SalesPercentageBasedOnSegmentation[];
};
// #endregion Sales Percentage Based On Segmentation Types

// #region Canceled Transaction Types
export type TotalPriceOfTheCanceledTransaction = {
  transaction_date: string;
  final_price: number;
};

export type TotalProductQuantityOfTheCanceledTransaction = {
  transaction_date: string;
  total_product_quantity: number;
};

export type GetTotalCanceledTransactionResult = {
  totalPriceOfTheCanceledTransaction: TotalPriceOfTheCanceledTransaction[];
  totalProductQuantityOfTheCanceledTransaction: TotalProductQuantityOfTheCanceledTransaction[];
};
// #endregion Canceled Transaction Types

// #endregion Sales Trend Types

export type TrendUserRepetitiveTransactions = {
  date: number;
  user_repeat_transaction_count: number;
  __typename?: string;
};

export type TrendUserRegisteredCustomerCount = {
  date: number;
  registered_count: number;
  active_user_count: number;
  __typename?: string;
};

export type GetTrendUserTransactionRepetitiveResult = {
  trendUserRepeatTransactions: TrendUserRepetitiveTransactions[];
};

export type GetTrendUserRegisteredCustomerCountResult = {
  trendUserRegisteredCustomerCount: TrendUserRegisteredCustomerCount[];
};

export type GetTrendUserTransactionRepetitiveParams = {
  startDate?: string;
  endDate?: string;
  segment?: string;
  depot?: string;
  periodType?: PeriodType;
};

export type PerformanceTeam = {
  averageApprovalTime: number;
  averageDeliveryTime: number;
  deliverySuccessRate: number;
  userSatisfactionRate: number;
  sameDayDeliveryCount: number;
  oneDayDeliveryCount: number;
  twoDayDeliveryCount: number;
  threeDayDeliveryCount: number;
  fourDayDeliveryCount: number;
  oneStarRatingCount: number;
  twoStarRatingCount: number;
  threeStarRatingCount: number;
  fourStarRatingCount: number;
};

export type GetPerformanceTeamResult = {
  getPerformanceTeam: PerformanceTeam;
};

export type GetPerformanceTeamParams = {
  where: {
    deliveredDate_gte: string;
    deliveredDate_lte: string;
    depot?: {
      id: string;
    };
  };
};

export type GetTrendUserRepetitiveTransactionParams = {
  startDate?: string;
  endDate?: string;
  segment?: string;
  depot?: string;
  periodType?: PeriodType;
};

export type GetTrendUserRegisteredCustomerCountParams = GetTrendUserRepetitiveTransactionParams;

// #region Sales Trend Query
export const GET_TREND_ORDER_COUNTS = gql`
  query getTrendOrderCounts(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $sku: [String!]
    $periodType: periodType!
  ) {
    trendOrderCounts(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      products: $sku
      periodType: $periodType
    ) {
      new_count
      received_count
      canceled_count
      in_process_count
    }
  }
`;

export const GET_TREND_USER_TRANSACTION = gql`
  query getTrendUserTransactions(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $sku: [String!]
    $periodType: periodType
  ) {
    trendUserTransactions(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      sku: $sku
      periodType: $periodType
    ) {
      online {
        transaction_price
        date
        transaction_price_avg
        qty_transaction
        qty_transaction_avg
      }
    }
  }
`;

export const GET_SALES_PERCENTAGE_BASED_ON_SEGMENTATION = gql`
  query getSalesPercentageBasedOnSegmentation(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $sku: [String!]
  ) {
    salesPercentageBasedOnSegmentation: getSalesPercentageBasedOnSegmentation(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      products: $sku
    ) {
      productID
      title
      percentage
      sales_amount
    }
  }
`;

export const GET_TOTAL_CANCELED_TRANSACTION = gql`
  query getTotalCanceledTransaction(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $sku: [String!]
    $periodType: periodType!
  ) {
    totalProductQuantityOfTheCanceledTransaction: getTotalProductQuantityOfTheCanceledTransaction(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      products: $sku
      periodType: $periodType
    ) {
      transaction_date
      total_product_quantity
    }

    totalPriceOfTheCanceledTransaction: getTotalPriceOfTheCanceledTransaction(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      products: $sku
      periodType: $periodType
    ) {
      transaction_date
      final_price
    }
  }
`;
// #endregion

export const GET_PERFORMANCE_TEAM = gql`
  query getPerformanceTeam($where: TransactionWhereInput) {
    getPerformanceTeam(where: $where) {
      averageApprovalTime
      averageDeliveryTime
      deliverySuccessRate
      userSatisfactionRate
      sameDayDeliveryCount
      oneDayDeliveryCount
      twoDayDeliveryCount
      threeDayDeliveryCount
      fourDayDeliveryCount
      oneStarRatingCount
      twoStarRatingCount
      threeStarRatingCount
      fourStarRatingCount
    }
  }
`;

export const GET_TREND_USER_REGISTERED_CUSTOMER_COUNT = gql`
  query getTrendUserRegisteredCustomerCount(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $periodType: periodType!
  ) {
    trendUserRegisteredCustomerCount(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      periodType: $periodType
    ) {
      date
      registered_count
      active_user_count
    }
  }
`;

export const GET_TREND_USER_TRANSACTION_REPETITIVE = gql`
  query getTrendUserRepeatTransactions(
    $startDate: String!
    $endDate: String!
    $segment: String
    $depot: String
    $periodType: periodType!
  ) {
    trendUserRepeatTransactions(
      startDate: $startDate
      endDate: $endDate
      segment: $segment
      depot: $depot
      periodType: $periodType
    ) {
      date
      user_repeat_transaction_count
    }
  }
`;
