import React from 'react';

import { ToastConsumer } from '../core-ui/ToastContext';

export type ToastContextProps = {
  openToast: (variant: ToastVariant, message: string) => void;
  closeToast: () => void;
};

export default function withToast<P extends ObjectKey>(
  Component: React.ComponentType<P>,
) {
  return (props: P) => {
    return (
      <ToastConsumer>
        {(toastContext) => (
          <Component
            openToast={toastContext && toastContext.openToast}
            closeToast={toastContext && toastContext.closeToast}
            {...props}
          />
        )}
      </ToastConsumer>
    );
  };
}
