import React, { Component, ComponentType } from 'react';

import { Mutation } from 'react-apollo';
import { View, StyleSheet } from 'react-native';
import { RouteComponentProps } from 'react-router';

import {
  Card,
  Text,
  TextField,
  DatePickerField,
  Dropdown,
  CurrencyField,
  RadioButton,
} from '../../core-ui';
import { FormPage } from '../../components';
import { GRAY70 } from '../../constants/colors';
import { isObjectEqual } from '../../helpers';
import {
  ASSIGN_TOP,
  AssignTopVars,
  TopInvoiceType,
  TopPaymentPeriod,
  AccessProps,
} from '../../graphql/queries';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import { NotFoundPage } from '..';

type Props = AccessProps &
  RouteComponentProps<{ id: string }> &
  ToastContextProps;
type State = {
  contractID: string;
  contractDate: Nullable<Date>;
  description: string;
  top: Nullable<TopPaymentPeriod>;
  credit: boolean;
  creditLimit: number;
  invoice: Nullable<TopInvoiceType>;
};

// TODO: make sure these are constant or remove it later
export const TOP_OPTIONS = [
  { label: '0 Hari', value: 'DAY0' as TopPaymentPeriod },
  { label: '7 Hari', value: 'DAY7' as TopPaymentPeriod },
  { label: '10 Hari', value: 'DAY10' as TopPaymentPeriod },
  { label: '15 Hari', value: 'DAY15' as TopPaymentPeriod },
  { label: '20 Hari', value: 'DAY20' as TopPaymentPeriod },
  { label: '30 Hari', value: 'DAY30' as TopPaymentPeriod },
  { label: '40 Hari', value: 'DAY40' as TopPaymentPeriod },
  { label: '45 Hari', value: 'DAY45' as TopPaymentPeriod },
];
export const INVOICE_OPTIONS = [
  { label: 'Inv - 1 - Akhir Bulan', value: 'MONTHLY' as TopInvoiceType },
  { label: 'Inv - Langsung', value: 'INSTANT' as TopInvoiceType },
  // NOTE: informed by the client that only Akhir Bulan and Langsung are needed
  // {label: 'Inv - Agen - Proses Invoice Agen', value: 'AGENT' as TopInvoiceType},
  // {
  //   label: 'Inv - Harian - Proses Invoice Harian',
  //   value: 'DAILY' as TopInvoiceType,
  // },
];

export class SelectAsTopScene extends Component<Props, State> {
  _initialState: State = {
    contractID: '',
    contractDate: null,
    description: '',
    top: null,
    credit: true,
    creditLimit: 0,
    invoice: null,
  };

  state = this._initialState;

  render() {
    const {
      location: { state },
      history,
      access,
      match: {
        params: { id },
      },
      openToast,
    } = this.props;
    const {
      contractID,
      contractDate,
      description,
      top,
      credit,
      creditLimit,
      invoice,
    } = this.state;

    if (!access.read || !access.update) {
      return <NotFoundPage />;
    }

    return (
      <Mutation<{}, AssignTopVars>
        mutation={ASSIGN_TOP}
        onCompleted={() => {
          openToast('success', 'Pelanggan telah dipilih menjadi anggota TOP');
          history.goBack();
        }}
        onError={() =>
          openToast('fail', 'Pelanggan gagal dipilih menjadi anggota TOP')
        }
      >
        {(assignTop, { loading }) => (
          <FormPage
            showBackButton
            isSubmitLoading={loading}
            isDirty={this._isDirty()}
            isValid={this._isValid()}
            handleSubmit={() =>
              contractDate &&
              top &&
              invoice &&
              assignTop({
                variables: {
                  id,
                  data: {
                    contractNumber: contractID,
                    contractStart: contractDate,
                    details: description,
                    paymentPeriod: top,
                    isCredit: credit,
                    creditLimit,
                    invoiceType: invoice,
                  },
                },
              })
            }
            handleDiscard={() => this.setState(this._initialState)}
            handleGoBack={() => history.goBack()}
          >
            <Card title="Pilih Sebagai Pelanggan TOP">
              <TextField
                disabled
                stretch
                labelHorizontal
                label="Kode Pelanggan"
                value={state.szID || '-'}
              />
              <TextField
                stretch
                labelHorizontal
                label="Nomor Kontrak"
                placeholder="Nomor Kontrak"
                value={contractID}
                onChangeText={(text) => this._onValueChange(text, 'contractID')}
              />
              <DatePickerField
                label="Tanggal Kontrak"
                labelHorizontal
                placeholder="Tanggal Kontrak"
                selectedDate={contractDate}
                onChange={(value: Date) => {
                  this._onValueChange(value, 'contractDate');
                }}
                style={{ zIndex: 3 }}
              />
              <TextField
                stretch
                multiline
                labelHorizontal
                label="Deskripsi Kontrak"
                placeholder="Deskripsi Kontrak"
                value={description}
                onChangeText={(text) =>
                  this._onValueChange(text, 'description')
                }
              />
              <Dropdown<TopPaymentPeriod>
                labelHorizontal
                label="Tempo Pembayaran"
                placeholder="Tempo Pembayaran"
                selectedOption={top}
                options={TOP_OPTIONS}
                onChange={(selectedOption) =>
                  this._onValueChange(
                    selectedOption && selectedOption.value,
                    'top',
                  )
                }
                style={{ zIndex: 2 }}
              />
              <View style={styles.creditRow}>
                <View style={styles.labelHorizontal}>
                  <Text size="small" color={GRAY70}>
                    Kredit
                  </Text>
                </View>
                <View style={styles.radioContainer}>
                  <View style={styles.flex}>
                    <RadioButton<boolean>
                      label="Kredit"
                      value
                      selected={credit}
                      onSelect={(v) => this.setState({ credit: v })}
                    />
                  </View>
                  <View style={styles.flex}>
                    <RadioButton<boolean>
                      label="Tidak Kredit"
                      value={false}
                      selected={!credit}
                      onSelect={(v) =>
                        this.setState({ credit: v, creditLimit: 0 })
                      }
                    />
                  </View>
                </View>
              </View>
              <CurrencyField
                stretch
                labelHorizontal
                disabled={!credit}
                editable={credit}
                label="Plafon Kredit"
                currencyValue={creditLimit}
                onCurrencyChange={(value) =>
                  this._onValueChange(value, 'creditLimit')
                }
              />
              <Dropdown<TopInvoiceType>
                labelHorizontal
                label="Pemrosesan Invoice"
                placeholder="Pemrosesan Invoice"
                selectedOption={invoice}
                options={INVOICE_OPTIONS}
                onChange={(selectedOption) =>
                  this._onValueChange(
                    selectedOption && selectedOption.value,
                    'invoice',
                  )
                }
                style={{ zIndex: 1 }}
              />
            </Card>
          </FormPage>
        )}
      </Mutation>
    );
  }

  _isDirty = () => !isObjectEqual(this._initialState, this.state);

  _isValid = () => {
    const {
      contractID,
      contractDate,
      description,
      top,
      credit,
      creditLimit,
      invoice,
    } = this.state;
    return (
      contractID !== '' &&
      contractDate != null &&
      description !== '' &&
      top != null &&
      ((credit && creditLimit !== 0) || !credit) &&
      invoice != null
    );
  };

  _onValueChange = <K extends keyof State>(value: State[K], field: K) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  creditRow: { flexDirection: 'row', marginBottom: 32 },
  labelHorizontal: { flex: 1, paddingVertical: 8 },
  radioContainer: { flex: 2, flexDirection: 'row' },
});

export default withToast(SelectAsTopScene) as ComponentType<{}>;
