import {
  Box,
  createStyles,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { PointTooltip } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import { PaperBox, Query } from 'components';
import { BLUE, GUARDSMAN_RED, YELLOW, ZOMBIE_GREEN } from 'constants/colors';
import { Loading, Text, Icon } from 'core-ui';
import { addDays, endOfMonth, format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import {
  GET_SALES_PERCENTAGE_BASED_ON_SEGMENTATION,
  GET_TOTAL_CANCELED_TRANSACTION,
  GET_TREND_ORDER_COUNTS,
  GET_TREND_USER_TRANSACTION,
  GetSalesPercentageBasedOnSegmentation,
  GetSalesPercentageBasedOnSegmentationParams,
  GetTotalCanceledTransactionResult,
  GetTrendOrderCountsParams,
  GetTrendOrderCountsResult,
  GetTrendUserTransactionParams,
  GetTrendUserTransactionResult,
  TrendOrderCount,
  TrendUserTransaction,
  TrendUserTransactionItem,
} from 'graphql/queries';
import { capitalize, formatThousandSeparator, omit, pick } from 'helpers';
import { convertToDecimal } from 'helpers/formatNumber';
import React, { useCallback, useMemo, useState } from 'react';
import {
  BarChart,
  DEFAULT_FILTER_STATE,
  Filter,
  LineChart,
  LineChartProps,
  PieChart,
} from '../components';
import { FilterValues } from '../context';
import { useDateValues } from '../hooks';

type TrendOrderCountChartData = {
  id: string;
  label: string;
  value: number | boolean;
  color: string;
};

const TREND_ORDER_COUNTS_MAP_DATA = {
  new_count: ['Pesanan Baru', BLUE],
  in_process_count: ['Pesanan Diproses', YELLOW],
  received_count: ['Pesanan Sampai', ZOMBIE_GREEN],
  canceled_count: ['Pesanan Dibatalkan', GUARDSMAN_RED],
};

const useStyles = makeStyles(
  createStyles({
    gap: {
      gap: '4px',
    },
    chartBox: {
      gap: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
    },
  }),
);

export function SalesTrend() {
  const classNames = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(810, 1200));

  const [filter, setFilter] = useState<FilterValues>(DEFAULT_FILTER_STATE);

  const dateValues = useDateValues(
    pick(filter, ['startDate', 'endDate', 'periodTime']),
  );

  const isSameDay = filter.startDate?.getTime() === filter.endDate?.getTime();

  const formatChartDate = useCallback(
    (date) => {
      if (filter.periodTime === 'daily') {
        return isSameDay
          ? new Date(date).toLocaleTimeString('id', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
          : new Date(date).toLocaleDateString('id', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            });
      } else if (filter.periodTime === 'weekly') {
        const startdate = new Date(date).toLocaleDateString('id', {
          day: 'numeric',
        });
        const enddate = new Date(addDays(date, 6)).toLocaleDateString('id', {
          day: 'numeric',
        });
        const startmonth = new Date(date).toLocaleDateString('id', {
          month: 'short',
        });
        const endmonth = new Date(addDays(date, 6)).toLocaleDateString('id', {
          month: 'short',
        });
        const year = new Date(date).getFullYear();
        const sameMonth = startmonth === endmonth;

        return `${startdate} ${
          sameMonth ? '' : startmonth
        } - ${enddate} ${endmonth} ${year}`;
      } else {
        return new Date(date).toLocaleDateString('id', {
          month: 'long',
          year: 'numeric',
        });
      }
    },
    [filter.periodTime, isSameDay],
  );

  const Tooltip: (isSameDay: boolean) => PointTooltip = () => ({ point }) => {
    return (
      <BasicTooltip
        id={formatChartDate(new Date(point.data.x))}
        color={point.borderColor}
        value={convertToDecimal(Number(point.data.y), 1)}
      />
    );
  };

  const commonLineChartProps: Omit<LineChartProps, 'data'> = {
    margin: {
      left: 80,
      right: 80,
      top: 50,
      bottom: 80,
    },
    legends: [
      {
        anchor: 'bottom',
        direction: 'row',
        itemWidth: 100,
        itemHeight: 25,
        translateY: 75,
        symbolShape: 'circle',
      },
    ],
    xScale: {
      type: 'point',
    },
    axisLeft: {
      format: (value) => formatThousandSeparator(value),
    },
    axisBottom: {
      format: (date) => formatChartDate(date),
      tickRotation: filter.periodTime === 'weekly' ? 15 : 45,
    },
    tooltip: Tooltip(isSameDay && filter.periodTime !== 'monthly'),
  };

  const onFilter = useCallback((values: FilterValues) => {
    setFilter(values);
  }, []);

  const handleTrendOrderCountsData = useCallback(
    (data?: TrendOrderCount): TrendOrderCountChartData[] => {
      if (!data) {
        return [
          {
            id: 'Fill the filter first',
            label: 'Fill the filter first',
            value: true,
            color: BLUE,
          },
        ];
      }

      const fixedData = {
        new_count: data.new_count,
        in_process_count: data.in_process_count,
        received_count: data.received_count,
        canceled_count: data.canceled_count,
        __typename: data.__typename,
      };

      return Object.entries(omit(fixedData, ['__typename'])).map(
        ([key, value]) => {
          const dataKey = key as keyof Omit<TrendOrderCount, '__typename'>;

          return {
            id: TREND_ORDER_COUNTS_MAP_DATA[dataKey][0],
            label: TREND_ORDER_COUNTS_MAP_DATA[dataKey][0],
            value,
            color: TREND_ORDER_COUNTS_MAP_DATA[dataKey][1],
          };
        },
      );
    },
    [],
  );

  const handleTrendUserTransactionData = useCallback(
    (data?: TrendUserTransaction) => (type: keyof TrendUserTransactionItem) => {
      if (!data) {
        return [];
      }

      const mapColors = {
        offline: GUARDSMAN_RED,
        online: ZOMBIE_GREEN,
        total: BLUE,
      };

      return Object.entries(omit(data, ['__typename'])).map(([key, value]) => {
        return {
          id: capitalize(key),
          color: mapColors[key as keyof typeof mapColors],
          data: value
            .map((item) => pick(item, ['date', type]))
            .map((item) => {
              return {
                x: new Date(
                  new Date(item.date).getTime() -
                    getTimezoneOffset('Asia/Jakarta'),
                ),
                y: item[type],
              };
            }),
        };
      });
    },
    [],
  );

  const { startDateVariable, endDateVariable } = useMemo(() => {
    if (!filter.startDate || !filter.endDate) {
      return {
        startDateVariable: undefined,
        endDateVariable: undefined,
      };
    }
    const startDateVariable = format(filter.startDate, 'yyyy-MM-dd HH:mm:ss');
    let endDateVariable;

    if (filter.periodTime === 'monthly') {
      endDateVariable = format(
        endOfMonth(filter.endDate),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else if (filter.periodTime === 'weekly') {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    } else if (filter.periodTime === 'daily') {
      endDateVariable = format(
        addDays(filter.endDate, 1),
        'yyyy-MM-dd HH:mm:ss',
      );
    } else {
      endDateVariable = format(filter.endDate, 'yyyy-MM-dd HH:mm:ss');
    }

    return {
      startDateVariable,
      endDateVariable,
    };
  }, [filter.endDate, filter.startDate, filter.periodTime]);

  const generateLabel = (percentage: number, amount: number) => {
    return `${convertToDecimal(percentage)}% • Qty total ${amount}`;
  };

  const generateYAxisLabel = (value: string) => {
    const minWord = 3;
    const words = value.split(' ');

    if (words.length <= minWord) {
      return (
        <tspan x="0" dy={4}>
          {value}
        </tspan>
      );
    }

    let firstWord: string = '';
    let secondWord: string = '';

    words.forEach((value, i) => {
      if (i < minWord) {
        firstWord += ' ' + value;
      } else {
        secondWord += ' ' + value;
      }
    });

    const allWords = [firstWord, secondWord];

    return (
      <>
        {allWords.map((value, i) => {
          return (
            <tspan x="0" dy={i === 0 ? 0 : 12}>
              {value}
            </tspan>
          );
        })}
      </>
    );
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      style={{
        gap: '2rem',
      }}
    >
      <Filter withSegmentationSelection withSKUSelection onFilter={onFilter} />
      {filter.startDate && filter.endDate ? (
        <>
          <PaperBox padding={4} elevation={0}>
            <Text weight="bold">Jumlah Pesanan</Text>
            <Query<GetTrendOrderCountsResult, GetTrendOrderCountsParams>
              query={GET_TREND_ORDER_COUNTS}
              variables={{
                startDate: startDateVariable,
                endDate: endDateVariable,
                depot: filter.depot ? filter.depot : undefined,
                segment: filter.segmentation ? filter.segmentation : undefined,
                periodType:
                  filter.startDate?.getTime() === filter.endDate?.getTime() &&
                  filter.periodTime === 'daily'
                    ? 'hourly'
                    : filter.periodTime,
                sku:
                  [...(filter.productIds || [])].length > 0
                    ? filter.productIds
                    : undefined,
              }}
              skip={!filter.startDate || !filter.endDate}
            >
              {({ data }) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={matches ? 'column' : 'row'}
                  style={{
                    gap: '32px',
                  }}
                  mt={4}
                >
                  <Box
                    width={matches ? '100%' : '50%'}
                    height={300}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {handleTrendOrderCountsData(data?.trendOrderCounts).every(
                      (item) => item.value === 0,
                    ) ? (
                      <>
                        <Icon isCustomSVG name="emptyData" size="xxlarge" />
                        <Text size="small">
                          Tidak ada data untuk ditampilkan
                        </Text>
                      </>
                    ) : (
                      <PieChart
                        data={handleTrendOrderCountsData(
                          data?.trendOrderCounts,
                        )}
                        margin={{
                          right: 140,
                        }}
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            itemWidth: 100,
                            itemHeight: 25,
                            translateX: 90,
                          },
                        ]}
                      />
                    )}
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={
                      data?.trendOrderCounts ? '1fr 1fr' : '1fr'
                    }
                    gridTemplateRows={
                      data?.trendOrderCounts ? '1fr 1fr' : '1fr'
                    }
                    gridGap={10}
                    flex={1}
                  >
                    {handleTrendOrderCountsData(data?.trendOrderCounts).map(
                      (data) => (
                        <Box
                          border={`2px solid ${data.color}`}
                          padding={4}
                          borderRadius={4}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                          className={classNames.gap}
                        >
                          <Text weight="light" size="small">
                            {data.label}
                          </Text>
                          <Text weight="bold">{data.value}</Text>
                        </Box>
                      ),
                    )}
                  </Box>
                </Box>
              )}
            </Query>
          </PaperBox>
          <Query<GetTrendUserTransactionResult, GetTrendUserTransactionParams>
            query={GET_TREND_USER_TRANSACTION}
            variables={{
              startDate: startDateVariable,
              endDate: endDateVariable,
              depot: filter.depot ? filter.depot : undefined,
              segment: filter.segmentation ? filter.segmentation : undefined,
              periodType:
                filter.startDate?.getTime() === filter.endDate?.getTime() &&
                filter.periodTime === 'daily'
                  ? 'hourly'
                  : filter.periodTime,
              sku:
                [...(filter.productIds || [])].length > 0
                  ? filter.productIds
                  : undefined,
            }}
            fetchPolicy="cache-and-network"
          >
            {({ data }) => {
              const getTrendUser = handleTrendUserTransactionData(
                data?.trendUserTransactions,
              );

              return (
                <Box
                  display="grid"
                  gridTemplateColumns={matches ? '100%' : '49% 49%'}
                  gridTemplateRows="1fr 1fr"
                  gridGap={'1.4em'}
                >
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Total Penjualan Berdasarkan Rupiah
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={400}>
                      <LineChart
                        data={getTrendUser('transaction_price')}
                        {...commonLineChartProps}
                      />
                    </Box>
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Total Penjualan Berdasarkan Kuantitas
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={400}>
                      <LineChart
                        data={getTrendUser('qty_transaction')}
                        {...commonLineChartProps}
                      />
                    </Box>
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Rata-rata Nilai Transaksi per pesanan berdasarkan Rupiah
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={400}>
                      <LineChart
                        data={getTrendUser('transaction_price_avg')}
                        {...commonLineChartProps}
                      />
                    </Box>
                  </PaperBox>
                  <PaperBox className={classNames.chartBox} elevation={0}>
                    <Text size="large" weight="bold">
                      Rata-rata Nilai Transaksi per pesanan berdasarkan
                      Kuantitas
                    </Text>
                    <Text size="small" weight="light">
                      {dateValues}
                    </Text>
                    <Box width="100%" height={400}>
                      <LineChart
                        data={getTrendUser('qty_transaction_avg')}
                        {...commonLineChartProps}
                      />
                    </Box>
                  </PaperBox>
                </Box>
              );
            }}
          </Query>

          <PaperBox className={classNames.chartBox} elevation={0}>
            <Text size="large" weight="bold">
              Persentase Penjualan berdasarkan Segmentasi
            </Text>
            <Text size="small" weight="light">
              {dateValues}
            </Text>
            <Box width="100%" height={500}>
              <Query<
                GetSalesPercentageBasedOnSegmentation,
                GetSalesPercentageBasedOnSegmentationParams
              >
                query={GET_SALES_PERCENTAGE_BASED_ON_SEGMENTATION}
                variables={{
                  startDate: startDateVariable,
                  endDate: endDateVariable,
                  depot: filter.depot ? filter.depot : undefined,
                  segment: filter.segmentation
                    ? filter.segmentation
                    : undefined,
                  sku:
                    [...(filter.products || [])].length > 0
                      ? filter.products
                      : undefined,
                }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading }) => {
                  const salesPercentageData =
                    data?.salesPercentageBasedOnSegmentation &&
                    data?.salesPercentageBasedOnSegmentation?.length > 0
                      ? data?.salesPercentageBasedOnSegmentation
                          ?.map((data) => ({
                            ...data,
                            truncatedTitle: data.title,
                          }))
                          ?.sort((a, b) => a.percentage - b.percentage)
                      : [
                          {
                            title: 'Not Found Data',
                            sales_amount: 0,
                            truncatedTitle: 'Not Found Data',
                            percentage: 0.0,
                          },
                        ];

                  return loading ? (
                    <Loading />
                  ) : (
                    <BarChart
                      data={salesPercentageData}
                      indexBy="truncatedTitle"
                      margin={{ top: 50, bottom: 50, left: 150, right: 50 }}
                      padding={0.3}
                      layout="horizontal"
                      keys={['percentage']}
                      colors={[BLUE]}
                      label={(d) =>
                        generateLabel(d.data.percentage, d.data.sales_amount)
                      }
                      labelTextColor="#000"
                      axisLeft={{
                        tickSize: 0,
                        tickPadding: 15,
                        renderTick: (tick) => {
                          return (
                            <g transform={`translate(${tick.x},${tick.y})`}>
                              <text
                                alignmentBaseline="auto"
                                textAnchor={tick.textAnchor}
                                transform={`translate(${tick.textX},${tick.textY})`}
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: 10,
                                }}
                              >
                                {generateYAxisLabel(tick.value)}
                              </text>
                            </g>
                          );
                        },
                      }}
                      minValue={0}
                      maxValue={100}
                      axisBottom={{
                        format: (value) =>
                          formatThousandSeparator(Number(value)),
                      }}
                      theme={{
                        fontSize: 12,
                      }}
                      enableGridX
                      labelSkipHeight={10}
                      labelSkipWidth={120}
                      tooltip={({ data }) => (
                        <PaperBox
                          padding={1}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <Box>
                            <Text size="small" weight="bold">
                              {`Qty online: ${
                                data.sales_amount
                              } (${convertToDecimal(data.percentage)}%)`}
                            </Text>
                          </Box>
                        </PaperBox>
                      )}
                    />
                  );
                }}
              </Query>
            </Box>
          </PaperBox>
          <Query<
            GetTotalCanceledTransactionResult,
            GetTrendUserTransactionParams
          >
            query={GET_TOTAL_CANCELED_TRANSACTION}
            variables={{
              startDate: startDateVariable,
              endDate: endDateVariable,
              depot: filter.depot ? filter.depot : undefined,
              segment: filter.segmentation ? filter.segmentation : undefined,
              sku:
                [...(filter.products || [])].length > 0
                  ? filter.products
                  : undefined,
              periodType:
                filter.startDate?.getTime() === filter.endDate?.getTime() &&
                filter.periodTime === 'daily'
                  ? 'hourly'
                  : filter.periodTime,
            }}
          >
            {({ data }) => (
              <Box
                display="grid"
                gridTemplateColumns={matches ? '100%' : '49% 49%'}
                gridGap="2%"
              >
                <PaperBox className={classNames.chartBox} elevation={0}>
                  <Text size="large" weight="bold">
                    Nilai Pembatalan Berdasarkan Rupiah
                  </Text>
                  <Text size="small" weight="light">
                    {dateValues}
                  </Text>
                  <Box width="100%" height={400}>
                    <LineChart
                      data={[
                        {
                          id: 'Online',
                          color: ZOMBIE_GREEN,
                          data: [
                            ...(data?.totalPriceOfTheCanceledTransaction || []),
                          ].map((data) => ({
                            x: new Date(
                              new Date(data.transaction_date).getTime() -
                                getTimezoneOffset('Asia/Jakarta'),
                            ),
                            y: data.final_price,
                          })),
                        },
                      ]}
                      {...commonLineChartProps}
                    />
                  </Box>
                </PaperBox>
                <PaperBox className={classNames.chartBox} elevation={0}>
                  <Text size="large" weight="bold">
                    Nilai Pembatalan Berdasarkan Kuantitas
                  </Text>
                  <Text size="small" weight="light">
                    {dateValues}
                  </Text>
                  <Box width="100%" height={400}>
                    <LineChart
                      data={[
                        {
                          id: 'Online',
                          color: ZOMBIE_GREEN,
                          data: [
                            ...(data?.totalProductQuantityOfTheCanceledTransaction ||
                              []),
                          ].map((data) => ({
                            x: new Date(
                              new Date(data.transaction_date).getTime() -
                                getTimezoneOffset('Asia/Jakarta'),
                            ),
                            y: data.total_product_quantity,
                          })),
                        },
                      ]}
                      {...commonLineChartProps}
                    />
                  </Box>
                </PaperBox>
              </Box>
            )}
          </Query>
        </>
      ) : null}
    </Box>
  );
}
