import React, { Component, Fragment } from 'react';
import { View, ScrollView, StyleSheet, Image } from 'react-native';

import {
  Dropdown,
  Text,
  TextField,
  Modal,
  Separator,
  Tabs,
  Tab,
  Button,
} from '../../../core-ui';
import { formatFullDate, convertPascalCase } from '../../../helpers';
import { BLACK, DARK_GRAY } from '../../../constants/colors';

import {
  GET_USER_KTP_LOG,
  GET_REJECT_CATEGORIES,
} from '../queries/getUsersWithPendingKtp';
import { ModalType, User, UserKTP } from '../helpers/modalHelper';
import {
  RejectKtpButtonWithToast,
  ApproveKtpButtonWithToast,
} from '../mutations/ktpApprovalMutations';

import ImagePlaceholder from './ImagePlaceholder';
import { Query, MapView, SegmentPicker } from '../../../components';

const PERSONAL_FIELDS = {
  depotName: 'Nama Depo',
  szID: 'Kode Pelanggan',
  name: 'Nama Pelanggan',
  status: 'Status Pelanggan',
  phone: 'Nomor HP Pelanggan',
  email: 'Email Pelanggan',
  address: 'Alamat Pelanggan',
};

const STORE_FIELDS = {
  storeName: 'Nama Toko',
  storeAddress: 'Alamat Toko',
  storePhoto: 'Foto Toko', // TODO: Display Photo
  storeTelephone: 'Nomor Telepon Toko',
  province: 'Provinsi',
  city: 'Kota',
  district: 'Kelurahan',
  subDistrict: 'Kecamatan',
  postalCode: 'Kode Pos',
  map: 'Peta Lokasi',
};

type DocumentRejectCategory = {
  description: string;
};

type DocumentActionLog = {
  status: string;
  message: string;
  createdAt: string;
  rejectCategory: { description: string };
};

type Props = {
  modalType: ModalType;
  ktpData: Nullable<UserKTP>;
  userDetail: Nullable<User>;
  userId: Nullable<string>;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type State = {
  selectedDetailTab: number;
  rejectCategory: Nullable<string>;
  rejectMessage: string;
  segment: Nullable<string>;
  subSegment: Nullable<string>;
  szID: Nullable<string>;
};

export default class KtpModal extends Component<Props, State> {
  state: State = {
    selectedDetailTab: 0,
    rejectCategory: null,
    rejectMessage: '',
    segment: null,
    subSegment: null,
    szID: null,
  };

  render() {
    const { isOpen, modalType, userDetail } = this.props;
    const title = this._getModalTitle(modalType);
    const content = this._getModalContent(modalType);

    return (
      <Modal
        title={title}
        isVisible={isOpen}
        onClose={this._onClose}
        maxHeight={!!userDetail}
        hideHeaderClose={modalType === 'APPROVE' || modalType === 'REJECT'}
      >
        {content}
      </Modal>
    );
  }

  _getModalTitle = (modalType: ModalType) => {
    switch (modalType) {
      case 'KTP':
        return 'Informasi KTP';
      case 'REJECT':
        return 'Tolak KTP';
      case 'REVISION':
        return 'Revisi KTP';
      case 'APPROVE':
        return 'Terima KTP';
      case 'DETAIL':
        return 'Informasi Pelanggan';
      default:
        return '';
    }
  };

  _getModalContent = (modalType: ModalType) => {
    switch (modalType) {
      case 'KTP': {
        return this._renderKtpContent();
      }
      case 'REVISION': {
        return this._renderRevisionContent();
      }
      case 'REJECT': {
        return this._renderRejectContent();
      }
      case 'APPROVE': {
        return this._renderApproveContent();
      }
      default: {
        return this._renderUserContent();
      }
    }
  };

  _renderKtpContent() {
    const { ktpData } = this.props;
    if (ktpData) {
      const { name, storeAddress, ktpUrl } = ktpData;
      return (
        <>
          <Field label="Nama" value={convertPascalCase(name)} />
          {/* Commented for further discussion */}
          {/* <Field label="No. KTP" value={ktpNumber} />
          <Field
            label="Tempat/Tanggal Lahir"
            value={`${birthplace}, ${formatFullDate(new Date(birthdate))}`}
          /> */}
          <Field label="Alamat Toko" value={storeAddress} />
          <ImagePlaceholder size="large" imageUrl={ktpUrl} />
        </>
      );
    }
    return null;
  }

  _renderRevisionContent() {
    const { userId } = this.props;
    return (
      <>
        <View style={styles.titleRow}>
          <Text size="xsmall" color={DARK_GRAY} style={styles.leftColumn}>
            Tanggal
          </Text>
          <Text size="xsmall" color={DARK_GRAY} style={styles.rightColumn}>
            Revisi
          </Text>
        </View>
        <Separator style={{ marginVertical: 10 }} />
        <Query<{ userDocumentActionLogs: Array<DocumentActionLog> }>
          pollInterval={1000}
          query={GET_USER_KTP_LOG}
          variables={{ userId }}
        >
          {(result) => {
            const { data } = result;

            return data!.userDocumentActionLogs.map(
              (datum: DocumentActionLog, index: number) =>
                datum.status === 'REJECTED' && (
                  <View style={styles.row} key={index}>
                    <Text size="small" style={styles.leftColumn}>
                      {formatFullDate(new Date(datum.createdAt))}
                    </Text>
                    <View style={styles.rightColumn}>
                      <Text
                        size="small"
                        weight="bold"
                        primary
                        style={styles.bottomMargin}
                      >
                        {datum.rejectCategory &&
                          datum.rejectCategory.description}
                      </Text>
                      <Text size="small">{datum.message}</Text>
                    </View>
                  </View>
                ),
            );
          }}
        </Query>
      </>
    );
  }

  _renderRejectContent() {
    const { userId, onSuccess } = this.props;
    const { rejectMessage, rejectCategory } = this.state;
    return (
      <>
        <View style={styles.rejectModalTextfield}>
          <Query<{ documentRejectCategories: Array<{ description: string }> }>
            query={GET_REJECT_CATEGORIES}
            fetchPolicy="network-only"
          >
            {({ data }) => {
              const options = data!.documentRejectCategories.map(
                ({ description }: DocumentRejectCategory) => ({
                  label: description,
                  value: description,
                }),
              );

              return (
                <Dropdown
                  label="Kategori Alasan"
                  placeholder="Kategori Alasan"
                  selectedOption={rejectCategory}
                  options={options}
                  onChange={(selectedOption) =>
                    this._onChangeText(
                      'rejectCategory',
                      selectedOption && selectedOption.value,
                    )
                  }
                  style={styles.dropdown}
                />
              );
            }}
          </Query>
          <TextField
            stretch
            multiline
            numberOfLines={5}
            label="Alasan Penolakan"
            placeholder="Alasan Penolakan"
            value={rejectMessage}
            onChangeText={(text: string) =>
              this._onChangeText('rejectMessage', text)
            }
            style={styles.bottomMargin}
          />
        </View>
        <View style={[styles.flexRow, styles.flexEnd]}>
          <Button
            inverted
            color="secondary"
            text="Batal"
            onPress={this._onClose}
            style={styles.rightMargin}
          />
          <RejectKtpButtonWithToast
            disabled={rejectMessage === '' || !rejectCategory}
            userId={userId || ''}
            category={rejectCategory || ''}
            message={rejectMessage}
            closeModal={this._onClose}
            onSuccess={onSuccess}
          />
        </View>
      </>
    );
  }

  _renderApproveContent() {
    const { userId, onSuccess } = this.props;
    const { segment, subSegment, szID } = this.state;

    return (
      <>
        <View style={styles.approveKtp}>
          <Text size="small" weight="reg" color={DARK_GRAY}>
            Pilih segmentasi untuk user.
          </Text>
          <SegmentPicker
            containerStyle={styles.segmentPicker}
            selectedSegment={segment}
            selectedSubSegment={subSegment}
            onSegmentChange={(selected) =>
              this.setState({
                segment: selected ? selected.value : selected,
              })
            }
            onSubSegmentChange={(selected) =>
              this.setState({
                subSegment: selected ? selected.value : selected,
              })
            }
          />
          <TextField
            stretch
            labelHorizontal
            label="Kode Pelanggan"
            placeholder="Kode Pelanggan"
            value={szID || ''}
            onChangeText={(input) => this.setState({ szID: input })}
            style={{ container: { zIndex: -1 } }}
          />
        </View>
        <View style={[styles.flexRow, styles.flexEnd]}>
          <Button
            inverted
            color="secondary"
            text="Batal"
            onPress={this._onClose}
            style={styles.rightMargin}
          />
          <ApproveKtpButtonWithToast
            disabled={!segment || !subSegment || !szID}
            userId={userId || ''}
            segment={segment || ''}
            szID={szID || ''}
            subSegment={subSegment || ''}
            closeModal={this._onClose}
            onSuccess={onSuccess}
          />
        </View>
      </>
    );
  }

  _renderUserContent = () => {
    const { userDetail } = this.props;
    const { selectedDetailTab } = this.state;
    return (
      userDetail && (
        <Tabs
          selectedIndex={selectedDetailTab}
          onChange={(_e, index) => this.setState({ selectedDetailTab: index })}
        >
          <Tab label="Data Pelanggan">
            {this._renderTabDetailContent(PERSONAL_FIELDS, userDetail)}
          </Tab>
          <Tab label="Data Toko">
            {this._renderTabDetailContent(STORE_FIELDS, userDetail)}
          </Tab>
        </Tabs>
      )
    );
  };

  _renderTabDetailContent = (fields: ObjectKey, data: User) => (
    <ScrollView contentContainerStyle={styles.modalBody}>
      <View>{this._renderInfoFields(Object.values(fields))}</View>
      <View style={styles.infoSeparator}>
        {this._renderInfoSeparators(Object.keys(fields))}
      </View>
      <View style={styles.infoValue}>
        {this._renderInfoValues(data, Object.keys(fields) as Array<keyof User>)}
      </View>
    </ScrollView>
  );

  _renderInfoFields = (fields: Array<string>) =>
    fields.map((field, index) => (
      <View
        style={[
          styles.infoWrapper,
          ['Foto Toko', 'Peta Lokasi'].includes(field) && { height: 200 },
        ]}
        key={index}
      >
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          {field}
        </Text>
      </View>
    ));

  _renderInfoSeparators = (fields: Array<string>) =>
    fields.map((field, idx) => (
      <View
        style={[
          styles.infoWrapper,
          ['storePhoto', 'map'].includes(field) && { height: 200 },
        ]}
        key={idx}
      >
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          :
        </Text>
      </View>
    ));

  _renderInfoValues = (data: User, dataKeys: Array<keyof User>) =>
    dataKeys.map((dataKey, index) => {
      let content;
      switch (dataKey) {
        case 'storePhoto': {
          content = (
            <Image
              source={{ uri: data.storePhoto }}
              // TODO: adjust size as necessary
              style={{ width: 200, height: 200 }}
            />
          );
          break;
        }
        case 'map': {
          const { latitude, longitude } = data.map;
          content = (
            <View>
              <MapView
                markerPosition={{
                  lat: Number(latitude || -6.1753124),
                  lng: Number(longitude || 106.8260692),
                }}
              />
            </View>
          );
          break;
        }
        default: {
          content = (
            <Text size="small" color={BLACK} style={styles.infoText}>
              {dataKey === 'email'
                ? data[dataKey]
                : convertPascalCase(data[dataKey] || '')}
            </Text>
          );
        }
      }
      return (
        <View style={styles.infoWrapper} key={index}>
          {content}
        </View>
      );
    });

  _onChangeText = <T extends keyof State>(key: T, value: State[T]) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _onClose = () => {
    this.setState(
      {
        rejectCategory: null,
        rejectMessage: '',
        segment: null,
        subSegment: null,
        szID: null,
        selectedDetailTab: 0,
      },
      () => this.props.onClose(),
    );
  };
}

type FieldProps = {
  label: string;
  value: string;
};

function Field(props: FieldProps) {
  return (
    <View style={styles.row}>
      <Text size="small" color={DARK_GRAY} style={styles.leftColumn}>
        {props.label}
      </Text>
      <Text size="small" color={DARK_GRAY} style={styles.separator}>
        :
      </Text>
      <Text size="small" style={styles.rightColumn}>
        {props.value}
      </Text>
    </View>
  );
}

let styles = StyleSheet.create({
  rightMargin: { marginRight: 20 },
  titleRow: {
    flexDirection: 'row',
    paddingBottom: 4,
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 12,
  },
  leftColumn: {
    flex: 1,
    paddingRight: 10,
  },
  separator: {
    paddingRight: 20,
  },
  rightColumn: {
    flex: 2,
  },
  bottomMargin: {
    marginBottom: 8,
  },
  rejectModalTextfield: {
    flex: 1,
    paddingBottom: 30,
  },
  approveKtp: {
    zIndex: 2,
  },
  segmentPicker: { paddingTop: 20 },
  flexRow: {
    flexDirection: 'row',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  dropdown: {
    zIndex: 2,
    paddingBottom: 12,
  },
  modalBody: { flex: 1, flexDirection: 'row', paddingTop: 20 },
  modalFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 30,
  },
  infoWrapper: { minHeight: 40, justifyContent: 'flex-start' },
  infoText: { height: 22 },
  infoSeparator: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  infoValue: { flex: 1 },
});
