import React, { Component, Fragment } from 'react';
import { View, StyleSheet } from 'react-native';

import { Button, Text } from '../../core-ui';
import {
  Table,
  RowsPerPage,
  DepotPicker,
  DistrictPicker,
  SubDistrictPicker,
  Query,
  DEFAULT_ROWS_PER_PAGE,
  ResetFilterButton,
} from '../../components';
import {
  GET_USERS,
  GetUsersResult,
  GetUsersParams,
  PageAccess,
} from '../../graphql/queries';
import { BLACK } from '../../constants/colors';
import { fetchMoreItems } from '../../helpers';

type Props = {
  access: PageAccess;
  searchContent: string;
};

type State = {
  resetPage: boolean;
  rowsPerPage: RowsPerPage;
  selectedDepot: Nullable<string>;
  selectedDistrict: Nullable<string>;
  selectedSubDistrict: Nullable<string>;
  page: number;
};

export default class UserRejected extends Component<Props, State> {
  state: State = {
    resetPage: false,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    selectedDepot: null,
    selectedDistrict: null,
    selectedSubDistrict: null,
    page: 0,
  };

  componentDidUpdate(prevProps: Props) {
    const { searchContent: prevSearch } = prevProps;
    const { searchContent } = this.props;
    if (prevSearch !== searchContent) {
      this.setState({ resetPage: true });
    }
  }

  render() {
    return (
      <>
        {this._renderFilters()}
        {this._renderTable()}
      </>
    );
  }

  _createQueryWhere = () => {
    const { searchContent } = this.props;
    const searchText = searchContent.toLowerCase();
    const {
      selectedDepot = '',
      selectedDistrict = '',
      selectedSubDistrict = '',
    } = this.state;
    return {
      where: {
        approvalKtp: 'REJECTED',
        depot: {
          ...(selectedDepot ? { id_contains: selectedDepot } : {}),
          coverage_some: {
            ...(selectedDistrict
              ? { district: { id_contains: selectedDistrict } }
              : {}),
            ...(selectedSubDistrict
              ? { subDistrict: { id_contains: selectedSubDistrict } }
              : {}),
          },
        },
        OR: [
          { storeName_contains: searchText },
          { name_contains: searchText },
          { depot: { title_contains: searchText } },
          {
            location: {
              district: { name_contains: searchText },
              subDistrict: { name_contains: searchText },
            },
          },
        ],
      },
    };
  };

  _renderTable = () => {
    const { rowsPerPage, resetPage, page } = this.state;
    return (
      <Query<GetUsersResult, GetUsersParams>
        query={GET_USERS}
        variables={{
          ...this._createQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData="users"
        notifyOnNetworkStatusChange
      >
        {({ data, loading, fetchMore }) => {
          if (data) {
            return (
              <Table
                data={data.users}
                dataCount={data.count}
                resetPage={resetPage}
                setResetPage={(reset) => this.setState({ resetPage: reset })}
                isLoading={loading}
                page={page}
                onChangePage={(nextPage) => this.setState({ page: nextPage })}
                rowsPerPage={rowsPerPage}
                structure={{
                  name: {
                    headerTitle: 'Nama Pelanggan',
                  },
                  storeName: { headerTitle: 'Nama Toko' },
                  documentActionLogs: {
                    headerTitle: 'Kategori Ditolak',
                    render: ({ documentActionLogs }) => {
                      const { length } = documentActionLogs;
                      const lastLog =
                        length > 0 ? documentActionLogs[length - 1] : null;
                      const rejectCategory =
                        lastLog && lastLog.rejectCategory
                          ? String(
                              lastLog.rejectCategory.description,
                            ).toUpperCase()
                          : '-';
                      return (
                        <Text size="small" style={{ letterSpacing: 1.5 }}>
                          {rejectCategory}
                        </Text>
                      );
                    },
                  },
                  depo: {
                    headerTitle: 'Depo',
                    alias: 'depot.title',
                  },
                  district: {
                    headerTitle: 'Kecamatan',
                    alias: 'location.district.name',
                  },
                  subDisctrict: {
                    headerTitle: 'Kelurahan',
                    alias: 'location.subDistrict.name',
                  },
                }}
                loadMore={({ skip, first }) => {
                  fetchMoreItems<GetUsersResult, GetUsersParams>(fetchMore, {
                    query: GET_USERS,
                    variables: {
                      ...this._createQueryWhere(),
                      first,
                      skip,
                    },
                    dataKey: 'users',
                  });
                }}
                onChangeRowsPerPage={(amountRows) =>
                  this.setState({ rowsPerPage: amountRows, page: 0 })
                }
              />
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _renderFilters = () => {
    const { selectedDepot, selectedDistrict, selectedSubDistrict } = this.state;
    const { access } = this.props;
    return (
      <View style={styles.filters}>
        <View style={styles.filterRow}>
          <DepotPicker
            isFilter
            selectedOption={selectedDepot}
            onChange={(selected) =>
              this.setState({
                selectedDepot: selected ? selected.value : selected,
              })
            }
            style={[styles.filterMargin, { zIndex: 3 }]}
            hidden={access.role !== 'SUPER_ADMIN'}
          />
          <DistrictPicker
            isFilter
            selectedOption={selectedDistrict}
            onChange={(selected) => {
              const newSelected = selected ? selected.value : selected;
              this.setState({
                selectedDistrict: newSelected,
                selectedSubDistrict:
                  selectedDistrict !== newSelected ? null : selectedSubDistrict,
              });
            }}
            style={[styles.filterMargin, { zIndex: 2 }]}
          />
          <SubDistrictPicker
            isFilter
            district={selectedDistrict}
            selectedOption={selectedSubDistrict}
            onChange={(selected) =>
              this.setState({
                selectedSubDistrict: selected ? selected.value : selected,
              })
            }
          />
        </View>
        <ResetFilterButton onPress={this._clearFilter} />
      </View>
    );
  };

  _clearFilter = () => {
    this.setState({
      selectedDepot: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
    });
  };
}

const styles = StyleSheet.create({
  emptyScene: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    alignItems: 'flex-start',
    paddingVertical: 20,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterMargin: {
    marginRight: 15,
  },
  filterButtonRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
});
