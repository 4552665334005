export const COMPANY_INFO: any = {
  305: {
    name: 'PT Tirta Varia Inti Pratama',
    address: 'Pergudangan Alam Sutera Jl. Techno 8 NO.01 Pakulonan',
  },
  318: {
    name: 'PT Tirta Varia Inti Pratama',
    address: 'Jl. Manis II No. 10 Kawasan Industri Manis',
  },
  320: {
    name: 'PT Tirta Varia Inti Pratama',
    address: 'Jl. Diklat Pemda Kamp. Dukuh Pinang',
  },
};
