import React, { useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';

import { Text, Dropdown } from '../../core-ui';
import { Query } from '../../components';
import {
  PRICE_SEGMENTS_WITHOUT_PRODUCTS_QUERY,
  PRODUCT_PRICE_SEGMENTS_QUERY,
  PriceSegmentsWithoutProductsResult,
  ProductPriceSegmentsResult,
  ProductPriceSegmentVariables,
} from '../../graphql/queries';
import { formatThousandSeparator } from '../../helpers';

type Props = { productID: string };

export default function ProductPrice(props: Props) {
  const [selected, onSelect] = useState<Nullable<string>>(null);
  return (
    <Query<PriceSegmentsWithoutProductsResult>
      query={PRICE_SEGMENTS_WITHOUT_PRODUCTS_QUERY}
      variables={{ where: { priceSegmentCode_ends_with: '00' } }}
    >
      {({ data: priceSegments }) => {
        const priceSegmentOptions = priceSegments!.priceSegments.map(
          ({ title, priceSegmentCode }) => ({
            label: title,
            value: priceSegmentCode,
          }),
        );
        return (
          <View>
            <Dropdown
              placeholder="Segmentasi Harga"
              style={styles.dropdown}
              selectedOption={selected}
              options={priceSegmentOptions}
              onChange={(newSelection) => onSelect(newSelection!.value)}
            />
            <Query<ProductPriceSegmentsResult, ProductPriceSegmentVariables>
              query={PRODUCT_PRICE_SEGMENTS_QUERY}
              variables={{
                productGqlID: props.productID,
                ...(selected
                  ? { priceSegmentCode: selected.split('_')[0] }
                  : {}),
              }}
              skip={!selected}
            >
              {({ data: productPrice }) => {
                if (productPrice) {
                  return (
                    <FlatList
                      style={styles.spacing}
                      data={productPrice.productPriceSegments}
                      keyExtractor={({ id }) => String(id)}
                      ItemSeparatorComponent={() => (
                        <View style={styles.spacing} />
                      )}
                      renderItem={({ item }) => {
                        const {
                          priceSegment,
                          price,
                          product: { defaultPrice },
                        } = item;
                        return (
                          <View style={styles.priceRow}>
                            <Text size="small" style={styles.flex}>
                              {priceSegment.title.split('-').pop()}
                            </Text>
                            <Text size="small" style={styles.stripe}>
                              -
                            </Text>
                            <Text size="small" style={styles.flex}>
                              Rp.{' '}
                              {formatThousandSeparator(price || defaultPrice)}
                            </Text>
                          </View>
                        );
                      }}
                    />
                  );
                }
                return null;
              }}
            </Query>
          </View>
        );
      }}
    </Query>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  dropdown: { zIndex: 2 },
  spacing: { paddingTop: 5 },
  priceRow: { flexDirection: 'row', alignItems: 'center' },
  stripe: { paddingHorizontal: 5 },
});
