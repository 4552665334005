import React from 'react';

import {
  Snackbar,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { StyleSheet, View } from 'react-native';

import { Icon, Text } from '.';
import { GREEN, LIGHT_GREEN, LIGHT_RED, RED, WHITE } from '../constants/colors';

type OwnProps = {
  isOpen: boolean;
  variant: ToastVariant;
  message: string;
  closeToast: () => void;
};

type Props = WithStyles<typeof styles> & OwnProps;

function Toast(props: Props) {
  const {
    isOpen,
    variant,
    message,
    closeToast,
    classes,
    ...otherProps
  } = props;

  let actionColor = WHITE;
  let textStyle = nativeStyles.defaultText;
  let contentClass = {};
  if (variant === 'fail') {
    actionColor = RED;
    textStyle = nativeStyles.failText;
    contentClass = { root: classes.failContent };
  } else if (variant === 'success') {
    actionColor = GREEN;
    textStyle = nativeStyles.successText;
    contentClass = { root: classes.successContent };
  }
  const content = (
    <Text size="small" style={[nativeStyles.baseText, textStyle]}>
      {message}
    </Text>
  );

  return (
    <Snackbar
      action={
        <View style={{ position: 'absolute', top: 14, right: 24 }}>
          <Icon
            size="small"
            name="close"
            onPress={() => closeToast()}
            color={actionColor}
          />
        </View>
      }
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={3000}
      classes={{ root: classes.root }}
      key={message}
      message={content}
      onClose={
        typeof window.orientation !== 'undefined'
          ? () => setTimeout(() => closeToast(), 3000)
          : () => closeToast()
      }
      onExited={
        typeof window.orientation !== 'undefined'
          ? () => {}
          : () => closeToast()
      }
      open={isOpen}
      ClickAwayListenerProps={{
        onClickAway: () => {}, // NOTE: to override the close toast on click away
      }}
      ContentProps={{ classes: contentClass }}
      data-testid="toast"
      {...otherProps}
    />
  );
}

const styles = createStyles({
  root: { top: 60 },
  successContent: {
    backgroundColor: LIGHT_GREEN,
    borderColor: GREEN,
    borderWidth: 1,
  },
  failContent: {
    backgroundColor: LIGHT_RED,
    borderColor: RED,
    borderWidth: 1,
  },
});

const nativeStyles = StyleSheet.create({
  baseText: { textAlign: 'center', paddingRight: 20 },
  defaultText: { color: WHITE },
  successText: { color: GREEN },
  failText: { color: RED },
});

export default withStyles(styles)(Toast);
