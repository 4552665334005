import gql from 'graphql-tag';
import {
  canceledOrders,
  coupon,
  depot,
  transactionItems,
  user,
} from '../relationalFragments';
import { ORDER_RETURN_VARIABLES, Order } from './orderQuery';

export const GET_COMPLETED_ORDER_LIST_WHERE =
  '{AND: [{status: COMPLETED}, {paymentStatus: PAID}, {OR: [{closingId: null}, {closingId: ""}]}]}';

export const GET_COMPLETED_ORDER_LIST = gql`
  query getCompletedOrderList($skip: Int, $first: Int, $where: TransactionWhereInput = ${GET_COMPLETED_ORDER_LIST_WHERE}) {
    count: dataCount(node: ORDER, orderWhere: $where)
    orders(where: $where, skip: $skip, first: $first) {
      id
      status
      ${depot}
      invoiceNumber
      poNumber
      closingId
      ${user}
      ${coupon}
      ${canceledOrders}
      bottleGuarantee {
        gallonType
        qty
        pricePerBottle
      }
      paymentType
      paymentStatus
      createdAt
      deliveredDate
      canceledDate
      cancelReason
      ${transactionItems}
      creditPointUse
    }
  }
`;

const CLOSING_FRAGMENTS = `
      id
      closingDate
      transactions {
        ${ORDER_RETURN_VARIABLES}
      }
      active
`;

export const CREATE_CLOSING = gql`
  mutation createClosing($depotID: ID!) {
    createClosing(depotID: $depotID) {
      ${CLOSING_FRAGMENTS}
    }
  }
`;

export type Closing = {
  id: string;
  active: boolean;
  transactions: Array<Order>;
  closingDate: string;
};
export type ClosingsData = { closings: Array<Closing> };

export type ClosingParams = {
  where: {
    transactions_some?: {
      depot: {
        id: Nullable<string>;
      };
    };
    closingDate_gte: string;
    closingDate_lt: string;
  };
};

export const GET_CLOSINGS = gql`
  query closings($where: ClosingWhereInput) {
    closings(where: $where) {
      ${CLOSING_FRAGMENTS}
    }
  }
`;
