/**
 * TVIP Color
 * This is color which use on design
 */

export const ALICE_BLUE = '#F5F9FF';
export const BAHAMA_BLUE = '#20417B';
export const SOFT_BLACK = '#222222';
export const BLACK = '#242325';
export const DARK_GRAY = '#9B9B9B';
export const DARKER_GRAY = '#4A4A4A';
export const GHOST_WHITE = '#F6F7F8';
export const GRAY = '#D8D8D8';
export const GREEN = '#517328';
export const LIGHT_GRAY = '#FAF8FE';
export const LIGHTER_GRAY = '#BBB9B9';
export const LIGHT_GREEN = '#C6D6B4';
export const LIGHT_RED = '#F1B3BB';
export const PALE_GREEN = '#D7EDDA';
export const PALE_RED = '#EFD4DA';
export const RED_MARKER = 'rgba(234, 67, 48, 1)';
export const NAVY_BLUE = '#0248BE';
export const RED = '#BF1423';
export const WHITE = '#FFFFFF';
export const TRANSPARENT = '#0000';
export const BLUE = '#00A4EA';
export const GREEN_SOFT = '#A5FF86';
export const BLUE_SOFT = '#A4E4FF';
export const RED_SOFT = '#FF8686';
export const YELLOW = '#F9B54F';
export const ZOMBIE_GREEN = '#57C94D';
export const GUARDSMAN_RED = '#C60000';
export const CORAL_RED = '#F94144';
export const BLUE_BONNET = '#221EF8';
export const ORCHID_PINK = '#E074E2';
export const MALACHITE_GREEN = '#74E28C';
export const MAIZE_YELLOW = '#F9C74F';
export const GREEN_FOREST = '#5EB772';
export const CRYSTAL_BLUE = '#5EB7B7';
export const ROSE_GOLD = '#B75E73';
export const FRENCH_FUCHSIA = '#FF4996';
export const VIVID_GREEN = '#00DB3D';
export const DAFFODIL_YELLOW = '#FBFF29';
export const SAFFRON_ORANGE = '#FF9029';
export const SHINE_RED = '#FF281B';
export const VODKA_PURPLE = '#C2C9EF';
export const BLUE_SAPPHIRE = '#0F4FC8';

export const PRIMARY = NAVY_BLUE;

/**
 *  Opacity based colors in solid hex values
 */
export const BASE_GRAY = '#363537';
export const GRAY5 = '#f4f4f4';
export const GRAY8 = '#ededee';
export const GRAY20 = '#d3d3d3';
export const GRAY30 = '#bdbdbe';
export const GRAY50 = '#929192';
export const GRAY70 = '#666566';
export const GRAY80 = 'rgba(34, 34, 34, 0.5)';

export const BASE_BLUE = '#8fa0bd';
export const BLUE30 = '#dde3eb';
export const BLUE5 = '#f9fafc';
export const NEON_BLUE = '#6357ff';

export const NAVY_BLUE70 = '#4e7fd2';

/**
 * Contextual color names based on usage
 */

export const INPUT = {
  LABEL: GRAY70,
  BORDER: BLUE30,
  BACKGROUND: BLUE5,
  PLACEHOLDER: GRAY20,
  TEXT_DISABLED: DARK_GRAY,
  FOCUSED: PRIMARY,
  ERROR: RED,
};

export const SEPARATOR = GRAY8;

export const BUTTON = {
  PRIMARY,
  SECONDARY: DARK_GRAY,
  HOVER: {
    PRIMARY: NAVY_BLUE70,
    SECONDARY: GRAY30,
  },
};

export const TABLE = {
  HEADER: GRAY50,
  BORDER: GRAY,
  NO_DATA: LIGHT_GRAY,
  PAGINATION: { TEXT: DARK_GRAY, ICON: PRIMARY },
};

export const TABS = {
  ACTIVE: PRIMARY,
  DEFAULT: DARK_GRAY,
  SEPARATOR: GRAY8,
};

export const MODAL = {
  TITLE: BLACK,
  DESCRIPTION: GRAY50,
};

export const DROPDOWN = {
  ...INPUT,
  ACTIVE: PRIMARY,
  HOVERED: GHOST_WHITE,
};

export const TOAST = {
  SUCCESS: {
    BORDER: LIGHT_GREEN,
    BACKGROUND: PALE_GREEN,
    TEXT: GREEN,
  },
  FAIL: {
    BORDER: LIGHT_RED,
    BACKGROUND: PALE_RED,
    TEXT: RED,
  },
};

export const CHECKBOX = {
  DEFAULT: DARK_GRAY,
};

export const DROPZONE = {
  BORDER: GRAY,
  DEFAULT: DARK_GRAY,
  ACTIVE: PRIMARY,
};

/**
 * Color From Elite Pro
 * TODO : remove if useless
 */

export const BACKDROP_TRANSPARENT = 'rgba(36,35,37,0.5)';
