import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Mutation, compose } from 'react-apollo';
import { Query } from '../../components';
import { DARK_GRAY, GRAY, NAVY_BLUE, WHITE } from '../../constants/colors';
import { Button, Modal, Separator, Text } from '../../core-ui';
import { SEGMENT_SYNC } from '../../graphql/mutations';
import { ChannelTreeListData, GET_CHANNELS_TREE } from '../../graphql/queries';
import withToast, { ToastContextProps } from '../../helpers/withToast';

const CELL_HEIGHT = 40;
const CELL_WIDTH = '30%';

type Props = ToastContextProps;
type State = {
  isUpdateVisible: boolean;
};

export class SegmentScene extends React.Component<Props, State> {
  state: State = {
    isUpdateVisible: false,
  };

  _closeUpdate = () => this.setState({ isUpdateVisible: false });

  _onActivateError = () => {
    const { openToast } = this.props;
    openToast && openToast('fail', 'Data gagal diperbarui.');
  };

  _renderSyncModal = (refetchFn: () => void) => {
    const { isUpdateVisible } = this.state;
    return (
      <Mutation
        mutation={SEGMENT_SYNC}
        onCompleted={() => {
          const { openToast } = this.props;
          openToast && openToast('success', 'Segment berhasil diperbarui.');
          refetchFn();
          this.setState({ isUpdateVisible: false });
        }}
        onError={this._onActivateError}
      >
        {(updateDatabase, { loading }) => (
          <Modal
            isVisible={isUpdateVisible}
            title="Perbarui Data Segmentasi"
            submitLoading={loading}
            description={
              <Text size="small" color={DARK_GRAY}>
                Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                yakin ingin meng-update/memperbarui data?{' '}
              </Text>
            }
            children={
              <View style={styles.modalView}>
                <Button
                  style={{ marginRight: 15 }}
                  inverted
                  color="secondary"
                  text="Batal"
                  onPress={this._closeUpdate}
                  loadingColor={WHITE}
                />
                <Button
                  color="primary"
                  text="Ya"
                  onPress={() => updateDatabase()}
                  loadingColor={WHITE}
                  isLoading={loading}
                />
              </View>
            }
            onClose={this._closeUpdate}
          />
        )}
      </Mutation>
    );
  };

  render() {
    return (
      <View style={styles.root}>
        <View style={styles.header}>
          <Text size="xlarge">Segmentasi</Text>
          <Button onPress={() => this.setState({ isUpdateVisible: true })}>
            Update Data
          </Button>
        </View>
        <Separator />
        <View style={styles.flex}>
          <View style={styles.tableHeader}>
            <View style={styles.flexRow}>
              <Header value="CHANNEL" />
              <Header value="SEGMENT" />
              <Header value="SUB SEGMENT" style={styles.subSegmentCell} />
            </View>
          </View>
          <Query<ChannelTreeListData> query={GET_CHANNELS_TREE}>
            {({ data, refetch }) => {
              if (data && data.channels) {
                return (
                  <View style={styles.marginBottom}>
                    {this._renderSyncModal(refetch)}
                    {data.channels.map((channel) => {
                      const subSegmentList: Array<string> = [];
                      return (
                        <View style={styles.flexRow} key={channel.id}>
                          <View style={styles.widthCell}>
                            <CellContent
                              value={channel.title}
                              style={styles.flex}
                            />
                          </View>
                          <View style={styles.widthCell}>
                            {channel.segments.map((segment) => {
                              segment.subSegments.map((subSegment) => {
                                subSegmentList.push(subSegment.title);
                              });
                              return (
                                <CellContent
                                  key={segment.id}
                                  value={segment.title}
                                  style={{
                                    height:
                                      CELL_HEIGHT * segment.subSegments.length,
                                  }}
                                />
                              );
                            })}
                          </View>
                          <View style={styles.subSegmentCell}>
                            {subSegmentList.map((text, subSegmentIdx) => (
                              <CellContent
                                key={subSegmentIdx}
                                value={text.toUpperCase()}
                                style={{ alignItems: 'flex-start' }}
                              />
                            ))}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                );
              }
              return null;
            }}
          </Query>
        </View>
      </View>
    );
  }
}

type CellProps = {
  value: string;
  style?: StyleProp<ViewStyle>;
};

function Header(props: CellProps) {
  const { value, style } = props;
  return (
    <View style={[styles.headerComponent, style]}>
      <Text color={WHITE} size="small">
        {value}
      </Text>
    </View>
  );
}

function CellContent(props: CellProps) {
  const { value, style } = props;
  return (
    <View style={[styles.cellContent, style]}>
      <Text size="small">{value}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  flexRow: {
    flexDirection: 'row',
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  tableHeader: {
    marginTop: 10,
  },
  cellContent: {
    justifyContent: 'center',
    alignItems: 'center',
    height: CELL_HEIGHT,
    padding: 10,
    borderWidth: 0.2,
    borderColor: GRAY,
    backgroundColor: WHITE,
  },
  headerComponent: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderWidth: 0.2,
    width: CELL_WIDTH,
    backgroundColor: NAVY_BLUE,
    borderColor: GRAY,
  },
  widthCell: {
    width: CELL_WIDTH,
  },
  subSegmentCell: {
    width: '40%',
  },
  marginBottom: {
    marginBottom: 50,
  },
  modalView: { flexDirection: 'row', justifyContent: 'flex-end' },
});

export default compose(withToast)(SegmentScene);
