import { Box, Grid } from '@material-ui/core';
import { PaginationState } from '@tanstack/react-table';
import {
  ColumnType,
  DepotPicker,
  Query,
  ReactTable,
  ResetFilterButton,
  SubDistrictPicker,
} from 'components';
import { GRAY, PRIMARY } from 'constants/colors';
import { DatePickerField, Icon } from 'core-ui';
import {
  AccessProps,
  GET_ORDER_LIST,
  GetOrderListResult,
  GetOrderListVariables,
  Order,
} from 'graphql/queries';
import { asyncStorage, convertDepoToPostgresqlID } from 'helpers';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { OrderDetailModal } from 'scenes/order/components';
import { useSearch } from '../../contexts';
import { processedOrderListColumns } from '../columns';

export type DeliveredOrderListProps = AccessProps & {
  renderSyncModal: (refetchFn: () => void) => ReactElement;
};

export function DeliveredOrderList({
  access,
  renderSyncModal,
}: DeliveredOrderListProps) {
  const [selectedDepo, setSelectedDepo] = useState('');
  const [selectedSubDistrict, setSelectedSubDistrict] = useState('');
  const [selectedOrderDate, setSelectedOrderDate] = useState<Date | null>(null);
  const [
    selectedDeliveredDate,
    setSelectedDeliveredDate,
  ] = useState<Date | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [idPostresqlDepo, setIdPostresqlDepo] = useState('');
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [actionData, setActionData] = useState<Order | null>(null);

  const searchContent = useSearch();

  const columns = useMemo<ColumnType<Order>[]>(
    () => [
      ...processedOrderListColumns,
      {
        id: 'actions',
        size: 20,
        cell: ({ row }) => {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                gap: 4,
              }}
            >
              <Icon
                size="small"
                name="description"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => {
                  setIsDetailVisible(true);
                  setActionData(row.original);
                }}
              />
            </Box>
          );
        },
      },
    ],
    [],
  );

  const clearFilter = () => {
    setSelectedDepo('');
    setSelectedSubDistrict('');
    setSelectedOrderDate(null);
    setSelectedDeliveredDate(null);
  };

  const orderDate = useMemo(() => {
    const createdAt_gt = selectedOrderDate && new Date(selectedOrderDate);
    const createdAt_lt =
      createdAt_gt &&
      new Date(new Date(createdAt_gt).setHours(23, 59, 59, 999));
    const orderDate = createdAt_gt &&
      createdAt_lt && {
        createdAt_lt: createdAt_lt.toISOString(),
        createdAt_gt: createdAt_gt.toISOString(),
      };

    return orderDate;
  }, [selectedOrderDate]);

  const deliveredDate = useMemo(() => {
    const deliveredDate_gt =
      selectedDeliveredDate && new Date(selectedDeliveredDate);
    const deliveredDate_lt =
      deliveredDate_gt &&
      new Date(new Date(deliveredDate_gt).setHours(23, 59, 59, 999));

    const deliveredDateObject = deliveredDate_gt &&
      deliveredDate_lt && {
        deliveredDate_gt: deliveredDate_gt.toISOString(),
        deliveredDate_lt: deliveredDate_lt.toISOString(),
      };

    return deliveredDateObject;
  }, [selectedDeliveredDate]);

  useEffect(() => {
    (async () => {
      const nameUserDepo = await asyncStorage.getName();

      try {
        const idPostresqlDepo = convertDepoToPostgresqlID(String(nameUserDepo));

        setIdPostresqlDepo(idPostresqlDepo);
      } catch (error) {
        setIdPostresqlDepo('');
      }
    })();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="20px 0"
      >
        <Grid container spacing={2} alignItems="center" style={{ zIndex: 2 }}>
          <Grid item>
            <DepotPicker
              isFilter
              selectedOption={selectedDepo}
              onChange={(selected) =>
                setSelectedDepo(selected ? selected.value : '')
              }
              hidden={access.role !== 'SUPER_ADMIN'}
            />
          </Grid>
          <Grid item>
            <SubDistrictPicker
              isFilter
              selectedOption={selectedSubDistrict}
              onChange={(selected) =>
                setSelectedSubDistrict(selected ? selected.value : '')
              }
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Order"
              placeholder="Tanggal Order"
              selectedDate={selectedOrderDate}
              onChange={(date) => setSelectedOrderDate(date)}
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Sampai"
              placeholder="Tanggal Sampai"
              selectedDate={selectedDeliveredDate}
              onChange={(date) => setSelectedDeliveredDate(date)}
            />
          </Grid>
        </Grid>
        <ResetFilterButton onPress={clearFilter} />
      </Box>
      <Query<GetOrderListResult, GetOrderListVariables>
        query={GET_ORDER_LIST}
        keyData="orders"
        variables={{
          whereData: searchContent,
          whereDepot: selectedDepo || idPostresqlDepo || '',
          whereSubDistrict: selectedSubDistrict || '',
          whereStatus: ['COMPLETED'],
          ...orderDate,
          ...deliveredDate,
          first: pagination.pageSize,
          skip: pagination.pageSize * pagination.pageIndex,
        }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, loading, refetch }) => (
          <>
            {renderSyncModal(refetch)}
            <ReactTable
              columns={columns}
              data={data?.orders ?? []}
              total={data?.count ?? 0}
              pagination={pagination}
              setPagination={setPagination}
              isLoading={loading}
            />
            {actionData ? (
              <OrderDetailModal
                isVisible={isDetailVisible}
                order={actionData}
                onClose={() => setIsDetailVisible(false)}
              />
            ) : null}
          </>
        )}
      </Query>
    </Box>
  );
}
