import { Box, Grid } from '@material-ui/core';
import { PaginationState } from '@tanstack/react-table';
import {
  ColumnType,
  DepotPicker,
  Picker,
  Query,
  ReactTable,
  ResetFilterButton,
  SubDistrictPicker,
} from 'components';
import { DatePickerField, Text } from 'core-ui';
import {
  AccessProps,
  GET_ORDER_LIST,
  GetOrderListResult,
  GetOrderListVariables,
  Order,
} from 'graphql/queries';
import {
  asyncStorage,
  convertDepoToPostgresqlID,
  formatThousandSeparator,
} from 'helpers';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FlatList } from 'react-native';
import { useSearch } from '../../contexts';
import { tipeTotalFunc, tipeTotalName } from '../../helpers';
import { processedOrderListColumns } from '../columns';

export type CanceledOrderListProps = AccessProps & {
  renderSyncModal: (refetchFn: () => void) => ReactElement;
};

const statuses = {
  Pelanggan: 'CANCELED_BY_CUSTOMER',
  Admin: 'CANCELED_BY_ADMIN',
  Driver: 'CANCELED_BY_DRIVER',
};

export function CanceledOrderList({
  access,
  renderSyncModal,
}: CanceledOrderListProps) {
  const [selectedDepo, setSelectedDepo] = useState('');
  const [selectedSubDistrict, setSelectedSubDistrict] = useState('');
  const [selectedOrderDate, setSelectedOrderDate] = useState<Date | null>(null);
  const [selectedRole, setSelectedRole] = useState<
    keyof typeof statuses | null
  >(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [idPostresqlDepo, setIdPostresqlDepo] = useState('');

  const searchContent = useSearch();

  const columns = useMemo<ColumnType<Order>[]>(
    () => [
      ...processedOrderListColumns.map((column: ColumnType<Order>) => {
        if (column.id === 'totalPrice') {
          return {
            ...column,
            cell: ({ row }) => {
              const {
                transactionItems,
                creditPointUse,
                bottleGuarantee,
                coupon,
              } = row.original;

              return `Rp. ${formatThousandSeparator(
                tipeTotalFunc(
                  transactionItems,
                  tipeTotalName.totalPriceAfterDiscount,
                  creditPointUse,
                  bottleGuarantee,
                  [],
                  coupon && coupon.couponType === 'POTONGAN'
                    ? coupon.couponRewardValue
                    : 0,
                ),
              )}`;
            },
          };
        }

        return column;
      }),
      {
        accessorKey: 'canceledDate',
        header: 'Tanggal Ditolak',
        format: 'date',
      },
      {
        id: 'reason',
        header: 'Alasan Ditolak',
        cell: ({ row }) => {
          const {
            status,
            assignedDeliveryDrivers,
            cancelReason,
          } = row.original;

          return (
            <>
              {status === 'CANCELED_BY_DRIVER' ? (
                <FlatList
                  data={assignedDeliveryDrivers}
                  renderItem={({ item: { assignedOrder }, index }) => (
                    <Text
                      key={index}
                      size="small"
                      weight="reg"
                      style={{ letterSpacing: 1.5 }}
                    >
                      {assignedOrder.cancelReason}
                    </Text>
                  )}
                />
              ) : (
                <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
                  {cancelReason}
                </Text>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  const clearFilter = () => {
    setSelectedDepo('');
    setSelectedSubDistrict('');
    setSelectedOrderDate(null);
    setSelectedRole(null);
  };

  const orderDate = useMemo(() => {
    const createdAt_gt = selectedOrderDate && new Date(selectedOrderDate);
    const createdAt_lt =
      createdAt_gt &&
      new Date(new Date(createdAt_gt).setHours(23, 59, 59, 999));
    const orderDate = createdAt_gt &&
      createdAt_lt && {
        createdAt_lt: createdAt_lt.toISOString(),
        createdAt_gt: createdAt_gt.toISOString(),
      };

    return orderDate;
  }, [selectedOrderDate]);

  useEffect(() => {
    (async () => {
      const nameUserDepo = await asyncStorage.getName();

      try {
        const idPostresqlDepo = convertDepoToPostgresqlID(String(nameUserDepo));

        setIdPostresqlDepo(idPostresqlDepo);
      } catch (error) {
        setIdPostresqlDepo('');
      }
    })();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="20px 0"
      >
        <Grid container spacing={2} alignItems="center" style={{ zIndex: 2 }}>
          <Grid item>
            <DepotPicker
              isFilter
              selectedOption={selectedDepo}
              onChange={(selected) =>
                setSelectedDepo(selected ? selected.value : '')
              }
              hidden={access.role !== 'SUPER_ADMIN'}
            />
          </Grid>
          <Grid item>
            <SubDistrictPicker
              isFilter
              selectedOption={selectedSubDistrict}
              onChange={(selected) =>
                setSelectedSubDistrict(selected ? selected.value : '')
              }
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Order"
              placeholder="Tanggal Order"
              selectedDate={selectedOrderDate}
              onChange={(date) => setSelectedOrderDate(date)}
            />
          </Grid>
          <Grid item>
            <Picker
              label="Penolak"
              placeholder="Penolak"
              options={[
                { label: 'Semua', value: 'Semua' },
                { label: 'Admin', value: 'Admin' },
                { label: 'Pelanggan', value: 'Pelanggan' },
                { label: 'Driver', value: 'Driver' },
              ]}
              isFilter
              selectedOption={selectedRole}
              onChange={(selected) =>
                setSelectedRole(
                  selected ? (selected.value as keyof typeof statuses) : null,
                )
              }
            />
          </Grid>
        </Grid>
        <ResetFilterButton onPress={clearFilter} />
      </Box>
      <Query<GetOrderListResult, GetOrderListVariables>
        query={GET_ORDER_LIST}
        keyData="orders"
        variables={{
          whereData: searchContent,
          whereDepot: selectedDepo || idPostresqlDepo || '',
          whereSubDistrict: selectedSubDistrict || '',
          whereStatus: selectedRole
            ? [statuses[selectedRole]]
            : [
                'CANCELED_BY_ADMIN',
                'CANCELED_BY_DRIVER',
                'CANCELED_BY_CUSTOMER',
              ],
          ...orderDate,
          first: pagination.pageSize,
          skip: pagination.pageSize * pagination.pageIndex,
        }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, loading, refetch }) => (
          <>
            {renderSyncModal(refetch)}
            <ReactTable
              columns={columns}
              data={data?.orders ?? []}
              total={data?.count ?? 0}
              pagination={pagination}
              setPagination={setPagination}
              isLoading={loading}
            />
          </>
        )}
      </Query>
    </Box>
  );
}
