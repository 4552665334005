import gql from 'graphql-tag';

export type UsagePercentageCreditPointResult = {
  usagePercentageCreditPoint: Array<{
    id: string;
    usagePercentage: number;
  }>;
};

export const GET_USAGE_PERCENTAGE_CREDIT_POINT = gql`
  query usagePercentageCreditPoint {
    usagePercentageCreditPoint(first: 1) {
      usagePercentage
    }
  }
`;
