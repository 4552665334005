import React, { Component } from 'react';

import { View, StyleSheet } from 'react-native';
import { compose, graphql, MutationFunc, DataValue } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { NonTopList, TopList, SettlementList } from '.';
import { SearchField } from '../../components';
import { Text, Tabs, Tab } from '../../core-ui';
import {
  UPDATE_SEARCH_STATE,
  GET_SEARCH_STATE,
  AccessProps,
} from '../../graphql/queries';
import { SearchState } from '../../graphql/localState';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type Props = RouteComponentProps &
  SearchStateProps &
  UpdateSearchData &
  AccessProps;
type State = { selectedTab: number };

const TAB_HASH_URI = ['#non-top', '#top', '#settlement'];

export class TopScene extends Component<Props, State> {
  state: State = { selectedTab: 0 };

  componentWillMount() {
    const { location } = this.props;
    if (location.hash === TAB_HASH_URI[1]) {
      this.setState({ selectedTab: 1 });
    } else if (location.hash === TAB_HASH_URI[2]) {
      this.setState({ selectedTab: 2 });
    }
  }

  componentWillUnmount() {
    this._clearSearch();
  }

  _clearSearch = () => {
    const { updateSearch } = this.props;
    updateSearch({ variables: { searchedString: '' } });
  };

  render() {
    return (
      <View style={styles.root}>
        {this._renderHeader()}
        {this._renderTabs()}
      </View>
    );
  }

  _renderHeader() {
    const {
      searchStateQuery: { searchState },
      updateSearch,
    } = this.props;

    return (
      <View style={styles.header}>
        <Text size="xlarge">Daftar TOP</Text>
        <SearchField
          value={searchState ? searchState.searchedString : ''}
          onChangeText={(text) =>
            updateSearch({ variables: { searchedString: text } })
          }
        />
      </View>
    );
  }

  _renderTabs() {
    const { selectedTab } = this.state;
    const {
      access,
      searchStateQuery: { searchState },
    } = this.props;
    return (
      <Tabs
        selectedIndex={selectedTab}
        onChange={(_e, index) => {
          const { history, location } = this.props;
          this.setState({ selectedTab: index });
          this._clearSearch();
          history.push(`${location.pathname}${TAB_HASH_URI[index]}`);
        }}
      >
        <Tab label="Pelanggan Non-TOP">
          <NonTopList
            access={access}
            searchContent={searchState ? searchState.searchedString : ''}
          />
        </Tab>
        <Tab label="Pelanggan TOP">
          <TopList
            access={access}
            searchContent={searchState ? searchState.searchedString : ''}
          />
        </Tab>
        <Tab label="Pelunasan TOP">
          <SettlementList
            access={access}
            searchContent={searchState ? searchState.searchedString : ''}
          />
        </Tab>
      </Tabs>
    );
  }
}

const styles = StyleSheet.create({
  root: { flex: 1, paddingVertical: 40, paddingHorizontal: 80 },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  tabContent: { paddingTop: 20 },
});

export default compose(
  withRouter,
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, UpdateSearchVariables, UpdateSearchData>(
    UPDATE_SEARCH_STATE,
    { name: 'updateSearch' },
  ),
)(TopScene);
