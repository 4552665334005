import React, { Component } from 'react';

import { TextField, Icon } from '../../../core-ui';
import { DARK_GRAY, PRIMARY } from '../../../constants/colors';

type State = {
  isSearchActive: boolean;
};

type Props = {
  value: string;
  placeholder?: string;
  onChangeText: (text: string) => void;
};

class SearchField extends Component<Props, State> {
  state: State = {
    isSearchActive: false,
  };

  render() {
    const { value, placeholder, ...otherProps } = this.props;
    const { isSearchActive } = this.state;
    return (
      <TextField
        stretch
        value={value}
        placeholder={placeholder || 'Cari..'}
        childrenPosition="left"
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        {...otherProps}
      >
        <Icon
          color={isSearchActive ? PRIMARY : DARK_GRAY}
          name="search"
          size="small"
          onPress={() => {
            this.setState({ isSearchActive: !isSearchActive });
          }}
        />
      </TextField>
    );
  }

  _onFocus = () => {
    this.setState({ isSearchActive: true });
  };

  _onBlur = () => {
    this.setState({ isSearchActive: false });
  };
}

export default SearchField;
