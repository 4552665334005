import {
  Paper,
  Table,
  TableCell,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { Component } from 'react';

import { Text } from '../../../core-ui';
import { convertPascalCase } from '../../../helpers';

import { BLACK, DARK_GRAY } from '../../../constants/colors';

type OwnProps = {
  data: ObjectKey;
  keyTitle: ObjectKey;
};

type Props = WithStyles<typeof styles> & OwnProps;

class DetailList extends Component<Props> {
  render() {
    const { classes, data, keyTitle } = this.props;
    return (
      <Paper square elevation={0} style={styles.container}>
        <Table>
          {Object.keys(keyTitle).map((key) => (
            <TableRow>
              <TableCell
                className={classes.cell}
                style={{ verticalAlign: 'top' }}
              >
                <Text size="xsmall" color={DARK_GRAY}>
                  {keyTitle[key]}
                </Text>
              </TableCell>
              <TableCell
                className={classes.cell}
                style={{ verticalAlign: 'top' }}
              >
                <Text size="xsmall" color={DARK_GRAY}>
                  :
                </Text>
              </TableCell>
              <TableCell
                className={classNames(classes.cell)}
                style={{ verticalAlign: 'top' }}
              >
                <Text size="small" color={BLACK} style={{ letterSpacing: 1.5 }}>
                  {this._getValue(key, data)}
                </Text>
              </TableCell>
              <TableCell className={classes.cell} />
            </TableRow>
          ))}
        </Table>
      </Paper>
    );
  }

  _getValue = (key: string, datum: ObjectKey) => {
    const pascalCase = ['title', 'description', 'district', 'subdistrict'];
    if (pascalCase.includes(key)) {
      return convertPascalCase(datum[key]);
    }

    switch (key) {
      case 'active':
        return datum[key] ? 'Aktif' : 'Tidak Aktif';
      default:
        return datum[key];
    }
  };
}

const styles = createStyles({
  container: {
    marginTop: 10,
  },
  cell: {
    padding: '4px 18px 4px 16px',
    borderBottom: '0px',
  },
});

export default withStyles(styles)(DetailList);
