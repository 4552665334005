import { AsyncStorage } from 'react-native';

export default {
  saveUserID: (id: string) => {
    AsyncStorage.setItem('user-id', id);
  },
  saveName: (name: string) => {
    AsyncStorage.setItem('name', name);
  },
  saveToken: (userToken: string) => {
    AsyncStorage.setItem('tvip-token', userToken);
  },
  saveRole: (role: string) => {
    AsyncStorage.setItem('role', role);
  },
  getUserID: () => AsyncStorage.getItem('user-id'),
  getName: () => AsyncStorage.getItem('name'),
  getToken: () => AsyncStorage.getItem('tvip-token'),
  getRole: () => AsyncStorage.getItem('role'),
  removeUserID: () => AsyncStorage.removeItem('user-id'),
  removeName: () => AsyncStorage.removeItem('name'),
  removeToken: () => AsyncStorage.removeItem('tvip-token'),
  removeRole: () => AsyncStorage.removeItem('role'),
};
