import { NavigationMenu } from '..';

export const NAVIGATION_ITEMS: Array<NavigationMenu> = [
  {
    logo: 'folder',
    label: 'Admin',
    route: '/admin-list',
  },
  {
    logo: 'adminWithCogwheels',
    label: 'Peran',
    route: '/admin-roles',
  },
  {
    logo: 'users',
    label: 'Profil Pelanggan',
    route: '/users',
  },
  {
    logo: 'monitoringProgram',
    label: 'Monitoring Program',
    route: '/monitoring-program',
  },
  {
    logo: 'program',
    label: 'Program',
    route: '/programs',
  },
  {
    logo: 'picture_in_picture',
    label: 'Header Highlight',
    route: '/header-highlights',
  },
  {
    logo: 'businessTrend',
    label: 'Trend Bisnis',
    route: '/business-trend',
  },
  {
    logo: 'coupon',
    label: 'Kupon',
    route: '/coupons',
  },
  {
    logo: 'cubeMolecule',
    label: 'Segmentasi',
    route: '/segments',
  },
  {
    logo: 'ribbonBadge',
    label: 'Cluster',
    route: '/clusters',
  },
  {
    logo: 'staff',
    label: 'TOP',
    route: '/tops',
  },
  {
    logo: 'order',
    label: 'Produk',
    route: '/products',
  },
  {
    logo: 'diagram',
    label: 'Pemesanan',
    route: '/purchase-orders',
  },
  {
    logo: 'assignment',
    label: 'Assignment',
    route: '/assignment',
  },
  {
    logo: 'clockCancel',
    label: 'Tutup Penjualan',
    route: '/closing',
  },
  {
    logo: 'customerService',
    label: 'Layanan Pelanggan',
    route: '/customer-service',
  },
  {
    logo: 'star',
    label: 'Penilaian',
    route: '/ratings',
  },
  {
    logo: 'questionMark',
    label: 'Bantuan',
    route: '/faqs',
  },
];
