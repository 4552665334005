import { Box, IconButton } from '@material-ui/core';
import { ColumnType, Query, ReactTable } from 'components';
import { BLACK, DARKER_GRAY, GREEN_SOFT, RED_SOFT } from 'constants/colors';
import { Icon, Separator, Text } from 'core-ui';
import { differenceInDays, format } from 'date-fns';
import {
  GET_MONITOR_PROGRAMS_PER_DEPO,
  GetMonitorProgramsPerDepoParams,
  GetMonitorProgramsPerDepoResult,
  MonitorProgramPerDepo,
} from 'graphql/queries';
import { formatDecimal, formatThousandSeparator } from 'helpers/formatNumber';
import React, { useCallback, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { LinearProgressbar } from '../components/LinearProgressbar';
import { StatisticsBox } from '../components/StatisticsBox';

type MonitoringProgramPerDepoSceneProps = RouteComponentProps<{
  programID: string;
}>;

export function MonitoringProgramPerDepoScene({
  match,
}: MonitoringProgramPerDepoSceneProps) {
  const calculateTimeGone = useCallback(
    (start: Nullable<string>, end: Nullable<string>) => {
      const startDate = new Date(start!).setHours(0, 0, 0, 0);
      const endDate = new Date(end!).setHours(0, 0, 0, 0);
      const today = new Date();

      const intervalFromStart = differenceInDays(today, startDate);
      const intervalToEnd = differenceInDays(endDate, startDate);

      const diffOfDays = intervalToEnd - intervalFromStart;
      const currentRemainingDays = diffOfDays > 0 ? diffOfDays : 0;
      const formattedStartDate = format(startDate, 'dd/MM/yyyy');
      const formattedEndDate = format(endDate, 'dd/MM/yyyy');
      const runningDays = `${
        intervalFromStart > intervalToEnd ? intervalToEnd : intervalFromStart
      }/${intervalToEnd}`;
      const percentage = Math.floor((intervalFromStart / intervalToEnd) * 100);

      return {
        currentRemainingDays,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        runningDays,
        percentage: percentage > 100 ? 100 : percentage,
      };
    },
    [],
  );

  const columns = useMemo<ColumnType<MonitorProgramPerDepo>[]>(() => {
    return [
      {
        accessorKey: 'title',
        header: 'Depo',
      },
      {
        accessorKey: 'maxQty',
        header: 'Kuota/Target',
        cell: ({ getValue }) => formatThousandSeparator(getValue<number>()),
      },
      {
        accessorKey: 'programClaimCount',
        header: 'Realisasi',
        cell: ({ getValue }) => formatThousandSeparator(getValue<number>()),
      },
      {
        accessorKey: 'achievement',
        header: 'Achievement (%)',
        cell: ({ getValue, row }) => {
          const value = getValue<number>() > 0 ? getValue<number>() : 0;
          const achievement = Number(formatDecimal(value * 100, 1));
          const timegone = calculateTimeGone(
            row.original.startDate,
            row.original.endDate,
          ).percentage;

          return (
            <LinearProgressbar
              withText
              value={achievement}
              color={achievement < timegone ? RED_SOFT : GREEN_SOFT}
            />
          );
        },
      },
      {
        accessorKey: 'gap',
        header: 'Gap',
        cell: ({ getValue }) => (
          <Text size="small" color={getValue<number>() < 0 ? RED_SOFT : BLACK}>
            {formatThousandSeparator(getValue<number>())}
          </Text>
        ),
      },
      {
        id: 'detail',
        cell: ({ row }) => (
          <Link
            to={{
              pathname: `/monitoring-program/customer/${match.params.programID}/${row.original.depotID}`,
            }}
          >
            Lihat Detail
          </Link>
        ),
      },
    ];
  }, [match.params.programID, calculateTimeGone]);

  return (
    <Box padding="40px 80px">
      <Box display="flex" alignItems="center" style={{ gap: 4 }}>
        <IconButton to="/monitoring-program" component={Link}>
          <Icon name="arrow_back" color={DARKER_GRAY} />
        </IconButton>
        <Text size="xlarge">Monitoring Program</Text>
      </Box>
      <Separator />
      <Query<GetMonitorProgramsPerDepoResult, GetMonitorProgramsPerDepoParams>
        query={GET_MONITOR_PROGRAMS_PER_DEPO}
        variables={{
          programID: match.params.programID,
          first: 100,
        }}
        disableLoading
      >
        {({ data, loading }) => {
          if (!data?.monitorProgramsDetailDepo) return null;
          const monitoringData =
            [...data.monitorProgramsDetailDepo.programDetailDepot] ?? [];

          const {
            totalMaxQty,
            totalProgramClaimCount,
            achievement,
            gap,
          } = data.monitorProgramsDetailDepo.total;

          const {
            currentRemainingDays,
            startDate,
            endDate,
            runningDays,
            percentage,
          } = calculateTimeGone(
            monitoringData[0]?.startDate || null,
            monitoringData[0]?.endDate || null,
          );

          return (
            <>
              <Breadcrumb
                labels={['Monitoring Program', monitoringData[0]?.programTitle]}
              />
              <StatisticsBox
                targetTotal={totalMaxQty}
                programClaimCountTotal={totalProgramClaimCount}
                achievementTotal={Number(formatDecimal(achievement * 100, 1))}
                gapTotal={gap}
                runningDays={runningDays}
                percentage={percentage}
                startDate={startDate}
                endDate={endDate}
                currentRemainingDays={currentRemainingDays}
                isLoading={loading}
              />
              <ReactTable
                columns={columns}
                data={monitoringData}
                isLoading={loading}
              />
            </>
          );
        }}
      </Query>
    </Box>
  );
}
