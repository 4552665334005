import parse from 'csv-parse';
import generate from 'csv-generate';

const DATA_TYPE = {
  programs: ['depoName', 'customerID'],
  // NOTE: may be added later on if necessary
};

export const parseCsv = (
  data: string,
  type: keyof typeof DATA_TYPE,
  onEnd: (data: Array<ObjectKey>) => void,
  onError: (error: Error) => void,
) => {
  const result: Array<ObjectKey> = [];
  const parser = parse({
    columns: DATA_TYPE[type],
    from: 0,
  });
  parser.on('readable', () => {
    let record;
    do {
      record = parser.read();
      if (record) {
        result.push(record);
      }
    } while (record);
  });
  parser.on('error', (error) => {
    onError(error);
  });
  parser.on('end', () => {
    onEnd(result.slice(1));
  });
  parser.write(data);
  parser.end();
};

export const generateCsv = (data: ObjectKey) => {
  const result: Array<ObjectKey> = [];
  generate(
    {
      columns: Object.keys(data).length,
    },
    (error, records) => {
      if (error) {
        return error;
      }
      // TODO: trigger file download
      return records;
    },
  );
};
