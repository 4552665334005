export const ID_POSTRESQL: any = {
  302: {
    depo_title: 'DEPO CIRENDEU',
    postgre_id_production: 'ckuxqxqg90nb50764goap55xv',
    postgre_id_staging: 'cko8cwzls0nb408797f3xck1f',
  },
  304: {
    depo_title: 'DEPO CINANGKA',
    postgre_id_production: 'ckuxqxqj10nfx076490u1zwnu',
    postgre_id_staging: 'cko8cwzoi0nfw0879fhldy2hg',
  },
  305: {
    depo_title: 'DEPO ALAM SUTERA',
    postgre_id_production: 'ckuxqxqfd0n8g0764r9w75jop',
    postgre_id_staging: 'cko8cwzkx0n8f0879kokzzstz',
  },
  306: {
    depo_title: 'DEPO KEDOYA',
    postgre_id_production: 'ckuxqxqm90nll0764z3p0tp4u',
    postgre_id_staging: 'cko8cwzs10nlk0879irw9odgi',
  },
  308: {
    depo_title: 'DEPO LODAN',
    postgre_id_production: 'ckuxqxqpx0npe0764u1ukznmv',
    postgre_id_staging: 'cko8cwzvf0npd0879zl0w45dz',
  },
  309: {
    depo_title: 'TVIP MANIS3',
    postgre_id_production: 'ckuxqxses0qb40764jy4qa9v1',
    postgre_id_staging: 'cko8cx1eu0qb30879co9tre6y',
  },
  311: {
    depo_title: 'DEPO CIKOKOL',
    postgre_id_production: 'ckuxqxqsa0nyt0764gem8wqzw',
    postgre_id_staging: 'cko8cwzxq0nys08791hat3dq8',
  },
  312: {
    depo_title: 'DEPO KALIBATA',
    postgre_id_production: 'ckuxqxr020o6c0764iy44hlk9',
    postgre_id_staging: 'cko8cx04e0o6b0879oss9rp7s',
  },
  313: {
    depo_title: 'DEPO BINTARO',
    postgre_id_production: 'ckuxqxr3q0obw0764f9kazsgh',
    postgre_id_staging: 'cko8cx0790obv08790zf76wya',
  },
  315: {
    depo_title: 'DEPO DAAN MOGOT',
    postgre_id_production: 'ckuxqxr6v0ofw07644gnw75ll',
    postgre_id_staging: 'cko8cx0a70ofv08796ewuyt7v',
  },
  316: {
    depo_title: 'DEPO PLUIT',
    postgre_id_production: 'ckuxqxr990oja0764nn0dn5st',
    postgre_id_staging: 'cko8cx0cq0oj90879go8gs528',
  },
  317: {
    depo_title: 'DEPO CIKANDE',
    postgre_id_production: 'ckuxqxrbu0okh0764ymxq4e97',
    postgre_id_staging: 'cko8cx0er0okg0879g7uf1j8p',
  },
  318: {
    depo_title: 'DEPO MANIS',
    postgre_id_production: 'ckuxqxq4r0n1y0764jbpfjpba',
    postgre_id_staging: 'cko8cwzau0n1x0879p2ehepd5',
  },
  320: {
    depo_title: 'DEPO KELAPA DUA',
    postgre_id_production: 'ckuxqxpxb0mm10764auuaj8uc',
    postgre_id_staging: 'cko8cwz3v0mm00879a2oow7qd',
  },
  321: {
    depo_title: 'DEPO BALARAJA',
    postgre_id_production: 'ckuxqxsfd0qcx0764d8eoyx5j',
    postgre_id_staging: 'cko8cx1f70qcw0879tc2wlpu1',
  },
  322: {
    depo_title: 'DEPO LENTENG AGUNG',
    postgre_id_production: 'ckuxqxsh70qeo0764uelhyr0u',
    postgre_id_staging: 'cko8cx1gx0qen0879ifol66p8',
  },
  323: {
    depo_title: 'DEPO DEPOK',
    postgre_id_production: 'ckuxqxsi60qf50764zqrmimak',
    postgre_id_staging: 'cko8cx1hv0qf40879nlyubcd1',
  },
  324: {
    depo_title: 'DEPO PANDEGLANG',
    postgre_id_production: 'cko8cx1hv0qf40879nlyubcd2',
    postgre_id_staging: 'cko8cx1hv0qf40879nlyubcd2',
  },
  336: {
    depo_title: 'DEPO SERANG',
    postgre_id_production: 'ckuxqxrec0prz0764n187nrq2',
    postgre_id_staging: 'cko8cx0ha0pry08798fxz6hpc',
  },
};
