import React, { Component, ComponentType } from 'react';
import { View } from 'react-native';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import withToast, { ToastContextProps } from '../../helpers/withToast';
import { Card, TextField } from '../../core-ui';
import { FormPage } from '../../components';
import {
  CREATE_FAQ,
  UPDATE_FAQ,
  CreateFaqParams,
  UpdateFaqParams,
} from '../../graphql/mutations';
import { GET_FAQ, GetFaqData, AccessProps } from '../../graphql/queries';
import { NotFoundPage } from '..';

type MatchParams = { id: string };
type Props = ToastContextProps & RouteComponentProps<MatchParams> & AccessProps;
type State = {
  initialQuestion: string;
  initialAnswer: string;
  question: string;
  answer: string;
  isEdit: boolean;
};

class FAQFormScene extends Component<Props, State> {
  state: State = {
    initialQuestion: '',
    initialAnswer: '',
    question: '',
    answer: '',
    isEdit: false,
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id === 'new') {
      this.setState({ isEdit: false });
    } else {
      this.setState({ isEdit: true });
    }
  }

  render() {
    const { isEdit } = this.state;
    const { access } = this.props;
    return isEdit ? (
      access.update ? (
        this._renderEdit()
      ) : (
        <NotFoundPage />
      )
    ) : access.create ? (
      this._renderCreate()
    ) : (
      <NotFoundPage />
    );
  }

  _renderForm = (
    title: string,
    loading: boolean,
    submitTitle: string,
    onSubmit: () => void,
  ) => {
    const { history } = this.props;
    const { initialQuestion, initialAnswer, question, answer } = this.state;
    return (
      <FormPage
        showBackButton
        isSubmitLoading={loading}
        isDirty={this._isDirty()}
        isValid={this._isValid()}
        submitTitle={submitTitle}
        handleDiscard={() =>
          this.setState({ question: initialQuestion, answer: initialAnswer })
        }
        handleGoBack={history.goBack}
        handleSubmit={onSubmit}
      >
        <Card title={title}>
          <View style={{ paddingBottom: 40 }}>
            <TextField
              stretch
              label="Pertanyaan"
              placeholder="Pertanyaan"
              value={question}
              onChangeText={(t) => this.setState({ question: t })}
            />
          </View>
          <TextField
            stretch
            multiline
            numberOfLines={10}
            label="Jawaban"
            placeholder="Jawaban"
            value={answer}
            onChangeText={(t) => this.setState({ answer: t })}
          />
        </Card>
      </FormPage>
    );
  };

  _renderCreate = () => {
    const { openToast, history } = this.props;
    const { question, answer } = this.state;
    return (
      <Mutation<{}, CreateFaqParams>
        mutation={CREATE_FAQ}
        onCompleted={() => {
          openToast('success', 'Pertanyaan baru telah ditambahkan');
          history.goBack();
        }}
        onError={() => openToast('fail', 'Pertanyaan baru gagal ditambahkan')}
      >
        {(createFaq, { loading }) =>
          this._renderForm('Tambah Pertanyaan', loading, 'Tambah', () =>
            createFaq({
              variables: {
                data: { title: question, content: answer, active: true },
              },
            }),
          )
        }
      </Mutation>
    );
  };

  _renderEdit = () => {
    const {
      openToast,
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { question, answer } = this.state;
    return (
      <Query<GetFaqData>
        query={GET_FAQ}
        variables={{ id }}
        fetchPolicy="network-only"
        onCompleted={(data) => {
          const { initialQuestion } = this.state;
          const result = (data as unknown) as GetFaqData;
          if (result.faq && initialQuestion === '') {
            const { title, content } = result.faq;
            this.setState({
              initialQuestion: title,
              initialAnswer: content,
              question: title,
              answer: content,
            });
          }
        }}
      >
        {({ data }) => (
          <Mutation<{}, UpdateFaqParams>
            mutation={UPDATE_FAQ}
            onCompleted={() => {
              openToast('success', 'Pertanyaan telah diubah');
              history.goBack();
            }}
            onError={() => openToast('fail', 'Pertanyaan gagal diubah')}
          >
            {(updateFaq, { loading }) =>
              this._renderForm('Ubah Pertanyaan', loading, 'Simpan', () =>
                updateFaq({
                  variables: {
                    id,
                    data: {
                      title: question,
                      content: answer,
                      active: data ? data.faq.active : true,
                    },
                  },
                }),
              )
            }
          </Mutation>
        )}
      </Query>
    );
  };

  _isDirty = () => {
    const { initialQuestion, initialAnswer, question, answer } = this.state;
    return initialQuestion !== question || initialAnswer !== answer;
  };

  _isValid = () => {
    const { question, answer } = this.state;
    return question !== '' && answer !== '';
  };
}

export default withToast(FAQFormScene) as ComponentType<{}>;
