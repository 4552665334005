import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {
  Button as MButton,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Icon, Text } from '.';
import { BUTTON, WHITE } from '../constants/colors';

type Color = 'primary' | 'secondary';

export type ButtonProps = {
  isLoading?: boolean;
  loadingColor?: string;
  children?: ReactNode;
  color?: Color;
  disabled?: boolean;
  icon?: IconKey;
  iconColor?: string;
  iconPosition?: 'left' | 'right';
  inverted?: boolean;
  onPress?: () => void;
  small?: boolean;
  stretch?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
};
type Props = WithStyles<typeof styles> & ButtonProps;

export function Button(props: Props) {
  let {
    children,
    classes,
    color = 'primary' as Color,
    disabled,
    isLoading,
    loadingColor,
    icon,
    iconColor,
    iconPosition,
    inverted,
    onPress,
    small,
    stretch,
    style,
    text,
    textStyle,
    ...otherProps
  } = props;
  let content;
  if (isLoading) {
    disabled = true;
  }
  iconPosition = iconPosition || 'left';

  if (children) {
    content = children;
  } else if (icon && !text) {
    content = <Icon name={icon} color={iconColor} size="small" />;
  } else if (icon && text) {
    content = (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {iconPosition === 'left' ? (
          <Icon
            name={icon}
            color={iconColor}
            size="small"
            style={{ marginRight: '5px' }}
          />
        ) : null}
        <Text size={small ? 'xsmall' : 'small'} style={textStyle}>
          {text}
        </Text>
        {iconPosition === 'right' ? (
          <Icon
            name={icon}
            color={iconColor}
            size="small"
            style={{ marginLeft: '5px' }}
          />
        ) : null}
      </View>
    );
  } else {
    content = (
      <Text size={small ? 'xsmall' : 'small'} style={textStyle}>
        {text}
      </Text>
    );
  }

  const COLOR = {
    default: {
      primary: classes.primaryColor,
      secondary: classes.secondaryColor,
    },
    inverted: {
      primary: classes.primaryInvertedColor,
      secondary: classes.secondaryInvertedColor,
    },
  };

  const buttonColoring = COLOR.default[color];
  const invertedButtonColoring = COLOR.inverted[color];

  const invertedDisabledStyle = classNames(
    classes.coreStyle,
    classes.invertedDisabledColor,
  );
  const invertedStyle = disabled
    ? invertedDisabledStyle
    : classNames(classes.coreStyle, invertedButtonColoring);

  const defaultStyle = disabled
    ? classes.coreStyle
    : classNames(classes.coreStyle, buttonColoring);

  const buttonStyle = inverted ? invertedStyle : defaultStyle;

  return (
    <View style={!stretch && { alignItems: 'center' }}>
      <MButton
        disabled={disabled}
        variant={inverted ? 'outlined' : 'contained'}
        onClick={onPress}
        style={StyleSheet.flatten(style) as CSSProperties}
        className={classNames(buttonStyle, small ? classes.smallButton : null)}
        {...otherProps}
      >
        {isLoading ? (
          <ActivityIndicator size={24} color={loadingColor || WHITE} />
        ) : (
          content
        )}
      </MButton>
    </View>
  );
}

const BUTTON_BORDER = '1px solid';
let styles = createStyles({
  coreStyle: {
    color: WHITE,
    'min-width': 90,
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 2,
    padding: '3px 15px',
    minHeight: 40,
    '&:hover': {
      opacity: 0.9,
      boxShadow: 'none',
    },
  },
  smallButton: {
    minHeight: 25,
  },
  primaryColor: {
    border: BUTTON_BORDER,
    borderColor: BUTTON.PRIMARY,
    backgroundColor: BUTTON.PRIMARY,
    '&:hover': {
      backgroundColor: BUTTON.HOVER.PRIMARY,
    },
  },
  secondaryColor: {
    border: BUTTON_BORDER,
    borderColor: BUTTON.SECONDARY,
    backgroundColor: BUTTON.SECONDARY,
    '&:hover': {
      backgroundColor: BUTTON.HOVER.SECONDARY,
    },
  },
  primaryInvertedColor: {
    backgroundColor: WHITE,
    color: BUTTON.PRIMARY,
    borderColor: BUTTON.PRIMARY,
  },
  secondaryInvertedColor: {
    backgroundColor: WHITE,
    color: BUTTON.SECONDARY,
    borderColor: BUTTON.SECONDARY,
  },
  invertedDisabledColor: {
    color: BUTTON.SECONDARY,
    borderColor: BUTTON.SECONDARY,
  },
});

export default withStyles(styles)(Button);
