import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';

import { ToastProvider } from './core-ui';
import { client } from './graphql/client';
import MainRoute from './routes/MainRoute';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

export default function App() {
  return (
    <ApolloProvider client={client}>
      <ToastProvider>
        <MainRoute />
      </ToastProvider>
    </ApolloProvider>
  );
}
