import gql from 'graphql-tag';
import { MutationFn } from 'react-apollo';

export type UploadPhotoResult = { uploadPhoto: { url: string } };
export type UploadPhotoVariables = {
  file: File;
  uploadType: 'PRODUCT' | 'KTP' | 'NPWP' | 'STORE' | 'COUPON' | 'HEADER';
};
export type UploadPhotoMutationFn = MutationFn<
  UploadPhotoResult,
  UploadPhotoVariables
>;

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($file: Upload, $uploadType: UploadType!) {
    uploadPhoto(file: $file, uploadType: $uploadType) {
      url
    }
  }
`;
