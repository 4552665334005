import React, { Component, Fragment } from 'react';
import {
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core';

import { Text } from '../../../core-ui';
import { GRAY } from '../../../constants/colors';

import { ProgramTerm } from '../../../graphql/queries';

type OwnProps = {
  programTerms: Array<ProgramTerm>;
};

type Props = WithStyles<typeof styles> & OwnProps;

const TERMS_FIELDS: ObjectKey = {
  productName: 'Nama Produk',
  minimalPurchase: 'Minimal Pembelian (Unit)',
  rewardQty: 'Potongan Harga (Unit)',
};

class DetailsTermsList extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Paper square elevation={0}>
        <Table>
          <TableHead>
            {this._renderHeader()}
            <TableCell className={classes.cell} />
          </TableHead>
          <TableBody>{this._renderBody()}</TableBody>
        </Table>
      </Paper>
    );
  }

  _renderHeader = () => {
    const { classes } = this.props;
    return (
      <>
        {Object.keys(TERMS_FIELDS).map((key) => (
          <TableCell className={classes.cell}>
            <Text size="xsmall" weight="bold">
              {TERMS_FIELDS[key]}
            </Text>
          </TableCell>
        ))}
      </>
    );
  };

  _renderBody = () => {
    const { programTerms, classes } = this.props;

    return (
      <>
        {programTerms.map(({ product, terms, id }) => (
          <TableRow key={id}>
            <TableCell className={classes.content}>
              <Text>{product.title}</Text>
            </TableCell>
            <TableCell className={classes.content}>
              <Text>{terms[0].minimalPurchase}</Text>
            </TableCell>
            <TableCell className={classes.content}>
              <Text>{terms[0].rewardQty}</Text>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };
}

const styles = createStyles({
  cell: {
    padding: '4px 18px 4px 16px',
    borderBottom: `1px dashed ${GRAY}`,
  },
  content: {
    padding: '4px 18px 4px 16px',
    borderBottom: 0,
  },
});

export default withStyles(styles)(DetailsTermsList);
