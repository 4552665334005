import React, { Component } from 'react';
import { ScrollView, View, StyleSheet, Image } from 'react-native';

import { Modal, Tab, Tabs, Text } from '../../../core-ui';
import { BLACK, DARK_GRAY } from '../../../constants/colors';
import { HeaderHighlight } from '../../../graphql/queries/headerQuery';
import SubsegmentList from '../components/SubsegmentList';

type Props = {
  isVisible: boolean;
  data: Array<HeaderHighlight>;
  dataID: Nullable<string>;
  onClose: () => void;
};
type State = { selectedDetailTab: number };

const HEADER_HIGHLIGHT_FIELDS = {
  title: 'Nama Header',
  description: 'Deskripsi',
  headerImage: 'Foto Header',
};

export default class HeaderHighlightDetailModal extends Component<
  Props,
  State
> {
  state = { selectedDetailTab: 0 };

  render() {
    const { isVisible, data, onClose } = this.props;
    const { selectedDetailTab } = this.state;
    const currentData = this._getCurrentData(data);
    return (
      <Modal
        maxHeight
        maxWidth="sm"
        title="Informasi Header Highlight"
        isVisible={isVisible}
        onClose={onClose}
      >
        {currentData && (
          <Tabs
            selectedIndex={selectedDetailTab}
            onChange={(_e, index) =>
              this.setState({ selectedDetailTab: index })
            }
          >
            <Tab label="Data Header Highlight">
              {this._renderTabDetailContent(
                HEADER_HIGHLIGHT_FIELDS,
                currentData,
              )}
            </Tab>
            <Tab label="Subsegment">
              {this._renderTabSubsegmentContent(currentData)}
            </Tab>
          </Tabs>
        )}
      </Modal>
    );
  }

  _renderTabSubsegmentContent = (data: HeaderHighlight) => (
    <View>
      <SubsegmentList data={data.segments}></SubsegmentList>
    </View>
  );

  _renderTabDetailContent = (fields: ObjectKey, data: HeaderHighlight) => (
    <ScrollView contentContainerStyle={styles.modalBody}>
      <View>{this._renderInfoFields(Object.values(fields))}</View>
      <View style={styles.infoSeparator}>
        {this._renderInfoSeparators(Object.keys(fields))}
      </View>
      <View style={styles.infoValue}>
        {this._renderInfoValues(data, Object.keys(fields))}
      </View>
    </ScrollView>
  );

  _renderInfoFields = (fields: Array<string>) =>
    fields.map((field, index) => (
      <View
        style={[
          styles.infoWrapper,
          ['Foto Header'].includes(field) && { height: 200 },
        ]}
        key={index}
      >
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          {field}
        </Text>
      </View>
    ));

  _renderInfoSeparators = (fields: Array<string>) =>
    fields.map((_, idx) => (
      <View style={[styles.infoWrapper]} key={idx}>
        <Text size="xsmall" color={DARK_GRAY} style={styles.infoText}>
          :
        </Text>
      </View>
    ));

  _renderInfoValues = (data: HeaderHighlight, dataKeys: Array<string>) =>
    dataKeys.map((dataKey, index) => {
      let content;
      switch (dataKey) {
        case 'headerImage': {
          content = (
            <Image
              source={{ uri: data.headerImage }}
              // TODO: adjust size as necessary
              resizeMethod="auto"
              resizeMode="contain"
              style={{ width: 200, height: 200 }}
            />
          );
          break;
        }
        default: {
          let processor: (val: string) => string = (val) => val;
          content = (
            <Text size="small" color={BLACK} style={styles.infoText}>
              {processor(this._getValue(data, dataKey))}
            </Text>
          );
        }
      }
      return (
        <View style={styles.infoWrapper} key={index}>
          {content}
        </View>
      );
    });

  _getValue = (data: HeaderHighlight, dataKey: string) => {
    const nestedKey = dataKey.split('.');
    let tmpData: ObjectKey = data;
    for (const key of nestedKey) {
      if (!tmpData[key]) {
        break; // NOTE: either tmpData[key] is null or tmpData has no such key break away from the loop
      } else if (typeof tmpData[key] === 'object') {
        tmpData = tmpData[key]; // NOTE: if tmpData[key] is an object, traverse deeper
      } else {
        if (typeof tmpData[key] === 'boolean') {
          // NOTE: if value is boolean (user's active property), return appropriate text
          return tmpData[key] ? 'Aktif' : 'Tidak Aktif';
        }
        return tmpData[key]; // NOTE: supposed to be the value, may be string or number
      }
    }
    return ''; // NOTE: defaultly returns an empty string
  };

  _getCurrentData = (data: Array<HeaderHighlight>) =>
    data.find(({ id }) => id === this.props.dataID);
}

const styles = StyleSheet.create({
  modalBody: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
    width: 600
  },
  infoWrapper: {
    minHeight: 40,
    justifyContent: 'flex-start',
  },
  infoText: {
    height: 22,
  },
  infoSeparator: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  infoValue: { flex: 1 },
});
