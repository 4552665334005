import React from 'react';
import { View, StyleSheet } from 'react-native';
import {
  compose,
  graphql,
  Mutation,
  MutationFunc,
  DataValue,
} from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, Icon, Separator, Text, Modal } from '../../core-ui';
import { Table, RowsPerPage, SearchField, Query } from '../../components';
import { GRAY, RED, DARK_GRAY, BLACK, PRIMARY } from '../../constants/colors';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import {
  convertPascalCase,
  fetchMoreItems,
  refetchItems,
  asyncStorage,
} from '../../helpers';

import {
  UPDATE_SEARCH_STATE,
  GET_SEARCH_STATE,
  GET_PROVIDERS,
  DELETE_PROVIDER,
  DeleteProviderMutation,
  AccessProps,
  ProviderListResult,
  ProviderListParams,
} from '../../graphql/queries';
import { SearchState } from '../../graphql/localState';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type DeleteProviderProps = { deleteProvider: DeleteProviderMutation };

type State = {
  userID: Nullable<string>;
  resetPage: boolean;
  isDeleteVisible: boolean;
  actionDataID: Nullable<string>;
  rowsPerPage: RowsPerPage;
  adminName: Nullable<string>;
  page: number;
};

type Props = RouteComponentProps &
  UpdateSearchData &
  ToastContextProps &
  DeleteProviderProps &
  AccessProps &
  SearchStateProps;

export class AdminListScene extends React.Component<Props, State> {
  state: State = {
    userID: null,
    resetPage: false,
    isDeleteVisible: false,
    actionDataID: null,
    adminName: null,
    rowsPerPage: 10,
    page: 0,
  };

  async componentDidMount() {
    const userID = await asyncStorage.getUserID();
    this.setState({ userID });
  }

  componentWillUnmount() {
    const { updateSearch } = this.props;
    updateSearch({ variables: { searchedString: '' } });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      searchStateQuery: { searchState: prevSearch },
    } = prevProps;
    const {
      searchStateQuery: { searchState },
    } = this.props;
    if (prevSearch && searchState) {
      if (prevSearch.searchedString !== searchState.searchedString) {
        this.setState({ resetPage: true });
      }
    }
  }

  render() {
    return (
      <View style={styles.root}>
        {this._renderHeader()}
        <Separator />
        {this._renderTable()}
      </View>
    );
  }

  _renderHeader = () => {
    const {
      searchStateQuery: { searchState },
      updateSearch,
      access,
    } = this.props;
    return (
      <View style={styles.header}>
        <Text size="xlarge">Daftar Admin</Text>
        <View style={styles.flexRow}>
          <SearchField
            value={searchState ? searchState.searchedString : ''}
            onChangeText={(text) =>
              updateSearch({ variables: { searchedString: text } })
            }
          />
          {access.create && (
            <Button
              icon="add"
              iconPosition="left"
              text="Tambah Admin"
              onPress={this._addNewAdmin}
            />
          )}
        </View>
      </View>
    );
  };

  _getQueryWhere = () => {
    const {
      searchStateQuery: { searchState },
    } = this.props;
    if (searchState) {
      const searchText = searchState.searchedString.toLowerCase();
      return {
        OR: [
          { name_contains: searchText },
          { email_contains: searchText },
          { telephone_contains: searchText },
        ],
      };
    }
    return {};
  };

  _renderTable = () => {
    const { access } = this.props;
    const { rowsPerPage, resetPage, page } = this.state;
    const dataKey = 'providers';

    return (
      <Query<ProviderListResult, ProviderListParams>
        query={GET_PROVIDERS}
        variables={{
          where: this._getQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({ data, loading, fetchMore }) => {
          if (data) {
            return (
              <View style={styles.bodyWrapper}>
                {this._renderModal(() =>
                  refetchItems<ProviderListResult, ProviderListParams>(
                    fetchMore,
                    {
                      query: GET_PROVIDERS,
                      variables: {
                        where: this._getQueryWhere(),
                        first: rowsPerPage,
                        skip: page * rowsPerPage,
                      },
                      dataKey,
                      rowsPerPage,
                      page,
                    },
                  ),
                )}
                <Table
                  data={data.providers || []}
                  dataCount={data.count || 0}
                  resetPage={resetPage}
                  setResetPage={(reset) => this.setState({ resetPage: reset })}
                  page={page}
                  onChangePage={(nextPage) => this.setState({ page: nextPage })}
                  isLoading={loading}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    name: { headerTitle: 'Nama Admin' },
                    email: { headerTitle: 'Alamat Email' },
                    telephone: { headerTitle: 'Kontak' },
                    role: {
                      headerTitle: 'Peran',
                      render: ({ role }) => (
                        <Text size="small" style={styles.cellText}>
                          {role.split('_').join(' ')}
                        </Text>
                      ),
                    },
                    actions: {
                      render: ({ id, name }) => (
                        <View style={styles.actionTableWrapper}>
                          {access.update && (
                            <Link to={{ pathname: `admin-list/${id}` }}>
                              <Icon
                                size="small"
                                name="edit"
                                color={GRAY}
                                hoverColor={PRIMARY}
                              />
                            </Link>
                          )}
                          {access.delete && (
                            <Icon
                              size="small"
                              name="delete"
                              disabled={id === this.state.userID}
                              color={GRAY}
                              hoverColor={id === this.state.userID ? GRAY : RED}
                              onPress={() =>
                                this.setState({
                                  isDeleteVisible: true,
                                  actionDataID: id,
                                  adminName: name,
                                })
                              }
                            />
                          )}
                        </View>
                      ),
                      noHeaderName: true,
                    },
                  }}
                  loadMore={({ first, skip }) => {
                    fetchMoreItems<ProviderListResult, ProviderListParams>(
                      fetchMore,
                      {
                        query: GET_PROVIDERS,
                        variables: {
                          where: this._getQueryWhere(),
                          first,
                          skip,
                        },
                        dataKey,
                      },
                    );
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _renderModal = (refetchFn: () => any) => {
    const { isDeleteVisible, adminName } = this.state;

    return (
      <Mutation
        mutation={DELETE_PROVIDER}
        onCompleted={() => {
          const { openToast } = this.props;
          openToast && openToast('success', 'Admin telah dihapus.');
          refetchFn();
          this.setState({ isDeleteVisible: false });
        }}
        onError={this._onDeleteError}
      >
        {(deleteProvider, { loading }) => (
          <Modal
            hideHeaderClose
            isVisible={isDeleteVisible}
            title="Hapus Admin"
            closeButtonText="Tidak"
            buttonText="Hapus"
            description={
              <Text size="small" color={DARK_GRAY}>
                Apakah Anda yakin ingin menghapus{' '}
                <Text size="small" weight="bold" color={BLACK}>
                  {adminName ? convertPascalCase(adminName) : 'data ini'}
                </Text>{' '}
                dari daftar admin?
              </Text>
            }
            submitLoading={loading}
            onClose={this._closeDelete}
            onSubmit={() => {
              const { actionDataID } = this.state;
              if (actionDataID) {
                deleteProvider({ variables: { id: actionDataID } });
              }
            }}
          />
        )}
      </Mutation>
    );
  };

  _onDeleteError = () => {
    const { openToast } = this.props;
    openToast && openToast('fail', 'Admin gagal dihapus.');
  };

  _addNewAdmin = () => {
    const { history } = this.props;
    history && history.push('/admin-list/new');
  };

  _closeDelete = () => this.setState({ isDeleteVisible: false });
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  historyWrapper: {
    justifyContent: 'center',
    paddingLeft: 15,
    marginLeft: 15,
  },
  bodyWrapper: {
    paddingTop: 20,
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  cellText: { letterSpacing: 1.5 },
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, {}, UpdateSearchData>(UPDATE_SEARCH_STATE, {
    name: 'updateSearch',
  }),
  withToast,
)(AdminListScene);
