import React, { Component } from 'react';

import { DetailList, DetailsTermsList, ModalTable, ProductList } from '.';
import { Modal, Tab, Tabs } from '../../../core-ui';

import { RETAIL } from '../../../constants/priceSegment';
import { Program } from '../../../graphql/queries';

type Props = {
  isVisible: boolean;
  data: Nullable<Program>;
  onClose: () => void;
};

type State = {
  selectedDetailTab: number;
};

const PROGRAM_FIELDS = {
  programType: 'Jenis Program',
  rewardType: 'Jenis Reward',
  title: 'Nama Program',
  description: 'Deskripsi Program',
  claimLimit: 'Jumlah Klaim',
  startPeriode: 'Periode Awal - waktu',
  endPeriode: 'Periode Akhir - waktu',
  active: 'Status',
};

const DEPO_FIELDS = {
  title: 'Nama Depo',
  qty: 'QTY',
};

const CUSTOMER_FIELDS = {
  szID: 'KODE PELANGGAN',
  name: 'NAMA TOKO',
  depoName: 'NAMA DEPO',
};

const TERM_FIELDS = {
  minimalPurchase: 'Minimal Pembelian (Unit)',
  rewardQty: 'Potongan Harga (Unit)',
};

export default class ProgramDetailModal extends Component<Props, State> {
  state = {
    selectedDetailTab: 0,
  };

  render() {
    const { isVisible, data, onClose } = this.props;
    const { selectedDetailTab } = this.state;

    let content;

    if (data) {
      const {
        customers,
        programDepot,
        products,
        terms,
        programType,
        depot,
        programUser,
      } = data;

      let customerList = Array<{
        szID: string;
        name: string;
        depoName: string;
      }>();
      let depoList = Array<{ title: string; qty: number }>();
      if (depot) {
        depoList.push({ title: depot.title, qty: 0 });
        customerList = customers.map((item) => ({
          szID: item.szID,
          name: item.storeName,
          depoName: item.depot ? item.depot.title : '-',
        }));
      } else {
        depoList = programDepot.map((item) => ({
          title: item.depot.title,
          qty: item.maxQty ?? 0,
        }));
        customerList = programUser.map((item) => ({
          szID: item.user.szID ?? '-',
          name: item.user.storeName,
          depoName: item.user.depot.title,
        }));
      }

      let priceSegmentCode = RETAIL;
      // NOTE: will use this later
      // let priceSegmentCode;
      // if (segment.title.includes('GT SO/W (GS)')) {
      //   priceSegmentCode = subSegment.title.includes('wholesaler')
      //     ? WHOLESALE
      //     : STAR_OUTLET;
      // }
      // if (segment.title.includes('GT RETAIL (GR)')) {
      //   priceSegmentCode = RETAIL;
      // }

      const programTerms = terms.flatMap((term) => term.terms);

      content = (
        <Tabs
          selectedIndex={selectedDetailTab}
          onChange={(_e, index) => this.setState({ selectedDetailTab: index })}
        >
          <Tab label="Data Program">
            <DetailList keyTitle={PROGRAM_FIELDS} data={data} />
          </Tab>
          <Tab label="Produk">
            <ProductList
              priceSegmentCode={priceSegmentCode}
              data={products}
              dataCount={products.length}
              hidePagination
              loadMore={() => {}}
            />
          </Tab>
          <Tab label="Pelanggan">
            <ModalTable
              data={customerList}
              keyTitle={CUSTOMER_FIELDS}
              dataCount={customerList.length}
              hidePagination
              loadMore={() => {}}
            />
          </Tab>
          <Tab label="Depo">
            <ModalTable
              data={depoList}
              keyTitle={DEPO_FIELDS}
              dataCount={depoList.length}
              hidePagination
              loadMore={() => {}}
            />
          </Tab>
          {/* TODO : Enhance syarat to show data when type program is loyalty - cashback */}
          <Tab label="Syarat">
            {programType === 'FLASH_SALE' || programType === 'BUNDLE' ? (
              <DetailsTermsList programTerms={terms} />
            ) : (
              <ModalTable
                data={programTerms}
                keyTitle={TERM_FIELDS}
                dataCount={programTerms.length}
                hidePagination
                loadMore={() => {}}
              />
            )}
          </Tab>
        </Tabs>
      );
    }
    return (
      <Modal
        maxHeight
        title="Informasi Program"
        isVisible={isVisible}
        onClose={onClose}
        maxWidth="xl"
      >
        {content}
      </Modal>
    );
  }
}
