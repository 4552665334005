export const GIFT_OPTS = [
  { label: 'Emas', value: 'GOLD' },
  { label: 'Produk Lain', value: 'ANOTHER_PRODUCT' },
];
export const STATUS_OPTS = [
  { label: 'Aktif', value: true },
  { label: 'Tidak Aktif', value: false },
];

export const PROGRAM_TYPE_OPTS = [
  { label: 'Trade Promo', value: 'TRADE_PROMO' },
  { label: 'Loyalty', value: 'LOYALTY' },
  { label: 'Bundling', value: 'BUNDLE' },
  { label: 'Flash Sale', value: 'FLASH_SALE' },
];

export const REWARD_TYPE_OPTS = {
  TRADE_PROMO: [{ label: 'Discount', value: 'DISCOUNT' }],
  LOYALTY: [{ label: 'Cashback', value: 'CASHBACK' }],
  FLASH_SALE: [{ label: 'Discount', value: 'DISCOUNT' }],
};
