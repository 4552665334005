import gql from 'graphql-tag';

export type DeleteInvoiceResult = { id: string };
export type DeleteInvoiceVars = { id: string };

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(where: { id: $id }) {
      id
    }
  }
`;
