import React, { Component } from 'react';
import { Subscription, compose } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DEFAULT_ROWS_PER_PAGE,
  DepotPicker,
  Picker,
  Query,
  ResetFilterButton,
  RowsPerPage,
  SubDistrictPicker,
  Table,
} from '../../components';
import { BLACK, GRAY, PRIMARY } from '../../constants/colors';
import { DatePickerField, Icon } from '../../core-ui';
import {
  GET_NEW_ASSIGNMENT_NOTIFICATION,
  GET_ORDER_LIST,
  GetOrderListResult,
  GetOrderListVariables,
  Order,
} from '../../graphql/queries';
import {
  asyncStorage,
  convertDepoToPostgresqlID,
  fetchMoreItems,
  refetchItems,
} from '../../helpers';
import withToast, { ToastContextProps } from '../../helpers/withToast';
import { AccessProps } from '../user/UserScene';
import { OrderDetailModal, TABLE_STRUCTURE } from './components';

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = AccessProps &
  ToastContextProps &
  SceneProps & {
    searchContent: string;
  };

type State = {
  isDetailVisible: boolean;
  actionData: Nullable<Order>;
  rowsPerPage: RowsPerPage;
  selectedDepo: Nullable<string>;
  selectedSubDistrict: Nullable<string>;
  selectedOrderDate: Nullable<Date>;
  selectedETA: Nullable<Date>;
  selectedStatus: Nullable<string>;
  nameUserDepo: string;
  idPostresqlDepo: string;
  page: number;
};

export class AssignmentList extends Component<Props, State> {
  state: State = {
    isDetailVisible: false,
    actionData: null,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    selectedDepo: null,
    selectedSubDistrict: null,
    selectedOrderDate: null,
    selectedETA: null,
    selectedStatus: null,
    nameUserDepo: '',
    idPostresqlDepo: '',
    page: 0,
  };

  async componentDidMount() {
    const nameUserDepo = (await asyncStorage.getName()) || '';
    this.setState({ nameUserDepo });

    try {
      this.setState({
        idPostresqlDepo: convertDepoToPostgresqlID(nameUserDepo),
      });
    } catch (error) {
      this.setState({ idPostresqlDepo: '' });
    }

    /*
      pastikan untuk di function helper convertDepoToPostgresqlID di ganti staging atau prodction sesuai dengan kebutuhan
    */
  }

  render() {
    return (
      <View style={styles.flex}>
        {this._renderFilters()}
        {this._renderTable()}
        {this._renderDetailModal()}
      </View>
    );
  }

  _renderFilters = () => {
    const {
      selectedDepo,
      selectedSubDistrict,
      selectedOrderDate,
      selectedETA,
      selectedStatus,
    } = this.state;
    const { access } = this.props;

    return (
      <View style={styles.filters}>
        <View style={styles.filterContainer}>
          <View style={[styles.filterRow, { zIndex: 2 }]}>
            <DepotPicker
              isFilter
              style={[styles.filterMargin, { zIndex: 3, width: 180 }]}
              selectedOption={selectedDepo}
              onChange={(selected) =>
                this.setState({
                  selectedDepo: selected ? selected.value : selected,
                })
              }
              hidden={access.role !== 'SUPER_ADMIN'}
            />
            <SubDistrictPicker
              isFilter
              style={[styles.filterMargin, { zIndex: 2, width: 180 }]}
              selectedOption={selectedSubDistrict}
              onChange={(selected) =>
                this.setState({
                  selectedSubDistrict: selected ? selected.value : selected,
                })
              }
            />
            <Picker
              label="Status"
              placeholder="Status"
              style={[styles.filterMargin, { zIndex: 2, width: 180 }]}
              options={[
                { label: 'Siap Kirim', value: 'IN_PROCESS' },
                { label: 'Sedang Kirim', value: 'IN_DELIVERY' },
                { label: 'Sudah Diterima', value: 'COMPLETED' },
              ]}
              isFilter
              selectedOption={selectedStatus}
              onChange={(selected) => {
                this.setState({
                  selectedStatus: selected ? selected.value : selected,
                });
              }}
            />
            <DatePickerField
              isFilter
              style={[styles.filterMargin, { zIndex: 2 }]}
              label="Tanggal Order"
              placeholder="Tanggal Order"
              selectedDate={selectedOrderDate}
              onChange={(date) => this.setState({ selectedOrderDate: date })}
            />
            <DatePickerField
              isFilter
              label="Tanggal Sampai"
              placeholder="Tanggal Sampai"
              selectedDate={selectedETA}
              onChange={(date) => this.setState({ selectedETA: date })}
            />
          </View>
          <View style={styles.filterRow}></View>
        </View>
        <ResetFilterButton onPress={this._clearFilter} />
      </View>
    );
  };

  _renderTable = () => {
    const { searchContent, resetPage, setResetPage } = this.props;
    const {
      selectedDepo,
      selectedStatus,
      selectedSubDistrict,
      selectedOrderDate,
      rowsPerPage: firstCount,
      idPostresqlDepo,
      page,
    } = this.state;

    const createdAt_gt = selectedOrderDate && new Date(selectedOrderDate);
    const createdAt_lt =
      createdAt_gt &&
      new Date(new Date(createdAt_gt).setHours(23, 59, 59, 999));
    const orderDate = createdAt_gt &&
      createdAt_lt && {
        createdAt_lt: createdAt_lt.toISOString(),
        createdAt_gt: createdAt_gt.toISOString(),
      };
    const dataKey = 'orders';
    return (
      <Query<GetOrderListResult, GetOrderListVariables>
        query={GET_ORDER_LIST}
        variables={{
          whereData: searchContent,
          whereDepot: selectedDepo || idPostresqlDepo || '',
          whereSubDistrict: selectedSubDistrict || '',
          whereStatus: selectedStatus
            ? [selectedStatus]
            : ['IN_PROCESS', 'IN_DELIVERY', 'COMPLETED'],
          ...orderDate,
          first: firstCount,
          skip: 0,
        }}
        keyData={dataKey}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {(result) => {
          const { data, loading, fetchMore, refetch } = result;
          if (data) {
            return (
              <Subscription
                subscription={GET_NEW_ASSIGNMENT_NOTIFICATION}
                onSubscriptionData={() => refetch()}
              >
                {() => (
                  <>
                    {this.props.renderSyncModal(() =>
                      refetchItems<GetOrderListResult, GetOrderListVariables>(
                        fetchMore,
                        {
                          query: GET_ORDER_LIST,
                          variables: {
                            whereData: searchContent,
                            whereDepot: selectedDepo || idPostresqlDepo || '',
                            whereSubDistrict: selectedSubDistrict || '',
                            whereStatus: selectedStatus
                              ? [selectedStatus]
                              : ['IN_PROCESS', 'IN_DELIVERY', 'COMPLETED'],
                            ...orderDate,
                            first: firstCount,
                            skip: page * firstCount,
                          },
                          dataKey,
                          rowsPerPage: firstCount,
                          page,
                        },
                      ),
                    )}
                    <Table
                      data={data.orders}
                      dataCount={data.count}
                      page={page}
                      onChangePage={(nextPage) =>
                        this.setState({ page: nextPage })
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      resetPage={resetPage}
                      setResetPage={setResetPage}
                      isLoading={loading}
                      structure={{
                        ...TABLE_STRUCTURE(refetch),
                        actions: {
                          render: (order) => (
                            <View style={styles.tableViewStyle}>
                              <Icon
                                size="small"
                                name="description"
                                color={GRAY}
                                hoverColor={PRIMARY}
                                onPress={() =>
                                  this.setState({
                                    isDetailVisible: true,
                                    actionData: order as Order,
                                  })
                                }
                              />
                            </View>
                          ),
                          noHeaderName: true,
                        },
                      }}
                      loadMore={({ first, skip, searchInput }) => {
                        fetchMoreItems<
                          GetOrderListResult,
                          GetOrderListVariables
                        >(fetchMore, {
                          query: GET_ORDER_LIST,
                          variables: {
                            whereData: searchInput || '',
                            whereDepot: selectedDepo || idPostresqlDepo || '',
                            whereSubDistrict: selectedSubDistrict || '',
                            whereStatus: selectedStatus
                              ? [selectedStatus]
                              : ['IN_PROCESS', 'IN_DELIVERY', 'COMPLETED'],
                            ...orderDate,
                            first,
                            skip,
                          },
                          dataKey: 'orders',
                        });
                      }}
                      onChangeRowsPerPage={(rowsPerPage) =>
                        this.setState({ rowsPerPage, page: 0 })
                      }
                    />
                    <ToastContainer />
                  </>
                )}
              </Subscription>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _renderDetailModal = () => {
    const { isDetailVisible, actionData } = this.state;
    return (
      actionData && (
        <OrderDetailModal
          isVisible={isDetailVisible}
          order={actionData}
          onClose={this._onDetailClose}
        />
      )
    );
  };

  _onDetailClose = () => {
    this.setState({ isDetailVisible: false });
  };

  _clearFilter = () => {
    this.setState({
      selectedDepo: null,
      selectedSubDistrict: null,
      selectedOrderDate: null,
      selectedETA: null,
      selectedStatus: null,
    });
  };
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  modalBody: { flex: 1, flexDirection: 'row' },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    alignItems: 'flex-start',
    paddingVertical: 20,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterContainer: {},
  filterRow: { flexDirection: 'row', flexWrap: 'wrap' },
  filterMargin: { marginRight: 15 },
});

export default compose(withToast)(AssignmentList);
