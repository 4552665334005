import gql from 'graphql-tag';

export type RejectOrderMutationResult = {
  rejectOrder: {
    id: string;
  };
};

export type PaidOrderMutationResult = {
  paidOrder: {
    id: string;
  };
};

export const REJECT_ORDER = gql`
  mutation RejectOrder($id: ID!, $role: String!, $cancelReason: String!) {
    rejectOrder(where: { id: $id }, role: $role, cancelReason: $cancelReason) {
      id
      canceledDate
    }
  }
`;

export const PAID_ORDER = gql`
  mutation PaidOrder($id: ID!) {
    paidOrder(where: { id: $id }) {
      id
    }
  }
`;

export type AcceptOrderMutationResult = {
  acceptOrder: {
    id: string;
  };
};

export const ACCEPT_ORDER = gql`
  mutation AcceptOrder($id: ID!) {
    acceptOrder(where: { id: $id }) {
      id
    }
  }
`;

export const COPY_ASSIGNMENT = gql`
  mutation CopyAssignment($id: ID!) {
    copyAssignment(assignmentId: $id) {
      id
    }
  }
`;
