import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { INPUT, LIGHTER_GRAY } from '../../../constants/colors';
import { Button, Text } from '../../../core-ui';

type Props = {
  headerText: string;
  bodyText: string;
  selectedText?: Nullable<string>;
  buttonText: string;
  onPress: () => void;
};

export default function CustomField(props: PropsWithChildren<Props>) {
  const {
    headerText,
    bodyText,
    selectedText,
    buttonText,
    onPress,
    children,
  } = props;
  return (
    <View style={styles.container}>
      <Text weight="bold">{headerText}</Text>
      <Text
        weight="light"
        size="small"
        color={INPUT.LABEL}
        style={styles.textContent}
      >
        {bodyText}
      </Text>
      {children}
      {selectedText && (
        <Text color={LIGHTER_GRAY} style={styles.marginBottom}>
          {selectedText}
        </Text>
      )}
      <Button
        text={buttonText}
        inverted
        style={{ alignSelf: 'flex-start' }}
        onPress={onPress}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  textContent: {
    marginVertical: 30,
  },
  marginBottom: {
    marginBottom: 30,
  },
});
