import gql from 'graphql-tag';
import { MutationFn } from 'react-apollo';

import { Product } from '../queries';

export type WhereProductVariable = {
  id: string;
  productID: string;
};
export type UpdateProductVariable = {
  where: WhereProductVariable;
  standardStock: number;
  data?: { photo: string };
};
export type UpdateProductResult = { product: Product };
export type UpdateProductMutation = MutationFn<
  UpdateProductResult,
  UpdateProductVariable
>;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $where: ProductWhereUniqueInput!
    $data: ProductUpdateInput
    $standardStock: Int
  ) {
    updateProduct(where: $where, data: $data, standardStock: $standardStock) {
      id
    }
  }
`;
