import React, { useState, useCallback, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { format, differenceInDays } from 'date-fns';
import { GET_SEARCH_STATE, UPDATE_SEARCH_STATE } from 'graphql/queries';
import {
  ColumnType,
  Query,
  ReactTable,
  SearchField,
} from '../../../components';
import {
  BLACK,
  DARKER_GRAY,
  GREEN_SOFT,
  RED_SOFT,
} from '../../../constants/colors';
import { Icon, Separator, Text } from '../../../core-ui';
import { LinearProgressbar } from '../components/LinearProgressbar';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { SearchState } from 'graphql/localState';
import { PaginationState } from '@tanstack/react-table';
import {
  GET_MONITOR_PROGRAMS_DETAIL_USER,
  GetMonitorProgramDetailUserListVariables,
  GetMonitorProgramDetailUserResult,
  MonitorProgramDetailUser,
} from 'graphql/queries';
import { Box, IconButton } from '@material-ui/core';
import { StatisticsBox } from '../components/StatisticsBox';
import Breadcrumb from '../components/Breadcrumb';
import { formatDecimal } from 'helpers/formatNumber';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

export type MonitoringProgramPerCustomerProps = SearchStateProps &
  UpdateSearchData &
  RouteComponentProps<{
    programID: string;
    depotID: string;
  }>;

function MonitoringProgramPerCustomerSceneComponent({
  searchStateQuery: { searchState },
  updateSearch,
  match,
}: MonitoringProgramPerCustomerProps) {
  const history = useHistory();
  const { programID, depotID } = match.params;
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [search, setSearch] = useState('');

  const calculateTimeGone = useCallback(
    (start: Nullable<string>, end: Nullable<string>) => {
      const startDate = new Date(start!);
      const endDate = new Date(end!);
      const today = new Date();

      const intervalFromStart = differenceInDays(today, startDate);
      const intervalToEnd = differenceInDays(endDate, startDate);

      const diffOfDays = intervalToEnd - intervalFromStart;
      const currentRemainingDays = diffOfDays > 0 ? diffOfDays : 0;
      const formattedStartDate = format(startDate, 'dd/MM/yyyy');
      const formattedEndDate = format(endDate, 'dd/MM/yyyy');
      const runningDays = `${
        intervalFromStart > intervalToEnd ? intervalToEnd : intervalFromStart
      }/${intervalToEnd}`;
      const percentage = Math.floor((intervalFromStart / intervalToEnd) * 100);

      return {
        currentRemainingDays,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        runningDays,
        percentage: percentage > 100 ? 100 : percentage,
      };
    },
    [],
  );

  const columns = useMemo<ColumnType<MonitorProgramDetailUser>[]>(() => {
    return [
      {
        accessorKey: 'userCode',
        header: 'Kode Pelanggan',
      },
      {
        accessorKey: 'userName',
        header: 'Customer',
      },
      {
        accessorKey: 'subSegment',
        header: 'Subsegmentasi',
      },
      {
        accessorKey: 'outletMaxQty',
        header: 'Kuota/Target',
      },
      {
        accessorKey: 'programClaimCount',
        header: 'Realisasi',
      },
      {
        accessorKey: 'achievement',
        header: 'Achievement (%)',

        cell: ({ getValue, row }) => {
          const value = getValue<number>() > 0 ? getValue<number>() : 0;
          const achievement = Number(formatDecimal(value * 100, 1));
          const timegone = calculateTimeGone(
            row.original.startDate,
            row.original.endDate,
          ).percentage;
          return (
            <LinearProgressbar
              value={achievement}
              withText
              color={achievement < timegone ? RED_SOFT : GREEN_SOFT}
            />
          );
        },
      },
      {
        accessorKey: 'gap',
        header: 'Gap',
        cell: ({ getValue }) => (
          <Text size="small" color={getValue<number>() < 0 ? RED_SOFT : BLACK}>
            {getValue()}
          </Text>
        ),
      },
    ];
  }, [calculateTimeGone]);

  return (
    <Box display="flex" flexDirection="column" padding="40px 80px">
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" style={{ gap: 4 }}>
          <IconButton onClick={() => history.goBack()}>
            <Icon name="arrow_back" color={DARKER_GRAY} />
          </IconButton>
          <Text size="xlarge">Monitoring Program</Text>
        </Box>
        <SearchField
          value={searchState ? searchState.searchedString : ''}
          onChangeText={(text) => {
            updateSearch({ variables: { searchedString: text } });
            setSearch(text);
            setPagination((prevPagination: any) => ({
              ...prevPagination,
              pageIndex: 0,
            }));
          }}
        />
      </Box>
      <Separator />

      <Query<
        GetMonitorProgramDetailUserResult,
        GetMonitorProgramDetailUserListVariables
      >
        query={GET_MONITOR_PROGRAMS_DETAIL_USER}
        variables={{
          depotID: depotID,
          programID: programID,
          first: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
          userName: search,
        }}
      >
        {({ data, loading }) => {
          if (!data?.monitorProgramDetailUser) return null;
          const monitoringData = [
            ...(data?.monitorProgramDetailUser.programDetailUser ?? []),
          ];

          const {
            totalOutletMaxQty,
            totalProgramClaimCount,
            achievement,
            gap,
          } = data.monitorProgramDetailUser.total;

          const {
            currentRemainingDays,
            startDate,
            endDate,
            runningDays,
            percentage,
          } = calculateTimeGone(
            monitoringData[0]?.startDate || null,
            monitoringData[0]?.endDate || null,
          );

          return (
            <>
              <Breadcrumb
                labels={[
                  'Monitoring Program',
                  monitoringData[0]?.programTitle,
                  monitoringData[0]?.depotName,
                ]}
              />
              <StatisticsBox
                targetTotal={totalOutletMaxQty}
                programClaimCountTotal={totalProgramClaimCount}
                achievementTotal={Number(formatDecimal(achievement * 100, 1))}
                gapTotal={gap}
                runningDays={runningDays}
                percentage={percentage}
                startDate={startDate}
                endDate={endDate}
                currentRemainingDays={currentRemainingDays}
                isLoading={loading}
              />

              <ReactTable
                columns={columns}
                data={monitoringData}
                isLoading={loading}
                pagination={pagination}
                setPagination={setPagination}
                total={data?.count || 0}
              />
            </>
          );
        }}
      </Query>
    </Box>
  );
}

export const MonitoringProgramPerCustomerScene = compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, {}, UpdateSearchData>(UPDATE_SEARCH_STATE, {
    name: 'updateSearch',
  }),
)(MonitoringProgramPerCustomerSceneComponent);
