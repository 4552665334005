import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Query } from '../../../components';
import {
  BLACK,
  DARK_GRAY,
  GRAY8,
  GREEN,
  LIGHT_RED,
  PRIMARY,
} from '../../../constants/colors';
import { Modal, Separator, Text } from '../../../core-ui';
import {
  CanceledOrder,
  Coupon,
  GET_RETURNED_ITEMS,
  GallonType,
  Order,
  ReturnedListResult,
  ReturnedListVars,
  TransactionItem,
} from '../../../graphql/queries';
import {
  convertPascalCase,
  formatNumberDate,
  formatThousandSeparator,
  getAddressDepo,
  getNameCompanyDepo,
} from '../../../helpers';

import {
  calculatePoValue,
  getPaymentStatusString,
  getPaymentTypeString,
  tipeNamaTotal,
  tipeTotalFunc,
} from '../helpers';

type Props = {
  isVisible?: boolean;
  order: Order;
  onClose?: () => void;
};

type PurchaseSummaryProps = {
  sumPriceProduct: number;
  creditPointUse: number;
  totalPrice: number;
  transactionItems?: any;
  coupon?: Coupon;
};

type OrderTableProps = {
  bottleGuarantee: Array<{
    gallonType: GallonType;
    qty: number;
    pricePerBottle: number;
  }>;
  purchaseList: Array<TransactionItem>;
  poNumber: string;
  style?: StyleProp<ViewStyle>;
  purchaseSummary: PurchaseSummaryProps;
  canceledOrders: Array<CanceledOrder>;
} & WithStyles<typeof tableStyles>;

function OrderDetailModal(props: Props) {
  const { isVisible, order, onClose } = props;

  const {
    depot,
    invoiceNumber,
    poNumber,
    user,
    paymentType,
    paymentStatus,
    createdAt: orderDate,
    transactionItems,
    creditPointUse = 0,
    bottleGuarantee,
    coupon,
    assignedDeliveryDrivers,
    canceledOrders,
  } = order;
  const {
    szID: customerId,
    name: customerName,
    storeAddress: customerAddress,
    storeTelephone: customerTelephone,
  } = user;

  const sumPriceProduct = calculatePoValue(order);
  const discount = coupon
    ? coupon.couponType === 'POTONGAN'
      ? coupon.couponRewardValue
      : 0
    : 0;
  const totalPrice = Math.max(0, sumPriceProduct - creditPointUse - discount);
  const purchaseSummary = {
    sumPriceProduct,
    creditPointUse,
    totalPrice,
    transactionItems,
    coupon,
  };

  let companyName = '';
  let companyAddress = '';

  try {
    companyName = getNameCompanyDepo(user.szID);
    companyAddress = getAddressDepo(user.szID);
  } catch (error) {
    companyName = getNameCompanyDepo('305');
    companyAddress = getAddressDepo('305');
  }

  return (
    <Modal
      title={companyName || ''}
      description={companyAddress || ''}
      isVisible={!!isVisible}
      onClose={() => onClose && onClose()}
      maxHeight
      fullWidth
      maxWidth="md"
      headerStyle={{
        title: styles.titleHeader,
        description: styles.descriptionHeader,
      }}
    >
      <View style={styles.flex}>
        <View style={[styles.row, styles.bottomSpacing]}>
          <Field label="Nama Depo" style={styles.flex}>
            {convertPascalCase(depot ? depot.title : '')}
          </Field>
          <Field label="Waktu" style={styles.flex}>
            {formatNumberDate(new Date(orderDate))}
          </Field>
        </View>
        <View style={styles.row}>
          <Field label="Nomor Invoice" style={styles.flex}>
            {invoiceNumber}
          </Field>
          <Field label="Nomor Pesanan" style={styles.flex}>
            {poNumber}
          </Field>
        </View>
        <Separator style={styles.separator} />
        <Text weight="bold" style={styles.bottomSpacing}>
          Pengiriman
        </Text>
        <View style={[styles.row, styles.bottomSpacing]}>
          <Field label="Nama Toko" style={styles.flex}>
            {user.storeName}
          </Field>
          <Field label="Kode Pelanggan" style={styles.flex}>
            {customerId}
          </Field>
        </View>
        <View style={styles.row}>
          <Field label="Alamat Tujuan" style={styles.flex}>
            {customerAddress}
          </Field>
          <Field label="Nomor HP Penerima" style={styles.flex}>
            {customerTelephone}
          </Field>
        </View>
        <Separator style={styles.separator} />
        <Text weight="bold" style={styles.bottomSpacing}>
          {assignedDeliveryDrivers && 'Driver'}
        </Text>
        {assignedDeliveryDrivers &&
          assignedDeliveryDrivers.map((item, index) => (
            <View key={index} style={styles.row}>
              <Field label="Assignment ID" style={styles.flex}>
                {item.assignedOrder.id}
              </Field>
              <Field label="Kode Driver" style={styles.flex}>
                {item.driver.driverCode}
              </Field>
              <Field label="Nama Driver" style={styles.flex}>
                {item.driver.driverName}
              </Field>
            </View>
          ))}
        {assignedDeliveryDrivers && <Separator style={styles.separator} />}
        <Text weight="bold" style={styles.bottomSpacing}>
          Pembayaran
        </Text>
        <View style={styles.row}>
          <Field label="Jenis Pembayaran" style={styles.flex}>
            {getPaymentTypeString(paymentType, false)}
          </Field>
          <Field label="Status Pembayaran" style={styles.flex}>
            {getPaymentStatusString(paymentStatus, false)}
          </Field>
        </View>
        <Separator style={styles.separator} />
        <OrderTable
          bottleGuarantee={bottleGuarantee}
          purchaseList={transactionItems}
          poNumber={poNumber}
          style={styles.bottomSpacing}
          purchaseSummary={purchaseSummary}
          canceledOrders={canceledOrders}
        />
        {/* // TODO: Commented until further information
        {PROMO && (
          <View style={styles.promoBox}>
            <Text>
              Kupon potongan harga Rp. {formatThousandSeparator(PROMO.value)}
            </Text>
          </View>
        )} */}
      </View>
    </Modal>
  );
}

/**
 * Purchase Data Table
 */
const tableStyles = createStyles({
  headerRow: { verticalAlign: 'top', height: 38 },
  headerCell: { borderWidth: 0, color: DARK_GRAY },
  bodyRow: { verticalAlign: 'center', minHeight: 42 },
  bodyCell: {
    borderWidth: 0,
    color: BLACK,
    padding: 4,
    maxWidth: 250,
  },
  footerRow: {
    borderTopWidth: 2,
    borderColor: GRAY8,
    borderTopStyle: 'solid',
    verticalAlign: 'bottom',
    height: 42,
  },
  footerCell: { color: BLACK },
});

function PurchaseSummary({ props }: { props: PurchaseSummaryProps }) {
  const {
    sumPriceProduct,
    creditPointUse,
    totalPrice,
    transactionItems,
    coupon,
  } = props;
  const [valueWidth, setValueWidth] = useState(100);
  const setWidth = (width: number) => {
    if (width > valueWidth) {
      setValueWidth(width);
    }
  };

  return (
    <View>
      <Separator style={styles.separator} />
      <Text weight="bold" style={styles.bottomSpacing}>
        Ringkasan Pembayaran
      </Text>
      <View>
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          label="Total Harga"
          style={styles.bottomSpacing}
          value={sumPriceProduct}
        />
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          label="Biaya Ongkos Kirim"
          style={styles.bottomSpacing}
          value={0}
        />
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          discount
          label="TVIP Credit Redeem"
          value={creditPointUse}
        />
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          discount
          label="Total Potongan Harga (TVIP Credit Redeem + Total Diskon)"
          value={
            tipeTotalFunc(transactionItems, tipeNamaTotal.totalDiskon) +
            creditPointUse
          }
          color={LIGHT_RED}
        />
        {coupon && (
          <SummaryField
            width={valueWidth}
            setWidth={setWidth}
            label={`KUPON ${coupon.couponType}`}
            style={styles.topSpacing}
            value={coupon.couponRewardValue}
            color={GREEN}
          />
        )}
      </View>
      <Separator style={styles.separator} />
      <SummaryField
        width={valueWidth}
        setWidth={setWidth}
        bold
        label="Total Pembayaran"
        value={totalPrice}
      />
    </View>
  );
}

const OrderTable = withStyles(tableStyles)((props: OrderTableProps) => {
  const {
    purchaseList,
    bottleGuarantee,
    style,
    classes,
    purchaseSummary,
    poNumber,
    canceledOrders,
  } = props;
  let { sumPriceProduct, totalPrice, ...restPurchaseSummary } = purchaseSummary;
  let returnedValue = 0;
  let totalDiscount = 0;

  const summedCanceledOrders = canceledOrders
    .flatMap(({ cancelReason, deliveryItems }) =>
      deliveryItems.map((item) => ({ ...item, cancelReason })),
    )
    .reduce((prev, curr) => {
      const index = prev.findIndex(
        (item) => item.transactionItem.id === curr.transactionItem.id,
      );

      if (index !== -1) {
        return [
          ...prev.filter((_, i) => i !== index),
          {
            ...curr,
            quantityDelivery:
              prev[index].quantityDelivery + curr.quantityDelivery,
          },
        ];
      }

      return [...prev, curr];
    }, [] as (CanceledOrder['deliveryItems'][number] & { cancelReason: string })[]);

  return (
    <Query<ReturnedListResult, ReturnedListVars>
      query={GET_RETURNED_ITEMS}
      variables={{ poNumber }}
      keyData="returnedItems"
      fetchPolicy="network-only"
    >
      {({ data }) => {
        if (data) {
          return (
            <>
              <View style={style}>
                <Text weight="bold" style={styles.bottomSpacing}>
                  Diskon
                </Text>
                <Table padding="none">
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Nama Produk
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Kode Produk
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Diskon
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Unit
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Harga Asli
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Harga Satuan
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseList.map(
                      (
                        {
                          products,
                          price,
                          priceCut,
                          quantity,
                          additionalProducts,
                        },
                        index,
                      ) => {
                        const tempDescount = priceCut * quantity;
                        totalDiscount += tempDescount;
                        return (
                          <TableRow key={index} className={classes.bodyRow}>
                            <TableCell className={classes.bodyCell}>
                              <View>
                                {products.map(({ productData }, idx) => (
                                  <Text key={idx}>
                                    {productData.title.toUpperCase()}
                                  </Text>
                                ))}
                                {[...(additionalProducts || [])].map(
                                  ({ name }, idx) => (
                                    <Text key={idx}>{name.toUpperCase()}</Text>
                                  ),
                                )}
                              </View>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <View>
                                {products.map(({ productData }, idx) => (
                                  <Text key={idx}>
                                    {productData.productID.toUpperCase()}
                                  </Text>
                                ))}
                              </View>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>
                                Rp. {formatThousandSeparator(priceCut)}
                              </Text>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <View>
                                {products.map(({ productData }, idx) => (
                                  <Text key={idx}>
                                    {productData.uom || '-'}
                                  </Text>
                                ))}
                              </View>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>Rp. {formatThousandSeparator(price)}</Text>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>
                                Rp. {formatThousandSeparator(price - priceCut)}
                              </Text>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow className={classes.footerRow}>
                      <TableCell className={classes.footerCell} colSpan={4}>
                        <Text>Total Diskon</Text>
                      </TableCell>
                      <TableCell className={classes.footerCell}>
                        <Text>
                          Rp. {formatThousandSeparator(totalDiscount)}
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </View>
              <Separator style={styles.separator} />
              <View style={style}>
                <Table padding="none">
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Nama Produk
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Jumlah
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Unit
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Harga Satuan
                        </Text>
                      </TableCell>
                      <TableCell className={classes.headerCell}>
                        <Text color={DARK_GRAY} size="small">
                          Subtotal
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseList.map(
                      (
                        {
                          products,
                          quantity,
                          price,
                          priceCut,
                          additionalProducts,
                        },
                        index,
                      ) => (
                        <TableRow key={index} className={classes.bodyRow}>
                          <TableCell className={classes.bodyCell}>
                            <View>
                              {products.map(({ productData }, idx) => (
                                <Text key={idx}>
                                  {productData.title.toUpperCase()}
                                </Text>
                              ))}
                              {[...(additionalProducts || [])].map(
                                ({ name }, idx) => (
                                  <Text key={idx}>{name.toUpperCase()}</Text>
                                ),
                              )}
                            </View>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>{quantity}</Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <View>
                              {products.map(({ productData }, idx) => (
                                <Text key={idx}>{productData.uom || '-'}</Text>
                              ))}
                            </View>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              Rp. {formatThousandSeparator(price - priceCut)}
                            </Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              Rp.{' '}
                              {formatThousandSeparator(
                                (price - priceCut) * quantity,
                              )}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                    {bottleGuarantee.map(
                      ({ gallonType, qty, pricePerBottle }, index) => (
                        <TableRow key={index} className={classes.bodyRow}>
                          <TableCell className={classes.bodyCell}>
                            <View>
                              <Text>{gallonType.toUpperCase()}</Text>
                              <Text color={DARK_GRAY} size="small">
                                ( Jaminan botol )
                              </Text>
                            </View>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>{qty}</Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>GALON</Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              Rp. {formatThousandSeparator(pricePerBottle)}
                            </Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              Rp.{' '}
                              {formatThousandSeparator(pricePerBottle * qty)}
                            </Text>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                    {summedCanceledOrders.map(
                      (
                        { cancelReason, transactionItem, quantityDelivery },
                        index,
                      ) => {
                        const itemPrice =
                          (transactionItem.price - transactionItem.priceCut) *
                          quantityDelivery;
                        totalPrice -= itemPrice;
                        sumPriceProduct -= itemPrice;

                        return (
                          <TableRow key={index} className={classes.bodyRow}>
                            <TableCell className={classes.bodyCell}>
                              <View>
                                <Text>
                                  {transactionItem.products[0].productData.title.toUpperCase()}
                                </Text>
                                <Text color={DARK_GRAY} size="small">
                                  {`( ${cancelReason} )`}
                                </Text>
                              </View>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>- {quantityDelivery}</Text>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>
                                {transactionItem.products[0].productData.uom}
                              </Text>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>
                                Rp.{' '}
                                {formatThousandSeparator(
                                  transactionItem.price -
                                    transactionItem.priceCut,
                                )}
                              </Text>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              <Text>
                                - Rp. {formatThousandSeparator(itemPrice)}
                              </Text>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableBody>
                  {data.returnedItems.length > 0 && (
                    <TableBody>
                      {data.returnedItems[0].returnedTransactionItems.map(
                        (
                          {
                            transactionItem: { products, price, priceCut },
                            quantity,
                          },
                          index,
                        ) => {
                          returnedValue = (price - priceCut) * quantity;
                          sumPriceProduct -= returnedValue;
                          totalPrice -= returnedValue;
                          return (
                            <TableRow key={index} className={classes.bodyRow}>
                              <TableCell className={classes.bodyCell}>
                                {products.map(({ productData }) => (
                                  <View key={productData.title}>
                                    <Text>
                                      {productData.title.toUpperCase()}
                                    </Text>
                                    <Text color={DARK_GRAY} size="small">
                                      ({' '}
                                      {data.returnedItems[0].returnedTransactionItems[
                                        index
                                      ].returnReason.toUpperCase()}{' '}
                                      )
                                    </Text>
                                  </View>
                                ))}
                              </TableCell>
                              <TableCell className={classes.bodyCell}>
                                <Text>{quantity}</Text>
                              </TableCell>
                              <TableCell className={classes.bodyCell}>
                                {products.map(({ productData }) => (
                                  <Text key={productData.uom}>
                                    {productData.uom || '-'}
                                  </Text>
                                ))}
                              </TableCell>
                              <TableCell className={classes.bodyCell}>
                                <Text>
                                  Rp.{' '}
                                  {formatThousandSeparator(price - priceCut)}
                                </Text>
                              </TableCell>
                              <TableCell className={classes.bodyCell}>
                                <Text>
                                  - Rp. {formatThousandSeparator(returnedValue)}
                                </Text>
                              </TableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow className={classes.footerRow}>
                      <TableCell className={classes.footerCell} colSpan={4}>
                        <Text>Total harga</Text>
                      </TableCell>
                      <TableCell className={classes.footerCell}>
                        <Text>
                          Rp. {formatThousandSeparator(sumPriceProduct)}
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
                <PurchaseSummary
                  props={{
                    sumPriceProduct,
                    totalPrice,
                    ...restPurchaseSummary,
                  }}
                />
              </View>
            </>
          );
        }
        return null;
      }}
    </Query>
  );
});

/**
 * Information Field - Label and Value
 */
type FieldProps = {
  label: string;
  children?: string;
  style?: StyleProp<ViewStyle>;
};

function Field({ label, children, style }: FieldProps) {
  return (
    <View style={style}>
      <Text color={DARK_GRAY} size="small" style={styles.fieldLabel}>
        {label}
      </Text>
      <Text>{children}</Text>
    </View>
  );
}

/**
 * Summary Field - Prices and Discounts
 */
type SummaryFieldProps = {
  value: number;
  discount?: boolean;
  width?: number;
  bold?: boolean;
  color?: string;
  setWidth?: (value: number) => void;
};

function SummaryField({
  label,
  style,
  value,
  discount,
  width,
  bold,
  color,
  setWidth,
}: FieldProps & SummaryFieldProps) {
  return (
    <View style={[styles.row, style]}>
      <Text weight={bold ? 'bold' : 'reg'} color={color || BLACK}>
        {label}
      </Text>
      <View style={[styles.row, styles.flex, styles.summaryValue]}>
        <Text
          style={styles.rightText}
          weight={bold ? 'bold' : 'reg'}
          color={color || BLACK}
        >
          {discount ? '- Rp. ' : 'Rp. '}
        </Text>
        <Text
          onLayout={(evt) => setWidth && setWidth(evt.nativeEvent.layout.width)}
          style={[styles.rightText, { minWidth: width }]}
          weight={bold ? 'bold' : 'reg'}
          color={color || BLACK}
        >
          {formatThousandSeparator(value <= 0 ? 0 : value)}
        </Text>
      </View>
    </View>
  );
}

export default OrderDetailModal;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  separator: {
    backgroundColor: PRIMARY,
  },
  bottomSpacing: {
    paddingBottom: 20,
  },
  topSpacing: {
    paddingTop: 20,
  },
  fieldLabel: {
    paddingBottom: 5,
  },
  promoBox: {
    alignItems: 'center',
    borderColor: PRIMARY,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'dashed',
    paddingVertical: 10,
  },
  summaryValue: {
    justifyContent: 'flex-end',
  },
  rightText: {
    textAlign: 'right',
  },
  textHeader: {
    textAlign: 'center',
    paddingBottom: 20,
  },
  titleHeader: {
    flex: 1,
    textAlign: 'center',
  },
  descriptionHeader: {
    textAlign: 'center',
    color: 'black',
  },
});
