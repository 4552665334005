import React from 'react';
import { StyleSheet, View } from 'react-native';

import { PRIMARY } from '../../constants/colors';
import { Icon } from '../../core-ui';

type Props = {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: CustomMouseEvent, page: number) => void;
};

export default function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: Props) {
  const handleBackButtonClick = (event: CustomMouseEvent) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: CustomMouseEvent) => {
    onPageChange(event, page + 1);
  };

  return (
    <View style={styles.root}>
      <Icon
        name="chevron_left"
        color={PRIMARY}
        disabled={page === 0}
        onPress={(e) =>
          // The `event` from Icon is of type `GestureResponderEvent`
          // Here, we need type `CustomMouseEvent`
          handleBackButtonClick((e as unknown) as CustomMouseEvent)
        }
        style={{ marginLeft: 50 }}
      />
      <Icon
        name="chevron_right"
        color={PRIMARY}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onPress={(e) =>
          // The `event` from Icon is of type `GestureResponderEvent`
          // Here, we need type `CustomMouseEvent`
          handleNextButtonClick((e as unknown) as CustomMouseEvent)
        }
        style={{ marginLeft: 20, marginRight: 20 }}
      />
    </View>
  );
}

let styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
});
