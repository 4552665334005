import React, { Component } from 'react';

import { ChosenProps, Picker } from '.';
import { MONTHS } from '../../constants/calendar';

type Props = { isFilter?: boolean } & ChosenProps<number>;

export class MonthPicker extends Component<Props> {
  render() {
    const { style, ...otherProps } = this.props;
    return (
      <Picker<number>
        label="Bulan"
        placeholder="Bulan"
        style={style}
        options={
          MONTHS
            ? MONTHS.map((item, index) => ({ value: index, label: item }))
            : []
        }
        {...otherProps}
      />
    );
  }
}

export default MonthPicker;
