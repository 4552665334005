import gql from 'graphql-tag';

export type CreateFaqParams = {
  data: {
    title: string;
    content: string;
    active: boolean;
  };
};
export const CREATE_FAQ = gql`
  mutation createFaq($data: FaqCreateInput!) {
    createFaq(data: $data) {
      id
    }
  }
`;

export type UpdateFaqParams = {
  id: string;
  data: {
    title: string;
    content: string;
    active: boolean;
  };
};
export const UPDATE_FAQ = gql`
  mutation updateFaq($id: ID!, $data: FaqUpdateInput!) {
    updateFaq(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export type DeleteFaqParams = { id: string };
export const DELETE_FAQ = gql`
  mutation deleteFaq($id: ID) {
    deleteFaq(where: { id: $id }) {
      id
    }
  }
`;
