import React from 'react';
import { compose, Mutation, Query } from 'react-apollo';

import { FormPage } from '../../components';
import { Card, TextField, Icon } from '../../core-ui';
import { GRAY, DARKER_GRAY } from '../../constants/colors';
import withToast, { ToastContextProps } from '../../helpers/withToast';

import {
  PROVIDER_CHANGE_PASSWORD,
  GET_PROVIDER_INFO,
  Provider,
  ProviderChangePasswordMutation,
} from '../../graphql/queries';
import { graphqlErrorRemover } from '../../helpers';

type Field = {
  value: string;
  visible: boolean;
};

type State = {
  oldPassword: Field;
  newPassword: Field;
  confirmNewPassword: Field;
};

type FieldFormProps = {
  label: string;
  value: string;
  isVisible: boolean;
  iconOnPress: () => void;
  onChangeText: (text: string) => void;
};

function FieldForm(props: FieldFormProps) {
  const { label, value, isVisible, iconOnPress, onChangeText } = props;
  return (
    <TextField
      stretch
      labelHorizontal
      secureTextEntry={isVisible}
      childrenPosition="right"
      label={label}
      placeholder={label}
      value={value}
      onChangeText={onChangeText}
    >
      <Icon
        name="remove_red_eye"
        color={isVisible ? GRAY : DARKER_GRAY}
        size="small"
        onPress={iconOnPress}
      />
    </TextField>
  );
}

type Props = ToastContextProps;

const defaultField = {
  value: '',
  visible: true,
};

export class ChangePasswordScene extends React.Component<Props, State> {
  state = {
    oldPassword: defaultField,
    newPassword: defaultField,
    confirmNewPassword: defaultField,
  };

  _isValid = () => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    return !!oldPassword && !!newPassword && !!confirmNewPassword;
  };

  _clearState = () => {
    this.setState({
      oldPassword: defaultField,
      newPassword: defaultField,
      confirmNewPassword: defaultField,
    });
  };

  _onSubmit = (
    resetPassword: ProviderChangePasswordMutation,
    userData: Provider,
  ) => {
    const { newPassword, oldPassword } = this.state;
    if (this._checkPassword()) {
      resetPassword({
        variables: {
          id: userData.id,
          email: userData.email,
          oldPassword: oldPassword.value,
          password: newPassword.value,
        },
      });
    }
  };

  _checkPassword = () => {
    const { newPassword, oldPassword, confirmNewPassword } = this.state;
    const { openToast } = this.props;
    if (!newPassword.value || !oldPassword.value || !confirmNewPassword.value) {
      openToast && openToast('fail', 'Kata sandi tidak boleh kosong');
      return false;
    }
    if (newPassword.value !== confirmNewPassword.value) {
      openToast && openToast('fail', 'Kata sandi tidak cocok');
      return false;
    }
    if (newPassword.value.length < 6) {
      openToast &&
        openToast('fail', 'Kata sandi tidak boleh kurang dari 6 karakter');
      return false;
    }
    if (oldPassword.value === newPassword.value) {
      openToast &&
        openToast('fail', 'Kata sandi lama dan baru tidak boleh sama');
      return false;
    }
    return true;
  };

  render() {
    const { openToast } = this.props;
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    return (
      <Query query={GET_PROVIDER_INFO}>
        {(result) => (
          <Mutation
            mutation={PROVIDER_CHANGE_PASSWORD}
            onCompleted={() => {
              this._clearState();
              openToast && openToast('success', 'Sandi berhasil diperbarui');
            }}
            onError={(error) =>
              openToast && openToast('fail', graphqlErrorRemover(error.message))
            }
          >
            {(resetPassword, { loading }) => (
              <FormPage
                isLoading={loading}
                isDirty={this._isValid()}
                isValid={this._isValid()}
                handleDiscard={this._clearState}
                handleSubmit={() =>
                  this._onSubmit(resetPassword, result.data.info)
                }
              >
                <Card title="Ubah Sandi">
                  <FieldForm
                    label="Sandi Lama"
                    value={oldPassword.value}
                    isVisible={oldPassword.visible}
                    onChangeText={(value: string) =>
                      this.setState({ oldPassword: { ...oldPassword, value } })
                    }
                    iconOnPress={() =>
                      this.setState({
                        oldPassword: {
                          ...oldPassword,
                          visible: !oldPassword.visible,
                        },
                      })
                    }
                  />
                  <FieldForm
                    label="Sandi Baru"
                    value={newPassword.value}
                    isVisible={newPassword.visible}
                    onChangeText={(value: string) =>
                      this.setState({ newPassword: { ...newPassword, value } })
                    }
                    iconOnPress={() =>
                      this.setState({
                        newPassword: {
                          ...newPassword,
                          visible: !newPassword.visible,
                        },
                      })
                    }
                  />
                  <FieldForm
                    label="Konfirmasi Sandi Baru"
                    value={confirmNewPassword.value}
                    isVisible={confirmNewPassword.visible}
                    onChangeText={(value: string) =>
                      this.setState({
                        confirmNewPassword: { ...confirmNewPassword, value },
                      })
                    }
                    iconOnPress={() =>
                      this.setState({
                        confirmNewPassword: {
                          ...confirmNewPassword,
                          visible: !confirmNewPassword.visible,
                        },
                      })
                    }
                  />
                </Card>
              </FormPage>
            )}
          </Mutation>
        )}
      </Query>
    );
  }
}

export default compose(withToast)(ChangePasswordScene);
