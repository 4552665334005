import React from 'react';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { PRIMARY, DARK_GRAY } from '../constants/colors';

type Size = 'small' | 'large' | number;

type Props = {
  style?: StyleProp<ViewStyle>;
  size?: Size;
  type?: 'TABLE' | 'ACTION' | 'BUTTON';
  color?: string;
};

export default function Loading(props: Props) {
  const { style, size, type = 'TABLE', color } = props;
  let defaultSize: Size = 'large';
  let defaultColor = PRIMARY;
  if (type !== 'TABLE') {
    defaultSize = 25;
    defaultColor = DARK_GRAY;
  }
  return (
    <View
      style={[
        styles.wrapper,
        style,
        type !== 'TABLE' && { backgroundColor: 'transparent' },
      ]}
    >
      <ActivityIndicator
        size={size || defaultSize}
        color={color || defaultColor}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
