import React, { ComponentClass } from 'react';

import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Button } from '../core-ui';
import { DARKER_GRAY } from '../constants/colors';

type OwnProps = {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

type Props = OwnProps & RouteComponentProps;

export function BackButton(props: Props) {
  const { history, onPress, style } = props;
  return (
    <Button
      inverted
      text="Kembali"
      color="secondary"
      icon="arrow_back"
      iconColor={DARKER_GRAY}
      style={[styles.back, style]}
      textStyle={{ color: DARKER_GRAY }}
      onPress={onPress || history.goBack}
    />
  );
}

const styles = StyleSheet.create({
  back: {
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
});

export default withRouter(BackButton) as ComponentClass<OwnProps>;
