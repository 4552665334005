// const API_LOCAL = 'http://localhost:8000/graphql';

// const API_STAGING = 'https://stg-tvip-api.now.sh/graphql';
// const API_STAGING = 'http://staging.api.sqala.id:8000/graphql';

// const API_PRO = 'http://api.sqala.id:8000/graphql';
// const API_PRO = 'http://13.215.176.38:8000/graphql';
const API_URL =
  process.env.REACT_APP_API_GRAPHQL_URL || 'http://localhost:8000/graphql';

export const API_URI = API_URL;
export const WS_URI =
  process.env.REACT_APP_WEBSOCKET_URL ||
  API_URL.replace('http', 'ws').replace('/graphql', '');

export const ECOMMERSE_URI = 'http://192.168.4.60/ecommerse_api/index.php';
