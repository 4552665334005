type tipeTotal = {
  totalDiskon: string;
  totalHargaAsali: string;
  totalHargaSetelahDiskon: string;
};

export const tipeNamaTotal: tipeTotal = {
  totalDiskon: 'totalDiskon',
  totalHargaAsali: 'totalHargaAsli',
  totalHargaSetelahDiskon: 'totalHargaSetelahDiskon',
};

export const tipeTotalFunc = (
  transaction: any,
  tipe: string,
  creditPoint?: any,
  bottol?: any,
  canceledOrderPrices: Array<number> = [],
  couponDiscount = 0,
): number => {
  let totalPriceArray: Array<number> = [];
  let totalRealPrice = 0;

  let totalBottolArray: Array<number> = [];
  let totalRealBottol = 0;

  // untuk total diskon
  if (tipe === tipeNamaTotal.totalDiskon) {
    totalPriceArray = transaction.map(
      (item: any) => item.priceCut * item.quantity,
    );

    totalRealPrice = totalPriceArray.reduce((total, num) => total + num, 0);
  }

  // untuk total harga asli
  if (tipe === tipeNamaTotal.totalHargaAsali) {
    totalPriceArray = transaction.map(
      (item: any) => item.price * item.quantity,
    );

    totalRealPrice = totalPriceArray.reduce((total, num) => total + num, 0);
  }

  // untuk total harga diskon
  if (tipe === tipeNamaTotal.totalHargaSetelahDiskon) {
    totalPriceArray = transaction.map(
      (item: any) => (item.price - item.priceCut) * item.quantity,
    );

    totalRealPrice = totalPriceArray.reduce((total, num) => total + num, 0);
  }

  if (bottol) {
    totalBottolArray = bottol.map(
      (item: any) => item.pricePerBottle * item.qty,
    );

    totalRealBottol = totalBottolArray.reduce((total, num) => total + num, 0);
  }

  // untuk canceled orders
  if (canceledOrderPrices.length > 0) {
    const totalCanceledOrderPrice = canceledOrderPrices.reduce(
      (total, num) => total + num,
      0,
    );
    totalRealPrice -= totalCanceledOrderPrice;
  }

  if (couponDiscount) {
    totalRealPrice -= couponDiscount;
  }

  totalRealPrice = bottol ? totalRealPrice + totalRealBottol : totalRealPrice;

  // untuk credit point
  totalRealPrice = creditPoint ? totalRealPrice - creditPoint : totalRealPrice;

  return totalRealPrice;
};
