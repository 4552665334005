import React, { Component } from 'react';
import { graphql } from 'react-apollo';

import {
  GET_DISTRICT_NAME_LIST,
  DistrictNameListData,
  DistrictListParams,
} from '../../graphql/queries';

import { ChosenProps, Picker } from '.';

type LocationParams = {
  city?: Nullable<string>;
};

type DistrictNameListProps = { districtQuery: DistrictNameListData };
type OwnProps = { isFilter?: boolean; disabled?: boolean } & ChosenProps &
  LocationParams;

type Props = DistrictNameListProps & OwnProps;

export class DistrictPicker extends Component<Props> {
  render() {
    const { disabled, districtQuery, style, ...otherProps } = this.props;
    return (
      <Picker
        label="Kecamatan"
        placeholder="Kecamatan"
        style={style}
        isLoading={districtQuery.loading}
        options={
          disabled
            ? []
            : districtQuery.districts
            ? districtQuery.districts.map(({ id, name }) => ({
                value: id,
                label: name.toUpperCase(), // NOTE: to adjust to lowercased data in API
              }))
            : []
        }
        {...otherProps}
      />
    );
  }
}

export default graphql<
  OwnProps,
  DistrictNameListData,
  DistrictListParams,
  DistrictNameListProps
>(GET_DISTRICT_NAME_LIST, {
  name: 'districtQuery',
  options: ({ city }) => ({
    variables: city ? { city } : {},
  }),
})(DistrictPicker);
