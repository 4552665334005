import gql from 'graphql-tag';
import { MutationFunc } from 'react-apollo';
import { Cluster } from '../queries/clusterQuery';

type AddClusterResult = {
  id: string;
};

type ClusterWithoutID = Omit<Cluster, 'id'>;
type AddClusterVariable = {
  validDate: string;
  expiredDate: string;
  segmentID: string;
  subSegmentID: string;
  products: Array<{ id: string }>;
  bronze: ClusterWithoutID;
  silver: ClusterWithoutID;
  gold: ClusterWithoutID;
  diamond: ClusterWithoutID;
};

type UpdateClusterVariable = AddClusterVariable & { id: string };
export type AddClusterMutation = MutationFunc<
  AddClusterResult,
  AddClusterVariable
>;

export type UpdateClusterMutation = MutationFunc<
  AddClusterResult,
  UpdateClusterVariable
>;

export const ADD_CLUSTERS = gql`
  mutation AddCluster(
    $validDate: DateTime!
    $expiredDate: DateTime!
    $segmentID: ID
    $subSegmentID: ID
    $products: [ProductWhereUniqueInput!]
    $bronze: ClusterObjectCreateInput
    $silver: ClusterObjectCreateInput
    $gold: ClusterObjectCreateInput
    $diamond: ClusterObjectCreateInput
  ) {
    createCluster(
      data: {
        validDate: $validDate
        expiredDate: $expiredDate
        segment: { connect: { id: $segmentID } }
        subSegment: { connect: { id: $subSegmentID } }
        products: { connect: $products }
        clusterGroup: {
          create: {
            bronze: { create: $bronze }
            silver: { create: $silver }
            gold: { create: $gold }
            diamond: { create: $diamond }
          }
        }
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CLUSTERS = gql`
  mutation UpdateCluster(
    $id: ID!
    $validDate: DateTime!
    $expiredDate: DateTime!
    $segmentID: ID
    $subSegmentID: ID
    $products: [ProductWhereUniqueInput!]
    $bronze: ClusterObjectUpdateDataInput
    $silver: ClusterObjectUpdateDataInput
    $gold: ClusterObjectUpdateDataInput
    $diamond: ClusterObjectUpdateDataInput
  ) {
    updateCluster(
      where: { id: $id }
      data: {
        validDate: $validDate
        expiredDate: $expiredDate
        segment: { connect: { id: $segmentID } }
        subSegment: { connect: { id: $subSegmentID } }
        products: { connect: $products }
        clusterGroup: {
          update: {
            bronze: { update: $bronze }
            silver: { update: $silver }
            gold: { update: $gold }
            diamond: { update: $diamond }
          }
        }
      }
    ) {
      id
    }
  }
`;

export const CREATE_POINT_PRICE_CLUSTERS = gql`
  mutation AddPointPriceCluster($pointPrice: Int!, $pointDate: DateTime!) {
    createPointPriceCluster(
      data: { pointPrice: $pointPrice, pointDate: $pointDate }
    ) {
      id
      pointDate
      pointPrice
      active
    }
  }
`;

export const CREATE_CLUSTER_CYCLE = gql`
  mutation CreateClusterCycle($cycleTime: Int!) {
    createClusterCycle(data: { cycleTime: $cycleTime }) {
      id
      cycleTime
    }
  }
`;
