import React, { PropsWithChildren, ReactComponentElement } from 'react';
import { ImageBackground, View, StyleSheet } from 'react-native';
import { LOGIN_BOX_WIDTH } from '../../../constants/boxSize';

import { background } from '../../../assets';

function LandingBackground({ children }: PropsWithChildren<unknown>) {
  return (
    <ImageBackground
      source={{ uri: background }}
      style={styles.container}
      resizeMode="cover"
    >
      <View style={styles.formWraper}>{children}</View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    minHeight: 800,
  },
  formWraper: {
    width: LOGIN_BOX_WIDTH,
    paddingVertical: 50,
    paddingHorizontal: 125,
    justifyContent: 'center',
  },
});

export default LandingBackground;
