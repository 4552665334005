import { ApolloCache } from 'apollo-cache';

import { GET_SEARCH_STATE } from '../queries';
import { SearchState } from '../localState';

type SearchedParam = {
  searchedString: string;
};

export const updateSearchState = (
  _: ObjectKey,
  { searchedString }: SearchedParam,
  { cache }: { cache: ApolloCache<any> },
) => {
  const previousState: Nullable<SearchState> = cache.readQuery({
    query: GET_SEARCH_STATE,
  });
  if (previousState) {
    const data = {
      searchState: {
        ...previousState.searchState,
        searchedString,
      },
    };
    cache.writeQuery({ query: GET_SEARCH_STATE, data });
  }
  return null;
};
