import { ComponentClass, FC } from 'react';

import {
  AdminFormScene,
  AdminListScene,
  AdminRoleFormScene,
  AdminRoleScene,
  AssigmentScene,
  BusinessTrendScene,
  ChangePassword,
  ClosingDayScene,
  ClusterInformationScene,
  ClusterScene,
  CouponForm,
  CouponListScene,
  CustomerServiceScene,
  Dashboard,
  EditProfileScene,
  FAQFormScene,
  ForgotPasswordScene,
  FrequentlyAskedScene,
  HeaderHighlightFormScene,
  HeaderHighlightScene,
  LoginScene,
  MonitoringProgramPerCustomerScene,
  MonitoringProgramPerDepoScene,
  MonitoringProgramScene,
  NotFoundPage,
  OrderScene,
  ProductFormScene,
  ProductScene,
  ProgramFormScene,
  ProgramListScene,
  RatingScene,
  SegmentScene,
  SelectAsTopScene,
  SetNewPasswordScene,
  TopScene,
  UserScene,
} from '../scenes';

export type Access = {
  page: string;
  role: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
};

export type RouteItem = {
  path?: string;
  strict?: boolean;
  exact?: boolean;
  component: ComponentClass<any, any> | FC<any>;
  nickname?: string;
};

export type RouteItemWithAccess = Access & RouteItem;

const DEFAULT_ROUTES: Array<RouteItem> = [
  {
    path: '/',
    strict: true,
    exact: true,
    component: LoginScene,
    nickname: 'Login',
  },
  {
    path: '/forgot-password',
    strict: true,
    exact: true,
    component: ForgotPasswordScene,
  },
  {
    path: '/set-new-password/:token',
    strict: true,
    exact: true,
    component: SetNewPasswordScene,
  },
  { component: NotFoundPage },
];

const AUTHENTICATED_ROUTES: Array<RouteItem> = [
  {
    path: '/',
    strict: true,
    exact: true,
    component: Dashboard,
    nickname: 'Dashboard',
  },
  {
    path: '/admin-roles',
    exact: true,
    component: AdminRoleScene,
    nickname: 'Daftar Peran',
  },
  {
    path: '/admin-roles/:id',
    strict: true,
    exact: true,
    component: AdminRoleFormScene,
    nickname: 'Daftar Peran',
  },
  {
    path: '/users',
    strict: true,
    exact: true,
    component: UserScene,
    nickname: 'Profil Pelanggan',
  },
  {
    path: '/products',
    strict: true,
    exact: true,
    component: ProductScene,
    nickname: 'Daftar Produk',
  },
  {
    path: '/faqs',
    strict: true,
    exact: true,
    component: FrequentlyAskedScene,
    nickname: 'Bantuan',
  },
  {
    path: '/faqs/:id',
    strict: true,
    exact: true,
    component: FAQFormScene,
    nickname: 'Bantuan',
  },
  {
    path: '/users/:id',
    strict: true,
    exact: true,
    component: EditProfileScene,
    nickname: 'Profil Pelanggan',
  },
  {
    path: '/programs',
    strict: true,
    exact: true,
    component: ProgramListScene,
    nickname: 'Program',
  },
  {
    path: '/programs/:id',
    strict: true,
    exact: true,
    component: ProgramFormScene,
    nickname: 'Program',
  },
  {
    path: '/clusters',
    strict: true,
    exact: true,
    component: ClusterScene,
    nickname: 'Cluster',
  },
  {
    path: '/clusters/:id',
    strict: true,
    exact: true,
    component: ClusterInformationScene,
    nickname: 'Cluster',
  },
  {
    path: '/products/:id',
    strict: true,
    exact: true,
    component: ProductFormScene,
    nickname: 'Daftar Produk',
  },
  {
    path: '/purchase-orders',
    strict: true,
    exact: true,
    component: OrderScene,
    nickname: 'Pemesanan',
  },
  {
    path: '/ratings',
    strict: true,
    exact: true,
    component: RatingScene,
    nickname: 'Penilaian',
  },
  {
    path: '/tops',
    strict: true,
    exact: true,
    component: TopScene,
    nickname: 'TOP',
  },
  {
    path: '/closing',
    strict: true,
    exact: true,
    component: ClosingDayScene,
    nickname: 'Tutup Penjualan',
  },
  {
    path: '/segments',
    strict: true,
    exact: true,
    component: SegmentScene,
    nickname: 'Segmentasi',
  },
  {
    path: '/tops/:id/assign',
    strict: true,
    exact: true,
    component: SelectAsTopScene,
    nickname: 'TOP',
  },
  {
    path: '/settings',
    strict: true,
    exact: true,
    component: ChangePassword,
  },
  {
    path: '/admin-list',
    strict: true,
    exact: true,
    component: AdminListScene,
    nickname: 'Daftar Admin',
  },
  {
    path: '/admin-list/:id',
    strict: true,
    exact: true,
    component: AdminFormScene,
    nickname: 'Daftar Admin',
  },
  {
    path: '/customer-service',
    strict: true,
    exact: true,
    component: CustomerServiceScene,
    nickname: 'Layanan Pelanggan',
  },
  {
    path: '/coupons',
    strict: true,
    exact: true,
    component: CouponListScene,
    nickname: 'Kupon',
  },
  {
    path: '/coupons/:id',
    strict: true,
    exact: true,
    component: CouponForm,
    nickname: 'Kupon',
  },
  {
    path: '/assignment', // yang dwiki tambahkan
    strict: true,
    exact: true,
    component: AssigmentScene,
    nickname: 'Assignment',
  },
  {
    path: '/header-highlights',
    strict: true,
    exact: true,
    component: HeaderHighlightScene,
    nickname: 'Header',
  },
  {
    path: '/header-highlights/new',
    strict: true,
    exact: true,
    component: HeaderHighlightFormScene,
    nickname: 'Header',
  },
  {
    path: '/header-highlights/:id',
    strict: true,
    exact: true,
    component: HeaderHighlightFormScene,
    nickname: 'Header',
  },
  {
    path: '/monitoring-program',
    strict: true,
    exact: true,
    component: MonitoringProgramScene,
  },
  {
    path: '/monitoring-program/depo/:programID',
    strict: true,
    exact: true,
    component: MonitoringProgramPerDepoScene,
    nickname: 'Header',
  },
  {
    path: '/monitoring-program/customer/:programID/:depotID',
    strict: true,
    exact: true,
    component: MonitoringProgramPerCustomerScene,
    nickname: 'Monitoring Program',
  },
  {
    path: '/business-trend',
    strict: true,
    exact: true,
    component: BusinessTrendScene,
    nickname: 'Trend Bisnis',
  },
  { component: NotFoundPage, path: '*' },
];

export { AUTHENTICATED_ROUTES, DEFAULT_ROUTES };
