import gql from 'graphql-tag';
import {
  bottleGuarantee,
  canceledOrders,
  coupon,
  depot,
  transactionItems,
  user,
} from '../relationalFragments';
import { AdditionalProduct, Coupon } from './couponQuery';
import { GallonType } from './gallonPriceQuery';
import { ReturnedTransactionItem } from './invoiceQuery';
import { Product } from './productQuery';

export type OrderStatus =
  | 'WAITING'
  | 'IN_PROCESS'
  | 'IN_DELIVERY'
  | 'PENDING'
  | 'COMPLETED'
  | 'CANCELED_BY_ADMIN'
  | 'CANCELED_BY_DRIVER'
  | 'CANCELED_BY_CUSTOMER';

export type PaymentType = 'TRANSFER' | 'CASH';

export type PaymentStatus = 'PAID' | 'UNPAID';

export type TransactionProduct = {
  productData: Product;
  unitQuantity: number;
};

export type TransactionItem = {
  id: string;
  products: Array<TransactionProduct>;
  additionalProducts: Array<AdditionalProduct>;
  description: string;
  quantity: number;
  price: number;
  priceCut: number;
};

export type CanceledOrder = {
  cancelReason: string;
  deliveryItems: Array<{
    quantityDelivery: number;
    transactionItem: TransactionItem;
  }>;
};

export type Order = {
  id: string;
  status: OrderStatus;
  depot: Nullable<{
    title: string;
    description: string;
    location: {
      subDistrict: {
        name: string;
      };
    };
  }>;
  invoiceNumber: string;
  poNumber: string;
  user: {
    id: string;
    szID: string;
    name: string;
    storeName: string;
    storeAddress: string;
    storeTelephone: string;
  };
  bottleGuarantee: Array<{
    gallonType: GallonType;
    qty: number;
    pricePerBottle: number;
  }>;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  createdAt: string;
  deliveredDate?: string;
  cancelReason?: string;
  canceledDate?: string;
  coupon?: Coupon;
  transactionItems: Array<TransactionItem>;
  canceledOrders: Array<CanceledOrder>;
  top: boolean;
  closingID?: string;
  invoiceDate: string;
  creditPointUse: number;
  assignedDeliveryDrivers: Array<{
    assignedOrder: {
      id: string;
      cancelReason: string;
      pendingReason: string;
      status: string;
    };
    driver: {
      driverCode: any;
      driverName: any;
    };
  }>;
  isCopied: boolean;
  returnedTransactionItems: ReturnedTransactionItem[];
};

export type GetOrderListResult = { orders: Array<Order>; count: number };
export type GetOrderListVariables = {
  whereStatus?: Array<string>;
  createdAt_gt?: Nullable<string>;
  createdAt_lt?: Nullable<string>;
  deliveredDate_gt?: Nullable<string>;
  deliveredDate_lt?: Nullable<string>;
  whereDepot?: Nullable<string>;
  whereSubDistrict?: Nullable<string>;
  whereData?: Nullable<string>;
  wherePaymentStatus?: Nullable<PaymentStatus>;
  whereUserID?: Nullable<string>;
  skip: number;
  first: number;
};

export type GetOrderResult = { order: Order };

const GET_ORDER_LIST_PARAMS = `
   {
    status_in: $whereStatus,
    depot: {
      id_contains: $whereDepot,
      coverage_some: {
        subDistrict: {
          id_contains: $whereSubDistrict
        }
      }
    },
    user: {
      id: $whereUserID
    }
    paymentStatus: $wherePaymentStatus,
    createdAt_gt: $createdAt_gt,
    createdAt_lt: $createdAt_lt,
    deliveredDate_gt: $deliveredDate_gt,
    deliveredDate_lt: $deliveredDate_lt,
    OR: [
      {
        invoiceNumber_contains: $whereData
      },
      {
        poNumber_contains: $whereData
      },
      {
        user: {
          storeName_contains: $whereData
        }
      },
      {
        depot: {
          title_contains: $whereData
        }
      },
      {
        depot: {
          coverage_some: {
            subDistrict: {
              name_contains: $whereData
            }
          }
        }
      }
    ]
  }
`;

const GET_ORDER_LIST_QUERY = `
  query getOrderList(
    $whereStatus: [OrderStatus!],
    $createdAt_gt: DateTime,
    $createdAt_lt: DateTime,
    $deliveredDate_gt: DateTime,
    $deliveredDate_lt: DateTime,
    $whereDepot: ID,
    $whereSubDistrict: ID,
    $whereData: String,
    $wherePaymentStatus: PaymentStatus,
    $whereUserID: ID,
    $first: Int,
    $skip: Int) {
    orders(
      where: ${GET_ORDER_LIST_PARAMS},
      first: $first,
      skip: $skip
    )
`;

export const ORDER_RETURN_VARIABLES = `
      id
      status
      ${depot}
      invoiceNumber
      poNumber
      ${user}
      paymentType
      paymentStatus
      createdAt
      deliveredDate
      canceledDate
      cancelReason
      ${coupon}
      ${transactionItems}
      ${canceledOrders}
      invoiceDate
      creditPointUse
      bottleGuarantee {
        gallonType
        qty
        pricePerBottle
      }
      assignedDeliveryDrivers {
        assignedOrder {
          id
          cancelReason
          pendingReason
          status
        }
        driver {
          driverCode
          driverName
        }
      }
      isCopied
      returnedTransactionItems {
        quantity
        transactionItem {
          quantity
          price
          priceCut
        }
      }
`;

export const GET_GALLON_GUARANTEE = gql`
  query gallon($where: TransactionWhereInput) {
    orders(where: $where) {
      ${ORDER_RETURN_VARIABLES}
      ${bottleGuarantee}
    }
    count: dataCount(node: ORDER, orderWhere: $where)
  }
`;

export const GET_ORDER_LIST = gql`
    ${GET_ORDER_LIST_QUERY}
    {
      ${ORDER_RETURN_VARIABLES}
    }
    count: dataCount(node: ORDER, orderWhere: ${GET_ORDER_LIST_PARAMS})
  }
`;

export const GET_NEW_ORDER_NOTIFICATION = gql`
  subscription getNewOrderNotification($token: String!) {
    newOrderNotification(token: $token) {
      id
    }
  }
`;

export const GET_NEW_REJECTED_ORDER_NOTIFICATION = gql`
  subscription getNewRejectedOrderNotification {
    cancelOrderByCustomerNotification {
      id
    }
  }
`;

export const GET_NAVBAR_COUNTER = gql`
  query getNavbarCounter(
    $whereStatus: [OrderStatus!],
    $createdAt_gt: DateTime,
    $createdAt_lt: DateTime,
    $deliveredDate_gt: DateTime,
    $deliveredDate_lt: DateTime,
    $whereDepot: ID,
    $whereSubDistrict: ID,
    $whereData: String,
    $wherePaymentStatus: PaymentStatus,
    $whereUserID: ID) {
    orderCount: dataCount(node: ORDER, orderWhere: ${GET_ORDER_LIST_PARAMS})
    assignmentCount: getCountNewAssignments
  }
`;

export const GET_COUNT_NEW_ASSIGNMENTS = gql`
  query {
    count: getCountNewAssignments
  }
`;

export const GET_NEW_ASSIGNMENT_NOTIFICATION = gql`
  subscription getNewAssignmentNotification {
    newAssignDriverNotification {
      id
    }
  }
`;
