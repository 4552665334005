import { ColumnType, Query } from 'components';
import { Text } from 'core-ui';
import {
  GET_RETURNED_ITEMS,
  Order,
  OrderStatus,
  ReturnedListResult,
  ReturnedListVars,
} from 'graphql/queries';
import { formatNumberDate, formatThousandSeparator } from 'helpers';
import React from 'react';
import { FlatList } from 'react-native';
import {
  getOrderStatusString,
  getPaymentTypeString,
} from 'scenes/order/helpers';
import { calculatePoValue, tipeTotalFunc, tipeTotalName } from '../../helpers';

export const newOrderListColumns: ColumnType<Order>[] = [
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => getOrderStatusString(getValue<OrderStatus>()),
  },
  {
    accessorKey: 'depot.title',
    header: 'Depo',
    size: 200,
  },
  {
    accessorKey: 'user.location.subDistrict.name',
    header: 'Kelurahan',
    size: 200,
  },
  {
    accessorKey: 'invoiceNumber',
    header: 'Invoice',
  },
  {
    id: 'realPriceTotal',
    header: 'Total Harga Asli',
    cell: ({ row }) => {
      const { transactionItems, bottleGuarantee } = row.original;

      return `Rp. ${formatThousandSeparator(
        tipeTotalFunc(
          transactionItems,
          tipeTotalName.totalRealPrice,
          0,
          bottleGuarantee,
        ),
      )}`;
    },
  },
  {
    id: 'bottleGuarantee',
    header: 'Jaminan Botol',
    cell: ({ row }) => {
      const { bottleGuarantee } = row.original;

      const botolArray: Array<number> = bottleGuarantee.map(
        (item) => item.pricePerBottle * item.qty,
      );

      const totalBotolJaminan: number = botolArray.reduce(
        (total, num) => total + num,
        0,
      );

      return `Rp. ${formatThousandSeparator(totalBotolJaminan)}`;
    },
  },
  {
    id: 'coupon',
    header: 'Coupon',
    cell: ({ row }) => {
      const { coupon } = row.original;

      const tampilCoupon = coupon ? coupon.couponRewardValue : 0;
      const tipeCoupon = coupon ? coupon.couponType : '';
      return `Rp. ${formatThousandSeparator(tampilCoupon)} - ${tipeCoupon}`;
    },
  },
  {
    id: 'discount',
    header: 'Total Discount',
    cell: ({ row }) => {
      const { transactionItems } = row.original;

      return `Rp. ${formatThousandSeparator(
        tipeTotalFunc(transactionItems, tipeTotalName.totalDiscount),
      )}`;
    },
  },
  {
    accessorKey: 'creditPointUse',
    header: 'Credit Point',
    format: 'currency',
  },
  {
    id: 'totalPriceCut',
    header: 'Total Potongan Harga',
    cell: ({ row }) => {
      const { transactionItems, creditPointUse } = row.original;

      return `Rp. ${formatThousandSeparator(
        tipeTotalFunc(transactionItems, tipeTotalName.totalDiscount) +
          creditPointUse,
      )}`;
    },
  },
  {
    id: 'totalPrice',
    header: 'Total Harga',
    cell: ({ row }) => {
      const {
        transactionItems,
        creditPointUse,
        bottleGuarantee,
      } = row.original;

      return `Rp. ${formatThousandSeparator(
        tipeTotalFunc(
          transactionItems,
          tipeTotalName.totalPriceAfterDiscount,
          creditPointUse,
          bottleGuarantee,
        ),
      )}`;
    },
  },
  {
    accessorKey: 'poNumber',
    header: 'No. PO',
    size: 200,
  },
  {
    accessorKey: 'user.szID',
    header: 'Kode Pelanggan',
  },
  {
    accessorKey: 'user.storeName',
    header: 'Nama Toko',
  },
  {
    accessorKey: 'createdAt',
    header: 'Tanggal Order',
    format: 'datetime-tz',
  },
  { accessorKey: 'deliveredDate', header: 'Deliveri', format: 'datetime-tz' },
  {
    id: 'eta',
    header: 'Estimasi',
    size: 200,
    cell: ({ row }) => {
      const { createdAt } = row.original;

      const eta = new Date(createdAt);

      if (eta.getDay() >= 1) {
        if (eta.getHours() >= 14) {
          if (eta.getMinutes() >= 0) {
            eta.setDate(eta.getDate() + 2);
          }
        } else {
          eta.setDate(eta.getDate() + 1);
        }
      } else {
        eta.setDate(eta.getDate() + 2);
      }

      return formatNumberDate(eta);
    },
  },
  {
    id: 'paymentType',
    header: 'Metode Pembayaran',
    cell: ({ row }) => getPaymentTypeString(row.original.paymentType),
  },
];

export const processedOrderListColumns: ColumnType<Order>[] = [
  ...newOrderListColumns.map((column: ColumnType<Order>) => {
    if (column.id === 'totalPrice') {
      return {
        ...column,
        cell: ({ row }) => {
          const {
            transactionItems,
            creditPointUse,
            bottleGuarantee,
            canceledOrders,
            coupon,
            returnedTransactionItems,
          } = row.original;

          return `Rp. ${formatThousandSeparator(
            tipeTotalFunc(
              transactionItems,
              tipeTotalName.totalPriceAfterDiscount,
              creditPointUse,
              bottleGuarantee,
              canceledOrders.flatMap((order) =>
                order.deliveryItems.map(
                  (item) =>
                    (item.transactionItem.price -
                      item.transactionItem.priceCut) *
                    item.quantityDelivery,
                ),
              ),
              coupon && coupon.couponType === 'POTONGAN'
                ? coupon.couponRewardValue
                : 0,
              returnedTransactionItems.reduce(
                (acc, curr) =>
                  acc +
                  (curr.transactionItem.price - curr.transactionItem.priceCut) *
                    curr.quantity,
                0,
              ),
            ),
          )}`;
        },
      };
    }

    return column;
  }),
  {
    id: 'pendingReason',
    header: 'Alasan Tunda',
    cell: ({ row }) => {
      const { assignedDeliveryDrivers } = row.original;

      return (
        <FlatList
          data={assignedDeliveryDrivers}
          renderItem={({ item: { assignedOrder }, index }) => (
            <Text
              key={index}
              size="small"
              weight="reg"
              style={{ letterSpacing: 1.5 }}
            >
              {assignedOrder.pendingReason}
            </Text>
          )}
        />
      );
    },
  },
];

export const gallonGuaranteeListColumns: ColumnType<Order>[] = [
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => getOrderStatusString(getValue<OrderStatus>()),
  },
  {
    accessorKey: 'depot.title',
    header: 'Depo',
    size: 200,
  },
  {
    accessorKey: 'user.location.subDistrict.name',
    header: 'Kelurahan',
    size: 200,
  },
  {
    accessorKey: 'invoiceNumber',
    header: 'Invoice',
  },
  {
    accessorKey: 'poNumber',
    header: 'No PO',
  },
  {
    accessorKey: 'user.szID',
    header: 'Kode Pelanggan',
  },
  {
    accessorKey: 'user.storeName',
    header: 'Nama Toko',
  },
  {
    accessorKey: 'createdAt',
    header: 'Tanggal Order',
    format: 'datetime-tz',
  },
  {
    id: 'bottleQty',
    header: 'Jumlah Botol',
    cell: ({ row }) => {
      const { bottleGuarantee } = row.original;
      const bottleQty = bottleGuarantee.reduce(
        (prev, curr) => prev + curr.qty,
        0,
      );

      return bottleQty;
    },
  },
  {
    id: 'poValue',
    header: 'Nilai PO',
    cell: ({ row }) => {
      const { poNumber } = row.original;
      let poValue = calculatePoValue(row.original);

      return (
        <Query<ReturnedListResult, ReturnedListVars>
          query={GET_RETURNED_ITEMS}
          variables={{ poNumber }}
          keyData="returnedItems"
          fetchPolicy="network-only"
        >
          {({ data }) => {
            if (data && data.returnedItems.length) {
              for (const {
                transactionItem: { price },
                quantity,
              } of data.returnedItems[0].returnedTransactionItems) {
                poValue -= price * quantity;
              }
            }
            return (
              <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
                {`Rp. ${formatThousandSeparator(poValue)}`}
              </Text>
            );
          }}
        </Query>
      );
    },
  },
  {
    id: 'paymentType',
    header: 'Metode Pembayaran',
    cell: ({ row }) => getPaymentTypeString(row.original.paymentType),
  },
];
