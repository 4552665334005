import React, { Component, Fragment } from 'react';
import { View, StyleSheet } from 'react-native';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { Icon } from '../../core-ui';
import { Table, RowsPerPage, Query } from '../../components';
import { fetchMoreItems, refetchItems } from '../../helpers';
import { PRIMARY, GRAY } from '../../constants/colors';
import {
  GET_PRODUCT_LIST,
  Product,
  AccessProps,
  GetProductListResult,
  GetProductListVariables,
} from '../../graphql/queries';

import ProductDetailModal from './ProductDetailModal';

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
};

type Props = RouteComponentProps &
  AccessProps &
  SceneProps & { searchContent: string };

type State = {
  resetPage: boolean;
  rowsPerPage: RowsPerPage;
  isDetailVisible: boolean;
  actionData: Nullable<Product>;
  page: number;
};

export class ProductListScene extends Component<Props, State> {
  state: State = {
    resetPage: false,
    rowsPerPage: 10 as RowsPerPage,
    isDetailVisible: false,
    actionData: null,
    page: 0,
  };

  render() {
    return (
      <>
        {this._renderDetailModal()}
        {this._renderTable()}
      </>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { searchContent: prevSearch } = prevProps;
    const { searchContent } = this.props;
    if (prevSearch !== searchContent) {
      this.setState({ resetPage: true });
    }
  }

  _renderTable = () => {
    const { searchContent, access } = this.props;
    const { rowsPerPage, resetPage, page } = this.state;
    const dataKey = 'products';
    return (
      <Query<GetProductListResult, GetProductListVariables>
        query={GET_PRODUCT_LIST}
        variables={{
          where: {
            searchKeyword: searchContent.toLowerCase(),
          },
          first: rowsPerPage,
          skip: 0,
        }}
        keyData={dataKey}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({ loading, data, fetchMore }) => {
          if (data && data.products) {
            // console.log(data)
            return (
              <>
                {this.props.renderSyncModal(() =>
                  refetchItems<GetProductListResult, GetProductListVariables>(
                    fetchMore,
                    {
                      query: GET_PRODUCT_LIST,
                      variables: {
                        where: { searchKeyword: searchContent.toLowerCase() },
                        first: rowsPerPage,
                        skip: page * rowsPerPage,
                      },
                      dataKey,
                      rowsPerPage,
                      page,
                    },
                  ),
                )}
                <Table
                  data={data.products}
                  dataCount={data.count}
                  resetPage={resetPage}
                  setResetPage={(reset) => this.setState({ resetPage: reset })}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({ page: nextPage })}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    title: {
                      headerTitle: 'Nama Produk',
                    },
                    productID: {
                      headerTitle: 'ID Produk',
                    },
                    stock: {
                      headerTitle: 'Stock Onhand',
                      processor: (stock) => String(Number(stock)),
                    },
                    actions: {
                      render: (datum) => (
                        <View style={styles.actionWrapper}>
                          <Icon
                            size="small"
                            name="description"
                            color={GRAY}
                            hoverColor={PRIMARY}
                            onPress={() =>
                              this.setState({
                                isDetailVisible: true,
                                actionData: datum as Product,
                              })
                            }
                          />
                          {access.update && (
                            <Link
                              to={{
                                pathname: `products/${datum.id}`,
                                state: { product: datum },
                              }}
                            >
                              <Icon
                                size="small"
                                name="edit"
                                color={GRAY}
                                hoverColor={PRIMARY}
                              />
                            </Link>
                          )}
                        </View>
                      ),
                      noHeaderName: true,
                    },
                  }}
                  loadMore={({ first, skip, searchInput }) => {
                    fetchMoreItems<
                      GetProductListResult,
                      GetProductListVariables
                    >(fetchMore, {
                      query: GET_PRODUCT_LIST,
                      variables: {
                        where: {
                          searchKeyword: searchInput
                            ? searchInput.toLowerCase()
                            : '',
                        },
                        first,
                        skip,
                      },
                      dataKey: 'products',
                    });
                  }}
                  onChangeRowsPerPage={(amountRows) =>
                    this.setState({ rowsPerPage: amountRows, page: 0 })
                  }
                />
              </>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _renderDetailModal = () => {
    const { actionData, isDetailVisible } = this.state;
    return (
      isDetailVisible &&
      actionData && (
        <ProductDetailModal
          product={actionData}
          onClose={this._closeDetailModal}
        />
      )
    );
  };

  _closeDetailModal = () => {
    this.setState({ isDetailVisible: false });
  };
}

const styles = StyleSheet.create({
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

export default withRouter(ProductListScene);
