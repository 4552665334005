export function formatThousandSeparator(n: number) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function sanitizeNumericInput(input: string) {
  return input.replace(/\D/g, '').trim();
}

export function parseInteger(input: string) {
  const value = Number(sanitizeNumericInput(input));
  return !isNaN(value) ? value : 0;
}

export function parseFloat(input: string) {
  const value = Number(input.replace(/[^\d\.]/g, '').trim());
  return !isNaN(value) ? value : 0;
}

export function formatPriceToRupiah(price: number) {
  const formatted = `Rp ${formatThousandSeparator(price)}`;
  return formatted;
}

export function formatDecimal(input: number, decimal: number) {
  if (input.toString().includes('.')) {
    const result = Number(input).toFixed(decimal);
    return Number(result);
  }
  return formatThousandSeparator(input);
}

export function convertToDecimal(input: number, decimal?: number) {
  if (input > 0) {
    if (input.toString().includes('.')) {
      const inputValue = input.toFixed(decimal ? decimal : 1);
      const parts = Number(inputValue)
        .toString()
        .split('.');
      const numberPart = parts[0];
      const decimalPart = parts[1];

      return (
        formatThousandSeparator(Number(numberPart)) +
        (decimalPart ? ',' + decimalPart : '')
      );
    }
    return formatThousandSeparator(input);
  }
  return input;
}
