function isEqual(a: any, b: any): boolean {
  if (a == null && b == null) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a instanceof Map && b instanceof Map) {
    return isMapEqual(a, b);
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return isArrayEqual(a, b);
  }
  if (
    typeof a === 'object' &&
    typeof b === 'object' &&
    a.constructor.name === 'Date' &&
    b.constructor.name === 'Date'
  ) {
    const dateA: Date = a;
    const dateB: Date = b;
    return dateA.toISOString() === dateB.toISOString();
  }
  if (typeof a === 'object' && typeof b === 'object') {
    return isObjectEqual(a, b);
  }
  return a === b;
}

export function isMapEqual(a: Map<any, any>, b: Map<any, any>): boolean {
  if (a.size !== b.size) {
    return false;
  }
  let res = true;
  a.forEach((val, key) => {
    if (!isEqual(val, b.get(key))) {
      res = false;
    }
  });
  return res;
}

export function isObjectEqual(a: ObjectKey, b: ObjectKey): boolean {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key) => isEqual(a[key], b[key]));
}

export function isArrayEqual(a: Array<any>, b: Array<any>): boolean {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((_el, i) => isEqual(a[i], b[i]));
}
