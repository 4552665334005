import React, { Component, ComponentClass } from 'react';
import { View, StyleSheet } from 'react-native';
import { compose, graphql, MutationFunc, DataValue } from 'react-apollo';

import {
  Button,
  Icon,
  Modal,
  Separator,
  TextField,
  Text,
  IncrementField,
} from '../../../core-ui';
import { Query } from '../../../components';
import {
  UPDATE_MODAL_SELECTED,
  GET_MODAL_SELECTED_STATE,
  GET_PRODUCT_LIST,
  GetProductListResult,
  Product,
  AdditionalProduct,
  CouponProduct,
} from '../../../graphql/queries';
import { ModalState } from '../../../graphql/localState';

import InputField from './InputField';
import Table from './Table';
import SearchField from './SearchField';
import {
  DARKER_GRAY,
  DARK_GRAY,
  GRAY,
  PRIMARY,
} from '../../../constants/colors';

type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState, {}>;
};

type UpdateSelectedVariables = { selectedArray: Array<any> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};

type OwnProps = {
  selectedItems: Array<CouponProduct>;
  onClose: () => void;
  onChangeSelected: (selectedItems: Array<CouponProduct>) => void;
  otherProducts: Array<AdditionalProduct>;
  onChangeOtherProductFields: (value: Array<AdditionalProduct>) => void;
};

type Props = OwnProps & UpdateSelectedData & SelectedStateProps;

type State = {
  resetPage: boolean;
  searchKey: string;
  selectedItems: Array<CouponProduct>;
};

class ProductSelectionModal extends Component<Props, State> {
  state = {
    resetPage: false,
    searchKey: '',
    selectedItems: this.props.selectedItems || [],
  };

  componentDidMount() {
    const { selectedItems, updateMultiTable } = this.props;
    // Show checked for selected products
    updateMultiTable({
      variables: {
        selectedArray: selectedItems
          ? selectedItems.map((datum) => datum.product)
          : [],
      },
    });
  }

  componentWillUnmount() {
    const { updateMultiTable } = this.props;
    // Remove selected state
    updateMultiTable({
      variables: {
        selectedArray: [],
      },
    });
  }

  render() {
    const { resetPage, searchKey } = this.state;
    const { otherProducts, onChangeOtherProductFields } = this.props;

    return (
      <Modal
        maxHeight
        isVisible
        title="Pilih Produk"
        onClose={this._closeModal}
        buttonText="Pilih"
        onSubmit={() => this._onSubmit()}
      >
        <View>
          <Text color={DARK_GRAY} style={styles.modalTextHeader}>
            Tentukan produk yang dapat dibeli dengan menggunakan jenis program
            ini
          </Text>
          <SearchField
            placeholder="Cari produk..."
            value={searchKey}
            onChangeText={this._onSearch}
          />
          <Separator style={styles.separator} />
          <Query<GetProductListResult>
            query={GET_PRODUCT_LIST}
            variables={{
              where: {
                searchKeyword: searchKey.toLowerCase(),
              },
            }}
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
          >
            {({ data, loading }) => {
              if (data && data.products) {
                const { products } = data;
                return (
                  <Table
                    resetPage={resetPage}
                    setResetPage={(isReset) =>
                      this.setState({ resetPage: isReset })
                    }
                    isLoading={loading}
                    showCheckboxes
                    searchKey={searchKey}
                    data={products}
                    rowPerPage={products.length}
                    dataCount={products.length}
                    hidePagination={false}
                    structure={{
                      title: {
                        headerTitle: 'Nama Produk',
                      },
                      qty: {
                        headerTitle: 'QTY',
                        render: (data, _, selected) => {
                          const qty = this._findSelectedProduct(data as Product)
                            ?.quantity;
                          return (
                            <InputField
                              initialValue={qty ?? 1}
                              selected={selected}
                              type="increment"
                              onUpdate={(value) => {
                                if (value >= 0) {
                                  this._updateValue(data as Product, value);
                                }
                              }}
                            />
                          );
                        },
                      },
                    }}
                    loadMore={() => {}}
                  />
                );
              }
              return null;
            }}
          </Query>
          <Separator style={styles.separator} />
          <View style={styles.otherProductsFieldsWrapper}>
            <Text color={DARKER_GRAY} style={styles.otherProductsTextTitle}>
              {'Produk lain diluar SKU (Optional)'}
            </Text>
            {otherProducts.map((val, index) => {
              return (
                <View key={index} style={styles.otherProductsFields}>
                  <View style={styles.otherProductsTextInput}>
                    <TextField
                      style={{ height: 20, paddingVertical: 10 }}
                      stretch
                      placeholder="Nama Produk"
                      value={val.name}
                      onChangeText={(text) =>
                        this._onChangeOtherProductName(text, index)
                      }
                    />
                  </View>
                  <View style={styles.otherProductsNumberInput}>
                    <IncrementField
                      stretch
                      value={val.quantity}
                      editable={true}
                      onChangeValue={(value) => {
                        this._onChangeOtherProductQty(value, index);
                      }}
                      onButtonPress={(value) =>
                        this._onChangeOtherProductQty(value, index)
                      }
                    />
                  </View>
                  <View style={styles.otherProductsDeleteButton}>
                    <Icon
                      size="small"
                      name="delete"
                      color={GRAY}
                      hoverColor={PRIMARY}
                      onPress={() => {
                        this._removeProduct(index);
                      }}
                    />
                  </View>
                </View>
              );
            })}
            <View style={styles.otherProductsAddButton}>
              <Button
                small
                stretch
                onPress={() => {
                  onChangeOtherProductFields([
                    ...otherProducts,
                    { name: '', quantity: 1 },
                  ]);
                }}
              >
                + Tambah Jenis
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  _findSelectedProduct = (product: Product): CouponProduct | null => {
    const { id } = product;
    const { selectedItems } = this.state;
    const isSelected = selectedItems.filter((item) => item.product.id === id);
    if (isSelected.length > 0) {
      return isSelected[0];
    } else {
      return null;
    }
  };

  _onChangeOtherProductName = (text: string, index: number) => {
    const { otherProducts, onChangeOtherProductFields } = this.props;
    const newOptionalProducts = [...otherProducts];
    newOptionalProducts![index].name = text;
    onChangeOtherProductFields(newOptionalProducts);
  };

  _onChangeOtherProductQty = (qty: number, index: number) => {
    const { otherProducts, onChangeOtherProductFields } = this.props;

    if (qty > 0) {
      const newOptionalProducts = [...otherProducts];
      newOptionalProducts![index].quantity = qty;
      onChangeOtherProductFields(newOptionalProducts);
    }
  };

  _removeProduct = (index: number) => {
    const { otherProducts, onChangeOtherProductFields } = this.props;
    const newOptionalProducts = [...otherProducts];
    newOptionalProducts.splice(index, 1);
    onChangeOtherProductFields(newOptionalProducts);
  };

  _onSearch = (searchKey: string) => {
    this.setState({ searchKey, resetPage: true });
  };

  _updateValue = (newItem: Product, newValue: number) => {
    this.setState((prevState) => {
      const { selectedItems } = prevState;
      let newSelected = [...selectedItems];
      if (
        selectedItems.filter(
          (bundleProduct) => bundleProduct.product.id === newItem.id,
        ).length > 0
      ) {
        newSelected = selectedItems.map((bundleProduct) =>
          bundleProduct.product.id === newItem.id
            ? { ...bundleProduct, quantity: newValue }
            : bundleProduct,
        );
      } else {
        newSelected.push({
          product: newItem,
          quantity: newValue,
        });
      }
      return { selectedItems: newSelected };
    });
  };

  _onSubmit = () => {
    const {
      onChangeSelected,
      selectedStateQuery: { modalState },
      onClose,
    } = this.props;
    if (modalState && modalState.selectedArray) {
      const { selectedItems } = this.state;
      const newSelectedItems: Array<CouponProduct> = [];
      modalState.selectedArray.forEach((datum: Product) => {
        const isSelected = selectedItems.filter(
          (item) => item.product.id === datum.id,
        );
        if (isSelected.length === 0) {
          newSelectedItems.push({
            product: datum,
            quantity: 1,
          });
        } else {
          newSelectedItems.push({ ...isSelected[0] });
        }
      });
      onChangeSelected(newSelectedItems);
      onClose();
    }
  };

  _closeModal = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingBottom: { paddingBottom: 20 },
  separator: { marginVertical: 30 },
  modalWrapper: {
    width: 500,
  },
  modalTextHeader: { paddingBottom: 20, fontSize: 14 },
  otherProductsTextTitle: { paddingBottom: 20, fontSize: 14 },
  otherProductsFieldsWrapper: {
    alignItems: 'flex-start',
    paddingHorizontal: 10,
  },
  otherProductsFields: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
  },
  otherProductsTextInput: {
    display: 'flex',
    flex: 6,
    marginRight: 10,
  },
  otherProductsNumberInput: {
    flex: 6,
  },
  otherProductsDeleteButton: {
    flex: 1,
  },
  otherProductsAddButton: {
    marginBottom: 20,
    width: '100%',
  },
});

export default compose(
  graphql<OwnProps, ModalState, {}, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<OwnProps, UpdateSelectedData, {}, OwnProps & UpdateSelectedData>(
    UPDATE_MODAL_SELECTED,
    { name: 'updateMultiTable' },
  ),
)(ProductSelectionModal) as ComponentClass<OwnProps>;
