import React, { ReactNode } from 'react';
// import {StyleSheet} from 'react-native';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { Loading } from '../core-ui';
import { MAP_SIZE } from '../constants/boxSize';

export type Position = {
  lat: number;
  lng: number;
};
type Props = {
  markerPosition: Position;
  children?: ReactNode;
};

function MapView(props: Props) {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDwhPwse0m8MYkBVVKpomIBfLzo_s_NlGE"
      loadingElement={<Loading />}
    >
      <GoogleMap
        center={props.markerPosition}
        mapContainerStyle={{ width: MAP_SIZE, height: MAP_SIZE }}
        mapTypeId="roadmap"
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          overviewMapControl: false,
          panControl: false,
          rotateControl: false,
          scaleControl: false,
          signInControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
        zoom={15}
      >
        <Marker
          position={props.markerPosition}
          // NOTE: if the icon is too big, we can adjust it but we need to send a new icon
          // icon={{url: '', scaledSize: {width: 30, height: 50}}}
        />
      </GoogleMap>
    </LoadScript>
  );
}

// const styles = StyleSheet.create({
//   container: {height: 200, width: 200},
//   map: {height: '100%', width: '100%'},
// });

export default MapView;
