import gql from 'graphql-tag';

export type UpdateSearchVariables = {
  searchedString: string;
};

export const GET_SEARCH_STATE = gql`
  query {
    searchState @client {
      searchedString
    }
  }
`;

export const UPDATE_SEARCH_STATE = gql`
  mutation updateSearch($searchedString: String) {
    updateSearch(searchedString: $searchedString) @client
  }
`;
