import React from 'react';

import { ChosenProps, Picker } from '.';

type OwnProps = { isFilter?: boolean } & ChosenProps;
type Props = OwnProps;

const PROGRAMS = [
  {
    id: 'TRADE_PROMO',
    name: 'Trade Promo',
  },
  {
    id: 'LOYALTY',
    name: 'Loyalty',
  },
  {
    id: 'BUNDLE',
    name: 'Bundle',
  },
  {
    id: 'FLASH_SALE',
    name: 'Flash Sale',
  },
];

export default function ProgramPicker(props: Props) {
  const { style, ...otherProps } = props;
  return (
    <Picker
      label="Jenis Program"
      placeholder="Jenis Program"
      style={style}
      options={
        PROGRAMS
          ? PROGRAMS.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          : []
      }
      {...otherProps}
    />
  );
}
