import {
  OrderStatus,
  PaymentType,
  PaymentStatus,
} from '../../../graphql/queries';

export function getOrderStatusString(input: OrderStatus) {
  switch (input) {
    case 'WAITING':
      return 'MENUNGGU';
    case 'IN_PROCESS':
      return 'SIAP DIKIRIM';
    case 'IN_DELIVERY':
      return 'SEDANG DIKIRIM';
    case 'PENDING':
      return 'SEDANG DITUNDA';
    case 'COMPLETED':
      return 'SUDAH DITERIMA';
    case 'CANCELED_BY_DRIVER':
      return 'DIBATALKAN DRIVER';
    case 'CANCELED_BY_CUSTOMER':
      return 'DIBATALKAN CUSTOMER';
    case 'CANCELED_BY_ADMIN':
      return 'DIBATALKAN ADMIN';
    default:
      return '-';
  }
}

export function getPaymentTypeString(input: PaymentType, uppercase = true) {
  let output = '-';
  switch (input) {
    case 'TRANSFER':
      output = 'Transfer Bank';
      break;
    case 'CASH':
      output = 'Tunai';
      break;
  }
  return uppercase ? output.toUpperCase() : output;
}

export function getPaymentStatusString(input: PaymentStatus, uppercase = true) {
  let output = '-';
  switch (input) {
    case 'PAID':
      output = 'Lunas';
      break;
    case 'UNPAID':
      output = 'Belum Lunas';
      break;
  }
  return uppercase ? output.toUpperCase() : output;
}
