import React, { Component, ReactNode } from 'react';
import { compose, graphql, DataValue, Mutation } from 'react-apollo';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { RouteComponentProps } from 'react-router-dom';

import { NotFoundPage } from '..';
import ProductPrice from './ProductPrice';

import { FormPage, ErrorScreen } from '../../components';
import { Text, Dropzone, Card, Loading } from '../../core-ui';
import { GRAY70 } from '../../constants/colors';
import {
  isObjectEqual,
  formatThousandSeparator,
  graphqlErrorRemover,
} from '../../helpers';
import withToast, { ToastContextProps } from '../../helpers/withToast';

import {
  GET_PRODUCT_BY_ID,
  GetProductResult,
  GetProductVariables,
  AccessProps,
} from '../../graphql/queries';

import {
  UPLOAD_PHOTO,
  UPDATE_PRODUCT,
  UploadPhotoVariables,
  UploadPhotoMutationFn,
  UpdateProductMutation,
  WhereProductVariable,
} from '../../graphql/mutations';

type RouteState = {};
type UploadPhotoProps = { uploadPhoto: UploadPhotoMutationFn };
type MatchParams = { id: string }; // NOTE: this has to match the uri described in routeUtils marked with colon
type GetProductProps = { productQuery: DataValue<GetProductResult, {}> };
type RouteProps = RouteComponentProps<MatchParams, any, RouteState>;
type Props = AccessProps &
  ToastContextProps &
  GetProductProps &
  RouteProps &
  UploadPhotoProps;

type State = {
  photo: string;
  isLoading: boolean;
  standardStock?: number;
  photoFile?: File;
};

const DEFAULT_STATE = { photo: '', isLoading: false, standardStock: 0 };

export class ProductForm extends Component<Props, State> {
  state: State = DEFAULT_STATE;

  componentDidMount() {
    const { productQuery } = this.props;
    if (productQuery.product) {
      const { photo, stock: standardStock } = productQuery.product;
      this.setState({ photo, standardStock });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { productQuery: prevQuery } = prevProps;
    const { productQuery: currQuery } = this.props;
    if (prevQuery.loading && !currQuery.loading && currQuery.product) {
      const { photo, stock: standardStock } = currQuery.product;
      this.setState({ photo, standardStock });
    }
  }

  render() {
    const { photo, isLoading, standardStock } = this.state;
    const { location, access, productQuery, openToast } = this.props;

    if (!location.state || !access.read || !access.update) {
      return <NotFoundPage />;
    }

    if (productQuery.loading) {
      return (
        <View style={styles.emptyScene}>
          <Loading />
        </View>
      );
    }
    if (productQuery.error) {
      return (
        <View style={styles.emptyScene}>
          <ErrorScreen
            detailMessage={graphqlErrorRemover(productQuery.error.message)}
          />
        </View>
      );
    }
    if (productQuery.product) {
      const { product } = productQuery;
      const {
        id,
        productID,
        title,
        nickname,
        uom,
        description,
        dimension,
        photo: photoProduct,
      } = product;
      const imageProduct = photo || photoProduct;

      return (
        <Mutation
          mutation={UPDATE_PRODUCT}
          onCompleted={() =>
            openToast && openToast('success', 'Produk berhasil diperbharui')
          }
          onError={(error) =>
            openToast && openToast('fail', graphqlErrorRemover(error.message))
          }
        >
          {(updateProduct, { loading }) => (
            <FormPage
              showBackButton
              isSubmitLoading={loading || isLoading}
              isDirty={this._isFormDirty()}
              isValid
              handleDiscard={() =>
                this.setState({ ...this._getInitialState() })
              }
              handleGoBack={this._handleBack}
              handleSubmit={() =>
                this._submitForm(updateProduct, { id, productID })
              }
            >
              <Card title="Ubah Informasi Produk">
                {/* 
                    TODO: Change `Kategori Produk`, `Informasi Isi Kemasan`, `Dimensi Produk` 
                    when the backend is complete
                  */}
                <Field label="Nama Produk" value={title.toUpperCase()} />
                <Field
                  label="Kategori Produk"
                  value={(nickname || '').toUpperCase()}
                />
                <Field label="Informasi Isi Kemasan" value={uom} />
                <Field label="Dimensi Produk" value={dimension || ''} />
                <Field label="ID Produk" value={productID} />
                <Field
                  label="Stock Onhand"
                  value={
                    standardStock == null
                      ? ''
                      : formatThousandSeparator(standardStock!)
                  }
                />
                <Field label="Harga Produk" style={{ zIndex: 2 }}>
                  <ProductPrice productID={id} />
                </Field>
                <Field label="Deskripsi Produk" value={description} />
                <View style={styles.imageField}>
                  <Text
                    size="small"
                    color={GRAY70}
                    style={styles.imageFieldTitle}
                  >
                    Foto Produk
                  </Text>
                  <View style={styles.dropzone}>
                    <Dropzone
                      multiple={false}
                      getPreview={(previews) =>
                        this.setState({
                          photo: previews[0].preview,
                          photoFile: previews[0].file,
                        })
                      }
                      source={imageProduct ? { uri: imageProduct } : undefined}
                    />
                  </View>
                </View>
              </Card>
            </FormPage>
          )}
        </Mutation>
      );
    }
    return <View />;
  }

  _onChangeText = <T extends keyof State>(key: T, value: State[T]) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  _isFormDirty = () => !isObjectEqual(this._getInitialState(), this.state);

  _getInitialState = (): State => {
    const { productQuery } = this.props;
    if (productQuery.product) {
      const { photo, stock: standardStock } = productQuery.product;
      return { photo, standardStock, isLoading: false };
    }
    return DEFAULT_STATE;
  };

  _submitForm = async (
    updateProduct: UpdateProductMutation,
    { id, productID }: WhereProductVariable,
  ) => {
    const { openToast, uploadPhoto } = this.props;
    const { photoFile, standardStock } = this.state;
    let data: { photo: string } | undefined;

    this.setState({ isLoading: true });

    if (photoFile) {
      const photoUrl = await uploadPhoto({
        variables: {
          file: photoFile,
          uploadType: 'PRODUCT',
        },
      });
      data =
        photoUrl && photoUrl.data
          ? { photo: photoUrl.data.uploadPhoto.url }
          : undefined;
    }

    await updateProduct({
      variables: {
        where: { id, productID },
        data,
        standardStock: standardStock || 0,
      },
    });
    this.setState({ isLoading: false });
    openToast && openToast('success', 'Informasi produk telah diubah');
    this._handleBack();
  };

  _handleBack = () => {
    const { history } = this.props;
    history && history.goBack();
  };
}

type FieldProps = {
  label: string;
  value?: string;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

function Field(props: FieldProps) {
  const { label, value, children, style } = props;
  return (
    <View style={[styles.fieldContainer, style]}>
      <Text size="small" style={styles.fieldLabel}>
        {label}
      </Text>
      <View style={styles.fieldValueWrapper}>
        {children || (
          <Text size="small" style={styles.fieldValue}>
            {value || '-'}
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyScene: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageField: {
    flexDirection: 'row',
  },
  imageFieldTitle: {
    flex: 1,
    paddingVertical: 8,
  },
  dropzone: {
    flex: 2,
  },
  fieldContainer: {
    flex: 1,
    flexBasis: 'auto',
    marginBottom: 32,
    flexDirection: 'row',
  },
  fieldLabel: {
    flex: 1,
    paddingVertical: 8,
    color: GRAY70,
  },
  fieldValueWrapper: {
    flex: 2,
    justifyContent: 'flex-start',
  },
  fieldValue: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  priceSegment: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
});

export default compose(
  withToast,
  graphql<RouteProps, GetProductProps, GetProductVariables, GetProductProps>(
    GET_PRODUCT_BY_ID,
    {
      name: 'productQuery',
      options: (props) => ({
        variables: { id: props.match.params.id },
      }),
    },
  ),
  graphql<RouteProps, UploadPhotoProps, UploadPhotoVariables, UploadPhotoProps>(
    UPLOAD_PHOTO,
    {
      name: 'uploadPhoto',
    },
  ),
)(ProductForm);
