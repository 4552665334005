import gql from 'graphql-tag';
import { USER_DATA_WITHOUT_CLUSTER } from '../../../graphql/queries';

const GET_USER_KTP_PENDING = gql`
  query($where: UserWhereInput!, $first: Int, $skip: Int) {
    users: usersWithPendingDoc(where: $where, docType: KTP, first: $first, skip: $skip) {
      ${USER_DATA_WITHOUT_CLUSTER}
    }
    count: dataCount(node: USER, userWhere: {AND: [{approvalKtp: PENDING}, $where]})
  }
`;

const GET_USER_KTP_LOG = gql`
  query getUserDocumentLog($userId: ID!) {
    userDocumentActionLogs(where: { id: $userId }) {
      status
      message
      createdAt
      rejectCategory {
        description
      }
    }
  }
`;

const GET_REJECT_CATEGORIES = gql`
  query {
    documentRejectCategories {
      description
    }
  }
`;

export { GET_USER_KTP_PENDING, GET_USER_KTP_LOG, GET_REJECT_CATEGORIES };
