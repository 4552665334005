import { ApolloCache } from 'apollo-cache';

import { GET_TABLE_SELECTED_STATE } from '../queries';
import { SelectedState } from '../localState';

type SelectedParams = {
  selectedArray: Array<any>;
};

export const updateSelectedState = (
  _: ObjectKey,
  { selectedArray }: SelectedParams,
  { cache }: { cache: ApolloCache<any> },
) => {
  const previousState: Nullable<SelectedState> = cache.readQuery({
    query: GET_TABLE_SELECTED_STATE,
  });
  if (previousState) {
    const data: SelectedState = {
      selectedState: {
        ...previousState.selectedState,
        selectedArray,
      },
    };
    cache.writeQuery({ query: GET_TABLE_SELECTED_STATE, data });
  }
  return null;
};
