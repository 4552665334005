import React, { Component, Fragment } from 'react';

import { graphql } from 'react-apollo';
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native';

import { Form } from '.';
import {
  Modal,
  Button,
  CurrencyField,
  Dropdown,
  Icon,
  Text,
  TextField,
  Option,
  Loading,
} from '../core-ui';
import { DARK_GRAY, BLACK, GRAY } from '../constants/colors';
import { GET_SEGMENTS, SegmentListData } from '../graphql/queries';

export type SegmentPriceInput = {
  segmentID: string;
  price: number;
};

type SegmentTitleProps = { segmentTitlesQuery: SegmentListData };
type OwnProps = {
  priceList: Array<SegmentPriceInput>;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  onChange?: (newPriceList: Array<SegmentPriceInput>) => void;
};
type Props = OwnProps & SegmentTitleProps;

type State = {
  editIndex: Nullable<number>;
  isModalVisible: boolean;
  segmentOptions: Array<Option>;
  selectedSegmentID: Nullable<string>;
  priceText: string;
};

export class PriceInput extends Component<Props, State> {
  state: State = {
    editIndex: null,
    isModalVisible: false,
    segmentOptions: [],
    selectedSegmentID: null,
    priceText: '',
  };

  componentDidUpdate(prevProps: Props) {
    const { segmentTitlesQuery: segmentQuery } = this.props;
    const { segmentTitlesQuery: prevSegmentQuery } = prevProps;
    const { segmentOptions } = this.state;
    if (
      !prevSegmentQuery.loading &&
      prevSegmentQuery.segments &&
      !segmentOptions.length
    ) {
      // NOTE: have fetched before and is cached so first query already holds result
      this.setState({
        segmentOptions: prevSegmentQuery.segments.map(({ id, title }) => ({
          value: id,
          label: title,
        })),
      });
    } else if (
      prevSegmentQuery.loading && // NOTE: previously still loading
      !segmentQuery.loading && // NOTE: next is done
      segmentQuery.segments // NOTE: check data existence
    ) {
      this.setState({
        segmentOptions: segmentQuery.segments.map(({ id, title }) => ({
          value: id,
          label: title,
        })),
      });
    }
  }

  render() {
    const { containerStyle, segmentTitlesQuery, disabled } = this.props;
    return segmentTitlesQuery.loading ? (
      <Loading />
    ) : (
      <View style={containerStyle}>
        {this._renderInputForm()}
        {this._renderPriceList()}
        {!disabled && (
          <Button
            inverted
            text="Tentukan Harga"
            onPress={() => this.setState({ isModalVisible: true })}
            style={styles.showModalButton}
          />
        )}
      </View>
    );
  }

  _getSegmentOptions = () => {
    const { priceList } = this.props;
    const { segmentOptions, editIndex } = this.state;
    return editIndex != null
      ? segmentOptions.filter(
          ({ value }) =>
            value === priceList[editIndex || 0].segmentID ||
            !priceList.find(({ segmentID }) => value === segmentID),
        )
      : segmentOptions.filter(
          ({ value }) =>
            !priceList.find(({ segmentID }) => value === segmentID),
        );
  };

  _renderInputForm = () => {
    const { isModalVisible, selectedSegmentID, priceText } = this.state;
    return (
      <Modal
        title="Tentukan Harga"
        isVisible={isModalVisible}
        onClose={this._resetState}
      >
        <Form onSubmit={this._onSubmit}>
          <Text size="small" color={DARK_GRAY}>
            Masukkan harga sesuai dengan segmentasi yang Anda pilih.
          </Text>
          <Dropdown
            placeholder="Segmentasi"
            label="Segmentasi"
            style={[styles.field, styles.dropdown]}
            options={this._getSegmentOptions()}
            selectedOption={selectedSegmentID}
            onChange={(selected) =>
              this.setState({
                selectedSegmentID: selected ? selected.value : null,
              })
            }
          />
          <CurrencyField
            stretch
            label="Harga Produk"
            placeholder="Harga Produk"
            style={styles.field}
            currencyValue={Number(priceText)}
            onCurrencyChange={(value) =>
              this.setState({ priceText: String(value) })
            }
          />
          <Button
            disabled={!this._isFormValid()}
            text="Simpan"
            style={styles.submitButton}
            onPress={this._onSubmit}
          />
        </Form>
      </Modal>
    );
  };

  _renderPriceList = () => {
    const { priceList, disabled } = this.props;
    return priceList.map(({ segmentID, price }, index) => (
      <View style={styles.segmentPrice} key={index}>
        <Text size="small" color={DARK_GRAY} style={styles.horizontalSpace}>
          {index + 1}.
        </Text>
        <TextField
          label="Segmentasi"
          value={this._getSegmentTitle(segmentID)}
          editable={false}
          style={{
            container: styles.horizontalSpace,
            textField: styles.disabledText,
          }}
        />
        <CurrencyField
          label="Harga"
          currencyValue={price}
          editable={false}
          style={styles.horizontalSpace}
          color={BLACK}
        />
        {!disabled && (
          <>
            <View style={styles.horizontalSpace}>
              <Icon
                name="edit"
                size="small"
                color={GRAY}
                onPress={() => this._editPrice(index)}
              />
            </View>
            <Icon
              name="highlight_off"
              size="small"
              color={GRAY}
              onPress={() => this._removePrice(segmentID)}
            />
          </>
        )}
      </View>
    ));
  };

  _getSegmentTitle = (segmentID: string) => {
    const { segmentTitlesQuery } = this.props;
    if (segmentTitlesQuery.segments) {
      const segment = segmentTitlesQuery.segments.find(
        ({ id }) => id === segmentID,
      );
      return segment ? segment.title : '';
    }
    return '';
  };

  _resetState = () =>
    this.setState({
      editIndex: null,
      isModalVisible: false,
      selectedSegmentID: null,
      priceText: '',
    });

  _isFormValid = () => {
    const { selectedSegmentID, priceText } = this.state;
    return selectedSegmentID != null && priceText !== '';
  };

  _onSubmit = () => {
    const { priceList, onChange } = this.props;
    const { selectedSegmentID, priceText, editIndex } = this.state;
    // NOTE: this 'if' check is only necessary for typescript
    if (selectedSegmentID != null && priceText !== '') {
      const newSegmentPrice = {
        segmentID: selectedSegmentID,
        price: Number(priceText),
      };
      const newPriceList =
        editIndex != null
          ? priceList.map((currentPrice, idx) =>
              idx === editIndex ? newSegmentPrice : currentPrice,
            )
          : [...priceList, newSegmentPrice];
      onChange && onChange(newPriceList);
      this._resetState();
    }
  };

  _editPrice = (index: number) => {
    const { priceList } = this.props;
    const { segmentID, price } = priceList[index];
    this.setState({
      editIndex: index,
      isModalVisible: true,
      selectedSegmentID: segmentID,
      priceText: String(price),
    });
  };

  _removePrice = (segmentToRemove: string) => {
    const { priceList, onChange } = this.props;
    onChange &&
      onChange(
        priceList.filter(({ segmentID }) => segmentID !== segmentToRemove),
      );
  };
}

const styles = StyleSheet.create({
  field: { paddingTop: 20 },
  dropdown: { zIndex: 2 },
  submitButton: { alignSelf: 'flex-end', marginTop: 20 },
  segmentPrice: {
    flexDirection: 'row',
    paddingBottom: 20,
    alignItems: 'flex-start',
  },
  horizontalSpace: { paddingRight: 10 },
  disabledText: { color: BLACK, cursor: 'default' },
  showModalButton: { alignSelf: 'flex-start' },
});

export default graphql<OwnProps, SegmentTitleProps, {}, SegmentTitleProps>(
  GET_SEGMENTS,
  { name: 'segmentTitlesQuery' },
)(PriceInput);
