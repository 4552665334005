import gql from 'graphql-tag';

export type UpdateGallonPriceResult = { id: string };
export type UpdateGallonPriceVars = { id: string; data: { price: number } };

export const UPDATE_GALLON_PRICE = gql`
  mutation updateGallonPrice($id: ID!, $data: GallonPriceUpdateInput!) {
    updateGallonPrice(where: { id: $id }, data: $data) {
      id
    }
  }
`;
