import React from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Table,
  RowsPerPage,
  Query,
  DEFAULT_ROWS_PER_PAGE,
} from '../../components';
import { Icon, Separator, Text } from '../../core-ui';
import { fetchMoreItems } from '../../helpers';
import { GRAY, PRIMARY } from '../../constants/colors';
import {
  GET_RATINGS_WITHDRIVER,
  GetRatingsVariables,
  GetRatingsResult,
} from '../../graphql/queries';

export type Rating = {
  id: string;
  customerName: string;
  rating: number;
  testimonial: string;
};

type State = {
  rowsPerPage: RowsPerPage;
  page: number;
};

type Props = {};

export default class RatingScene extends React.Component<Props, State> {
  state: State = {
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    page: 0,
  };

  render() {
    return (
      <View style={styles.root}>
        <View style={styles.header}>
          <Text size="xlarge">Penilaian</Text>
        </View>
        <Separator />
        <View style={styles.content}>{this._renderTable()}</View>
      </View>
    );
  }

  _renderTable = () => (
    <Query<GetRatingsResult>
      query={GET_RATINGS_WITHDRIVER}
      variables={{ first: this.state.rowsPerPage, skip: 0 }}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange
    >
      {({ data, loading, fetchMore }) => {
        if (data) {
          return (
            <Table
              data={data.ratingWithDriver}
              dataCount={data.count}
              page={this.state.page}
              onChangePage={(nextPage) => this.setState({ page: nextPage })}
              rowsPerPage={this.state.rowsPerPage}
              isLoading={loading}
              structure={{
                customer_code: {
                  headerTitle: 'Kode Pelanggan',
                  alias: 'customer_code',
                },
                customer_name: {
                  headerTitle: 'Nama Pelanggan',
                  alias: 'customer_name',
                },
                store_name: {
                  headerTitle: 'Nama Toko',
                  alias: 'store_name',
                },
                driver_name: {
                  headerTitle: 'Nama Driver',
                  alias: 'driver_name',
                },
                Penilaian: {
                  render: ({ point }) => {
                    const ratingComp = [];
                    for (let i = 0; i < 4; i++) {
                      ratingComp.push(
                        <Icon
                          key={i}
                          name="star"
                          size="small"
                          color={point > i ? PRIMARY : GRAY}
                        />,
                      );
                    }

                    return <View style={styles.flexRow}>{ratingComp}</View>;
                  },
                },
                review: { headerTitle: 'Testimoni Pelanggan' },
              }}
              loadMore={({ first, skip }) => {
                fetchMoreItems<GetRatingsResult, GetRatingsVariables>(
                  fetchMore,
                  {
                    query: GET_RATINGS_WITHDRIVER,
                    variables: { skip, first },
                    dataKey: 'ratingWithDriver',
                  },
                );
              }}
              onChangeRowsPerPage={(rowsPerPage) =>
                this.setState({ rowsPerPage })
              }
            />
          );
        }
        return null;
      }}
    </Query>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  content: { flex: 1, paddingTop: 20 },
  flexRow: {
    flexDirection: 'row',
  },
});
