import gql from 'graphql-tag';

export type AddUsageCreditPointPercentageResult = {
  id: string;
  usagePercentage: number;
};

export type AddUsageCreditPointPercentageParams = {
  data: number;
}

export const ADD_USAGE_CREDIT_POINT_PERCENTAGE = gql`
  mutation addUsagePercentageCreditPoint($data: Float!) {
    addUsagePercentageCreditPoint(usagePercentage: $data) {
      id
      usagePercentage
      createdAt
      updatedAt
    }
  }
`;
