import React, { useContext, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { compose, graphql, Mutation, MutationFn } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from '../../components';
import { BAHAMA_BLUE, LIGHT_RED, PRIMARY, RED } from '../../constants/colors';
import { UserContent } from '../../graphql/localState';
import { SAVE_USER_LOGIN, USER_LOGIN } from '../../graphql/queries';
import { graphqlErrorRemover } from '../../helpers';
import { AuthenticatedContext } from '../../routes/MainRoute';

import { Button, Text, TextField } from '../../core-ui';
import { LandingBackground } from './components';

export interface LoginSceneProps extends RouteComponentProps {
  loginSuccess: MutationFn<null, UserContent>;
}

export type LoginMutationResults = {
  cmsLogin: {
    token: string;
    provider: {
      id: string;
      role: string;
      name: string;
      email: string;
    };
  };
};

function LoginScene({ history, loginSuccess }: LoginSceneProps) {
  const [credential, setCredential] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });
  const refetch = useContext(AuthenticatedContext);

  const submitLoginForm = async (
    login: MutationFn<any, { email: string; password: string }>,
  ) => {
    const { email, password } = credential;

    try {
      await login({
        variables: {
          email,
          password,
        },
      });
    } catch (error) {
      // TODO: Handle error
      // console.log(error);
    }
  };

  const onEmailTextChange = (text: string) => {
    setCredential((prevCredential) => ({
      ...prevCredential,
      email: text,
    }));
  };

  const onPasswordTextChange = (text: string) => {
    setCredential((prevCredential) => ({
      ...prevCredential,
      password: text,
    }));
  };

  const renderErrorBox = (message: string) => (
    <View style={styles.errorBox}>
      <Text size="xsmall">{message}</Text>
    </View>
  );

  return (
    <LandingBackground>
      <Mutation<LoginMutationResults>
        mutation={USER_LOGIN}
        update={async (_, { data }) => {
          try {
            const loginData = data?.cmsLogin;
            const cmsData = loginData?.provider;
            await loginSuccess({
              variables: {
                userID: cmsData?.id || '',
                email: loginData?.provider.email || '',
                name: cmsData?.name || '',
                token: loginData?.token || '',
                role: cmsData?.role || '',
              },
            });
            refetch();
            document.location.href = '/';
          } catch (error) {
            // console.log(error);
          }
        }}
      >
        {(login, { loading, error }) => (
          <Form onSubmit={() => submitLoginForm(login)}>
            <Text size="xlarge" style={styles.header}>
              Selamat Datang di TVIP
            </Text>
            {error && renderErrorBox(graphqlErrorRemover(error.message))}
            <TextField
              stretch
              label="Email"
              value={credential.email}
              placeholder="Email address"
              onChangeText={onEmailTextChange}
              style={{
                container: { paddingBottom: 20, marginTop: 20 },
                textField: {
                  color: BAHAMA_BLUE,
                },
              }}
            />
            <TextField
              stretch
              secureTextEntry
              label="Password"
              value={credential.password}
              placeholder="Password"
              onChangeText={onPasswordTextChange}
              style={{
                container: { paddingBottom: 20 },
                textField: {
                  color: BAHAMA_BLUE,
                },
              }}
            />
            <View style={styles.forgotPasswordBox}>
              <TouchableOpacity
                onPress={() => history.push('/forgot-password')}
              >
                <Text
                  color={PRIMARY}
                  weight="light"
                  size="small"
                  style={styles.forgotPassword}
                >
                  Lupa kata sandi?
                </Text>
              </TouchableOpacity>
            </View>
            <View>
              <Button
                color="primary"
                stretch
                text="Masuk"
                style={styles.loginButton}
                onPress={() => submitLoginForm(login)}
                isLoading={loading}
              />
            </View>
          </Form>
        )}
      </Mutation>
    </LandingBackground>
  );
}

const styles = StyleSheet.create({
  errorBox: {
    padding: 10,
    borderWidth: 1,
    backgroundColor: LIGHT_RED,
    borderColor: RED,
    borderRadius: 3,
  },
  header: {
    paddingBottom: 20,
  },
  forgotPassword: {
    paddingBottom: 40,
  },
  loginButton: {
    padding: 0,
    margin: 0,
  },
  forgotPasswordBox: {
    alignItems: 'flex-end',
  },
});

export default compose(
  graphql<
    {},
    Pick<LoginSceneProps, 'loginSuccess'>,
    UserContent,
    Pick<LoginSceneProps, 'loginSuccess'>
  >(SAVE_USER_LOGIN, { name: 'loginSuccess' }),
)(LoginScene);
