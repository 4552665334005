import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Text } from '.';
import { Props as TextProps } from './Text';
import { DARK_GRAY, WHITE } from '../constants/colors';

type CardProps = {
  title: string;
  titleRightComponent?: ReactNode;
  style?: StyleProp<ViewStyle>;
  titleProps?: TextProps;
  description?: string;
  children?: ReactNode;
};

const LARGE_SPACE = 100;
const MEDIUM_SPACE = 40;
const SMALL_SPACE = 24;

function Card(props: CardProps) {
  const {
    title,
    titleRightComponent,
    titleProps,
    description,
    style,
    children,
    ...otherProps
  } = props;
  return (
    <View style={[styles.cardWrapper, style]} {...otherProps}>
      <View style={styles.title}>
        <Text size="large" {...titleProps} style={styles.flex}>
          {title}
        </Text>
        {titleRightComponent}
      </View>
      <View style={{ paddingVertical: SMALL_SPACE }}>
        {description && (
          <Text size="small" style={styles.desc}>
            {description}
          </Text>
        )}
      </View>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  title: { flexDirection: 'row', alignItems: 'center' },
  cardWrapper: {
    minHeight: 240,
    borderRadius: 2,
    backgroundColor: WHITE,
    paddingVertical: MEDIUM_SPACE,
    paddingHorizontal: LARGE_SPACE,
  },
  desc: {
    color: DARK_GRAY,
    lineHeight: 22,
  },
});

export default Card;
