import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from 'react-native';
import { getInitialName } from '../helpers';
import {
  AVATAR_SIZES,
  TITLE_SIZES,
  TITLE_WEIGHTS,
} from '../constants/avatarPreset';
import { PRIMARY, WHITE } from '../constants/colors';

type Props = {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  rounded?: boolean;
  source?: string;
  name?: string;
  style?: {
    container?: StyleProp<ViewStyle>;
    image?: StyleProp<ImageStyle>;
    title?: StyleProp<TextStyle>;
  };
  onPress?: () => void;
};

function Avatar(props: Props) {
  const { size, rounded, source, name, style, onPress } = props;
  const avatarSize = size || 'medium';
  const containerStyle = {
    height: AVATAR_SIZES[avatarSize],
    width: AVATAR_SIZES[avatarSize],
    borderRadius: rounded ? AVATAR_SIZES[avatarSize] / 2 : 0,
    backgroundColor: PRIMARY,
  };
  const titleStyle = {
    fontWeight: TITLE_WEIGHTS[avatarSize].toString(),
    fontSize: TITLE_SIZES[avatarSize],
    color: WHITE,
  };
  const disabled = !onPress;
  const content = source ? (
    <Image
      source={{ uri: source }}
      resizeMode="cover"
      style={[containerStyle as ImageStyle, style && style.image]}
    />
  ) : (
    <Text style={[titleStyle as TextStyle, style && style.title]}>
      {name ? getInitialName(name) : ''}
    </Text>
  );
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.flexCenter,
        containerStyle as ViewStyle,
        style && style.container,
      ]}
    >
      {content}
    </TouchableOpacity>
  );
}

export default Avatar;

const styles = StyleSheet.create({
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: WHITE,
  },
});
