import React, { Component, Fragment, ComponentType } from 'react';

import { View, StyleSheet } from 'react-native';
import { Mutation } from 'react-apollo';
import { makeStyles } from '@material-ui/styles';

import UserDetailModal from '../user/UserDetailModal';
import withToast, { ToastContextProps } from '../../helpers/withToast';

import { Icon, Button, Modal, Text } from '../../core-ui';
import { Table, RowsPerPage, Query, LoadMoreParams } from '../../components';
import { GRAY, PRIMARY, DARK_GRAY, BLACK } from '../../constants/colors';
import {
  TOPStatusListResult,
  UserWithTOP,
  GET_TOP_STATUS_LIST,
  AccessProps,
  TOPStatusListVars,
  GetOrderListResult,
  GetOrderListVariables,
  GET_ORDER_LIST,
  TopPaymentPeriod,
} from '../../graphql/queries';
import {
  formatThousandSeparator,
  formatFullDate,
  fetchMoreItems,
  getDueDate,
} from '../../helpers';
import { PaidOrderMutationResult, PAID_ORDER } from '../../graphql/mutations';
import { RefetchFn } from '../../routes/PageAccess';

type OwnProps = AccessProps & {
  searchContent: string;
};

type Props = ToastContextProps & OwnProps;

type State = {
  resetStatusPage: boolean;
  resetInvoicePage: boolean;
  rowsPerPage: RowsPerPage;
  rowsPerPageInvoice: RowsPerPage;
  isInvoiceListVisible: boolean;
  isSettleVisible: boolean;
  isDetailVisible: boolean;
  actionUserID: Nullable<string>;
  actionTransactionID: Nullable<string>;
  actionPaymentPeriodUser: Nullable<TopPaymentPeriod>;
  pageStatus: number;
  pageInvoice: number;
};

enum PaymentPeriodEnum {
  DAY0 = 0,
  DAY7 = 7,
  DAY10 = 10,
  DAY15 = 15,
  DAY20 = 20,
  DAY30 = 30,
  DAY40 = 40,
  DAY45 = 45,
}

export class SettlementList extends Component<Props, State> {
  state: State = {
    resetStatusPage: false,
    resetInvoicePage: false,
    rowsPerPage: 5,
    rowsPerPageInvoice: 5,
    isInvoiceListVisible: false,
    isSettleVisible: false,
    isDetailVisible: false,
    actionUserID: null,
    actionTransactionID: null,
    actionPaymentPeriodUser: null,
    pageStatus: 0,
    pageInvoice: 0,
  };

  _firstStatus = 0;

  _skipStatus = 0;

  _firstInvoice = 0;

  _skipInvoice = 0;

  _getStatusesQueryVariables = () => {
    const { searchContent } = this.props;
    return {
      where: {
        OR: [
          { storeName_contains: searchContent },
          { id_contains: searchContent },
        ],
      },
    };
  };

  _getInvoiceQueryVariables = () => {
    const { actionUserID } = this.state;
    if (actionUserID) {
      return { whereUserID: actionUserID };
    }
    return null;
  };

  componentDidUpdate(prevProps: Props) {
    const { searchContent: prevSearch } = prevProps;
    const { searchContent } = this.props;
    if (prevSearch !== searchContent) {
      this.setState({ resetStatusPage: true });
    }
  }

  render() {
    return (
      <Query<TOPStatusListResult, TOPStatusListVars>
        keyData="userTOPStatuses"
        query={GET_TOP_STATUS_LIST}
        variables={{
          ...this._getStatusesQueryVariables(),
          skip: 0,
          first: this.state.rowsPerPage,
        }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({
          data,
          refetch: refetchStatus,
          fetchMore: fetchMoreUsersTopStatuses,
          loading: statusLoading,
        }) => {
          if (data) {
            const fetchMoreTopStatuses = ({ first, skip }: LoadMoreParams) => {
              this._firstStatus = first;
              this._skipStatus = skip;
              fetchMoreItems<TOPStatusListResult, TOPStatusListVars>(
                fetchMoreUsersTopStatuses,
                {
                  query: GET_TOP_STATUS_LIST,
                  variables: {
                    ...this._getStatusesQueryVariables(),
                    first,
                    skip,
                  },
                  dataKey: 'userTOPStatuses',
                },
              );
            };
            const users = data.userTOPStatuses.filter(
              ({ totalPurchase }) => totalPurchase > 0,
            );

            return (
              <>
                {this._renderTable(
                  users,
                  data.count || 0,
                  fetchMoreTopStatuses,
                  statusLoading,
                )}
                {this._renderInvoiceList()}
                {this._renderSettle(users, refetchStatus)}
                {this._renderDetail(users)}
              </>
            );
          }
          return null;
        }}
      </Query>
    );
  }

  _renderTable = (
    data: Array<UserWithTOP>,
    count: number,
    fetchMoreUsersTopStatuses: (params: LoadMoreParams) => void,
    loading: boolean,
  ) => {
    const { rowsPerPage, pageStatus, resetStatusPage } = this.state;
    const { access } = this.props;
    return (
      <Table
        data={data}
        dataCount={count}
        page={pageStatus}
        onChangePage={(nextPage) => this.setState({ pageStatus: nextPage })}
        resetPage={resetStatusPage}
        setResetPage={(reset) => this.setState({ resetStatusPage: reset })}
        isLoading={loading}
        rowsPerPage={rowsPerPage}
        structure={{
          id: {
            headerTitle: 'Kode Pelanggan',
            alias: 'user.szID',
          },
          storeName: {
            headerTitle: 'Nama Toko',
            alias: 'user.storeName',
          },
          totalPurchase: {
            headerTitle: 'Total Pembelian',
            render: ({ totalPurchase }) => (
              <Text size="small" style={styles.cellText}>
                Rp. {formatThousandSeparator(totalPurchase)}
              </Text>
            ),
          },
          status: {
            headerTitle: 'Status',
          },
          actions: {
            noHeaderName: true,
            render: ({ user: { id, top } }) => (
              <View style={styles.actions}>
                <Icon
                  size="small"
                  name="description"
                  color={GRAY}
                  hoverColor={PRIMARY}
                  onPress={() => this._openDetail(id)}
                />
                {access.create && (
                  <Button
                    text="Daftar Invoice"
                    onPress={() =>
                      this._openInvoiceList(
                        id,
                        top ? top.paymentPeriod : 'DAY7',
                      )
                    }
                  />
                )}
              </View>
            ),
          },
        }}
        loadMore={fetchMoreUsersTopStatuses}
        onChangeRowsPerPage={(amountRows) =>
          this.setState({ rowsPerPage: amountRows, pageStatus: 0 })
        }
      />
    );
  };

  _openDetail = (id: string) =>
    this.setState({ isDetailVisible: true, actionUserID: id });

  _renderInvoiceList = () => {
    const {
      isInvoiceListVisible,
      rowsPerPageInvoice,
      resetInvoicePage,
      pageInvoice,
    } = this.state;
    const { access } = this.props;
    function InvoiceTable() {
      const extraStyles = useStyles();
      return (
        <Query<GetOrderListResult, GetOrderListVariables>
          keyData="orders"
          query={GET_ORDER_LIST}
          fetchPolicy="network-only"
          variables={{
            ...this._getInvoiceQueryVariables(),
            wherePaymentStatus: 'UNPAID',
            skip: 0,
            first: 10,
          }}
          notifyOnNetworkStatusChange
        >
          {({
            data: transactionData,
            fetchMore: fetchMoreTransaction,
            loading: transactionLoading,
          }) => {
            if (transactionData) {
              const fetchMoreTransactionFn = ({
                first,
                skip,
              }: LoadMoreParams) => {
                this._firstInvoice = first;
                this._skipInvoice = skip;
                fetchMoreItems<GetOrderListResult, GetOrderListVariables>(
                  fetchMoreTransaction,
                  {
                    query: GET_ORDER_LIST,
                    variables: {
                      ...this._getInvoiceQueryVariables(),
                      wherePaymentStatus: 'UNPAID',
                      first,
                      skip,
                    },
                    dataKey: 'invoices',
                  },
                );
              };
              return (
                <Table
                  data={transactionData!.orders}
                  dataCount={transactionData!.count || 0}
                  page={pageInvoice}
                  onChangePage={(nextPage) =>
                    this.setState({ pageInvoice: nextPage })
                  }
                  resetPage={resetInvoicePage}
                  setResetPage={(reset) =>
                    this.setState({ resetInvoicePage: reset })
                  }
                  isLoading={transactionLoading}
                  rowsPerPage={rowsPerPageInvoice}
                  extraStyles={extraStyles}
                  loadMore={fetchMoreTransactionFn}
                  onChangeRowsPerPage={(amountRows) => {
                    this.setState({
                      rowsPerPageInvoice: amountRows,
                      pageInvoice: 0,
                    });
                  }}
                  structure={{
                    invoiceNumber: { headerTitle: 'No. Invoice' },
                    date: {
                      headerTitle: 'Jatuh Tempo',
                      render: ({ invoiceDate }) => {
                        const paymentPeriod = Number(
                          PaymentPeriodEnum[
                            this.state.actionPaymentPeriodUser
                              ? this.state.actionPaymentPeriodUser
                              : 'DAY7'
                          ],
                        );
                        const dueDate = getDueDate(invoiceDate, paymentPeriod);
                        return (
                          <Text size="small" style={styles.cellText}>
                            {formatFullDate(dueDate)}
                          </Text>
                        );
                      },
                    },
                    totalPrice: {
                      headerTitle: 'Total',
                      render: ({ transactionItems }) => {
                        const totalPrice = transactionItems.reduce(
                          (acc, { price, priceCut, quantity }) =>
                            acc + (price - priceCut) * quantity,
                          0,
                        );
                        return (
                          <Text size="small" style={styles.cellText}>
                            Rp. {formatThousandSeparator(totalPrice)}
                          </Text>
                        );
                      },
                    },
                    action: {
                      noHeaderName: true,
                      render: ({ id }) =>
                        (access.update || true) && (
                          <Button
                            text="Lunas"
                            onPress={() =>
                              this.setState(
                                {
                                  isSettleVisible: true,
                                  actionTransactionID: id,
                                },
                                () =>
                                  this.setState({
                                    isInvoiceListVisible: false,
                                  }),
                              )
                            }
                          />
                        ),
                    },
                  }}
                />
              );
            }
            return null;
          }}
        </Query>
      );
    }
    return (
      <Modal
        title="Daftar Invoice"
        maxWidth="lg"
        isVisible={isInvoiceListVisible}
        onClose={this._closeInvoiceList}
      >
        <InvoiceTable />
      </Modal>
    );
  };

  _openInvoiceList = (id: string, paymentPeriod: TopPaymentPeriod) =>
    this.setState({
      isInvoiceListVisible: true,
      actionUserID: id,
      actionPaymentPeriodUser: paymentPeriod,
    });

  _closeInvoiceList = () =>
    this.setState({
      isInvoiceListVisible: false,
      actionUserID: null,
      actionPaymentPeriodUser: null,
    });

  _renderSettle = (
    data: Array<UserWithTOP>,
    refetchStatuses: RefetchFn<TOPStatusListResult, TOPStatusListVars>,
  ) => {
    const { openToast } = this.props;
    const { isSettleVisible, actionUserID, actionTransactionID } = this.state;
    const currentData = data.find(({ user: { id } }) => id === actionUserID);

    return (
      <Mutation<PaidOrderMutationResult, { id: string }>
        mutation={PAID_ORDER}
        onCompleted={async (result) => {
          if (result) {
            openToast('success', 'Invoice telah diubah menjadi lunas.');
            this._closeSettle();
            await refetchStatuses();
          }
        }}
        onError={() => openToast('fail', 'Invoice gagal diubah menjadi lunas.')}
        refetchQueries={[
          {
            query: GET_ORDER_LIST,
            variables: {
              whereUserID: actionUserID || '',
              wherePaymentStatus: 'UNPAID',
              first: this._firstInvoice,
              skip: this._skipInvoice,
            },
          },
        ]}
      >
        {(paidOrderProvider, { loading }) =>
          currentData ? (
            <Modal
              hideHeaderClose
              isVisible={isSettleVisible}
              title="Hapus Pelanggan TOP"
              closeButtonText="Tidak"
              buttonText="Lunas"
              submitLoading={loading}
              onSubmit={() =>
                paidOrderProvider({
                  variables: { id: actionTransactionID || '' },
                })
              }
              onClose={this._closeSettle}
            >
              <Text color={DARK_GRAY}>
                Apakah{' '}
                <Text weight="bold" color={BLACK}>
                  {currentData.user.name}{' '}
                </Text>
                sudah melunasi pembelian dengan jumlah Rp.{' '}
                <Text weight="bold" color={BLACK}>
                  {formatThousandSeparator(currentData.totalPurchase)}
                </Text>
                ?
              </Text>
            </Modal>
          ) : null
        }
      </Mutation>
    );
  };

  _openSettle = (id: string) =>
    this.setState({ isSettleVisible: true, actionTransactionID: id });

  _closeSettle = () =>
    this.setState({
      isSettleVisible: false,
      isInvoiceListVisible: true,
      actionTransactionID: null,
    });

  _renderDetail = (data: Array<UserWithTOP>) => {
    const { isDetailVisible, actionUserID } = this.state;
    const users = data.map(({ user }) => user);
    return (
      <UserDetailModal
        data={users}
        isVisible={isDetailVisible}
        dataID={actionUserID}
        onClose={() => this.setState({ isDetailVisible: false })}
      />
    );
  };
}

const styles = StyleSheet.create({
  emptyScene: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cellText: { letterSpacing: 1.5 },
});

const useStyles = makeStyles({
  row: { borderLeft: 0, borderRight: 0 },
  cell: {
    '&:last-child': { borderRight: 0 },
  },
});

export default withToast(SettlementList) as ComponentType<OwnProps>;
