import React, { FC } from 'react';
import { Mutation } from 'react-apollo';
import graphql from 'graphql-tag';
import { Button } from '../../../core-ui';
import withToast, { ToastContextProps } from '../../../helpers/withToast';

const REJECT_KTP = graphql`
  mutation RejectKTP($userId: ID!, $message: String!, $category: String!) {
    documentReject(
      where: { id: $userId }
      docType: KTP
      message: $message
      category: $category
    ) {
      id
    }
  }
`;

type RejectKtpButtonProps = {
  userId: string;
  category: string;
  message: string;
  closeModal?: () => void;
  onSuccess?: () => void;
  disabled: boolean;
};

const approveKtpExtended = (
  approveKtp: any,
  userId: string,
  segment: string,
  subSegment: string,
  szID: string,
) => {
  const authorization = 'YWRtaW46RGF0YWJhNTM=';

  const response = fetch(
    `https://modust.tvip.co.id/apidms/pelanggankdy?szId=${szID}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${authorization}`,
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      const szPriceSegmentID = data && data[0] ? data[0].szPriceSegmentId : '';
      if (szPriceSegmentID === '') {
        alert('Pelanggan belum terdaftar di DMS !!!');
      } else {
        approveKtp({
          variables: { userId, segment, subSegment, szID, szPriceSegmentID },
        });
      }
    });
};

function RejectKtpButton({
  userId,
  category,
  message,
  closeModal,
  onSuccess,
  disabled,
  openToast,
}: RejectKtpButtonProps & ToastContextProps) {
  return (
    <Mutation
      mutation={REJECT_KTP}
      onCompleted={() => {
        onSuccess && onSuccess();
        closeModal && closeModal();
        openToast('success', 'KTP telah ditolak.');
      }}
      onError={() => openToast('fail', 'KTP gagal ditolak.')}
    >
      {(rejectKtp, { loading }) => (
        <Button
          text="Kirim"
          disabled={disabled}
          isLoading={loading}
          onPress={() =>
            rejectKtp({ variables: { userId, category, message } })
          }
        />
      )}
    </Mutation>
  );
}

const APPROVE_KTP = graphql`
  mutation ApproveKtp(
    $userId: ID!
    $segment: String!
    $subSegment: String!
    $szID: String!
    $szPriceSegmentID: String!
  ) {
    documentApprove(
      where: { id: $userId }
      docType: KTP
      segment: $segment
      subSegment: $subSegment
      szID: $szID
      szPriceSegmentID: $szPriceSegmentID
    ) {
      id
    }
  }
`;

type ApproveKtpButtonProps = {
  userId: string;
  segment: string;
  subSegment: string;
  szID: string;
  closeModal?: () => void;
  onSuccess?: () => void;
  disabled: boolean;
};

function ApproveKtpButton({
  userId,
  segment,
  subSegment,
  szID,
  closeModal,
  onSuccess,
  disabled,
  openToast,
}: ApproveKtpButtonProps & ToastContextProps) {
  return (
    <Mutation
      mutation={APPROVE_KTP}
      onCompleted={() => {
        onSuccess && onSuccess();
        closeModal && closeModal();
        openToast('success', 'KTP Telah diterima.');
      }}
      onError={() => openToast('fail', 'KTP gagal diterima.')}
    >
      {(approveKtp, { loading }) => (
        <Button
          disabled={disabled}
          isLoading={loading}
          text="Konfirmasi"
          onPress={() =>
            approveKtpExtended(approveKtp, userId, segment, subSegment, szID)
          }
        />
      )}
    </Mutation>
  );
}

export const RejectKtpButtonWithToast = withToast(RejectKtpButton) as FC<
  RejectKtpButtonProps
>;
export const ApproveKtpButtonWithToast = withToast(ApproveKtpButton) as FC<
  ApproveKtpButtonProps
>;
