import gql from 'graphql-tag';

export type Faq = {
  id: string;
  title: string;
  content: string;
  active: boolean;
};
export type GetFaqsData = { faqs: Array<Faq> };
export const GET_FAQS = gql`
  query getFaqS {
    faqs(where: { active: true }) {
      id
      title
      content
      active
    }
  }
`;

export type GetFaqData = { faq: Faq };
export const GET_FAQ = gql`
  query getFaq($id: ID!) {
    faq(id: $id) {
      id
      title
      content
      active
    }
  }
`;
