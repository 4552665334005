import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';

import { CheckBox, Text } from '../core-ui';
import { Props as CheckBoxProps } from '../core-ui/CheckBox';
import { GRAY80 } from '../constants/colors';

type Props = {
  style?: StyleProp<ViewStyle>;
  text: string;
  textStyle?: StyleProp<TextStyle>;
} & CheckBoxProps;

export default class CheckBoxText extends Component<Props> {
  render() {
    const { style } = this.props;
    return (
      <View style={[styles.root, style]}>
        {this._renderCheckBox()}
        {this._renderText()}
      </View>
    );
  }

  _renderCheckBox = () => {
    const { disabled = false, iconSize, isSelected, onPress } = this.props;

    return (
      <CheckBox
        disabled={disabled}
        iconSize={iconSize}
        isSelected={isSelected}
        onPress={disabled ? () => {} : onPress}
      />
    );
  };

  _renderText = () => {
    const { text, textStyle, disabled } = this.props;
    return (
      <View style={[styles.leftMargin, textStyle]}>
        <Text color={disabled ? GRAY80 : ''}>{text}</Text>
      </View>
    );
  };
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  leftMargin: {
    marginLeft: 11,
  },
  disabled: {
    color: GRAY80,
  },
});
