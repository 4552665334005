import React, { ReactNode, Component } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';
import { Modal, Button, Loading } from '../core-ui';
import { BackButton } from '.';
import { WHITE } from '../constants/colors';

const LARGE_SPACE = 100;
const MEDIUM_SPACE = 40;

type Props = {
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  handleDiscard: () => void;
  children?: ReactNode;
  showBackButton?: boolean;
  handleGoBack?: () => void;
  leftSideBarChildren?: ReactNode;
  rightSideBarChildren?: ReactNode;
  submitTitle?: string;
  isDirty: boolean;
  isValid: boolean;
  handleSubmit: () => void;
  stickyLeftSideBar?: boolean;
  style?: {
    containerStyle?: StyleProp<ViewStyle>;
    mainStyle?: StyleProp<ViewStyle>;
  };
};

type State = {
  alert: { isVisible: boolean; action: string; onPress: Nullable<() => void> };
};

class FormPage extends Component<Props, State> {
  state: State = {
    alert: { isVisible: false, action: '', onPress: null },
  };

  render() {
    const { handleDiscard, style } = this.props;
    const { isVisible, onPress } = this.state.alert;
    return (
      <View style={[styles.contentWrapper, style && style.containerStyle]}>
        <Modal
          hideHeaderClose
          isVisible={isVisible}
          closeButtonText="Tidak"
          buttonText="Hapus"
          title="Hapus Perubahan?"
          description={`Apakah anda yakin untuk ${this.state.alert.action}? Semua perubahan tidak akan disimpan.`}
          onClose={() =>
            this.setState({
              alert: { isVisible: false, action: '', onPress: null },
            })
          }
          onSubmit={() => {
            onPress && onPress();
            this.setState({
              alert: { isVisible: false, action: '', onPress: null },
            });
            handleDiscard();
          }}
        />
        {this._renderLeftSideBar()}
        {this._renderContent()}
        {this._renderRightSideBar()}
      </View>
    );
  }

  _renderContent() {
    const {
      isLoading,
      isSubmitLoading,
      children,
      submitTitle,
      handleSubmit,
      isDirty,
      isValid,
      style,
    } = this.props;
    if (isLoading) {
      return this._renderLoading();
    }
    return (
      <View style={[styles.mainContent, style && style.mainStyle]}>
        {children}
        <View style={styles.submit}>
          <Button
            color="secondary"
            inverted
            text="Batal"
            onPress={() => {
              isDirty &&
                this.setState({
                  alert: { isVisible: true, action: 'batal', onPress: null },
                });
            }}
          />
          <Button
            isLoading={isSubmitLoading}
            style={styles.submitButton}
            disabled={!isDirty || !isValid}
            text={submitTitle || 'Simpan'}
            onPress={handleSubmit}
          />
        </View>
      </View>
    );
  }

  _renderLoading() {
    return (
      <View style={styles.loadingWrapper}>
        <Loading style={{ backgroundColor: 'transparent' }} />
      </View>
    );
  }

  _renderLeftSideBar() {
    const {
      showBackButton,
      leftSideBarChildren,
      stickyLeftSideBar = true,
    } = this.props;
    return (
      <View style={styles.leftSide}>
        <View style={stickyLeftSideBar && styles.sticky}>
          {showBackButton && <BackButton onPress={this._goBack} />}
          {leftSideBarChildren}
        </View>
      </View>
    );
  }

  _goBack = () => {
    const { handleGoBack, isDirty } = this.props;
    if (isDirty) {
      this.setState({
        alert: {
          isVisible: true,
          action: 'kembali ke halaman sebelumnya',
          onPress: () => handleGoBack && handleGoBack(),
        },
      });
    } else {
      handleGoBack && handleGoBack();
    }
  };

  _renderRightSideBar() {
    const { rightSideBarChildren } = this.props;
    return <View style={styles.rightSide}>{rightSideBarChildren}</View>;
  }
}

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: MEDIUM_SPACE,
    overflow: 'scroll',
  },
  leftSide: {
    flex: 1,
    marginRight: MEDIUM_SPACE,
  },
  rightSide: {
    flex: 1,
    marginLeft: MEDIUM_SPACE,
  },
  mainContent: {
    flex: 4,
    backgroundColor: WHITE,
    boxShadow: '1px 4px 20px -11px rgba(0,0,0,0.51)',
  },
  loadingWrapper: {
    backgroundColor: 'transparent',
    flex: 1,
    height: 500,
    width: 300,
  },
  submit: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: MEDIUM_SPACE,
    paddingRight: LARGE_SPACE,
    zIndex: -1,
  },
  submitButton: { marginLeft: 20 },
  sticky: ({ position: 'sticky', top: 0 } as unknown) as ViewStyle, // NOTE: because "sticky" is a web-only position
});

export default FormPage;
