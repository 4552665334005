import { PriceSegmentResult } from '../graphql/queries';

const getProductPrice = (
  id: string,
  retailPriceList: PriceSegmentResult,
  price: number,
  segmentPriceList?: Nullable<PriceSegmentResult>,
) => {
  let productData = segmentPriceList
    ? segmentPriceList.priceSegment.products.find(
        ({ product }) => product.id === id,
      )
    : null;
  if (productData && productData.price > 0) {
    return productData.price;
  }
  productData = retailPriceList.priceSegment.products.find(
    ({ product }) => product.id === id,
  );
  return productData && productData.price > 0 ? productData.price : price;
};

export default getProductPrice;
