import gql from 'graphql-tag';

const clusterObject = `
  {
    id
    name
    minRange
    maxRange
    unitMultiplier
    pointMultiplier
  }
`;

const clusterGroup = `
clusterGroup {
  diamond ${clusterObject}
  gold ${clusterObject}
  silver ${clusterObject}
  bronze ${clusterObject}
}
`;

const location = `
  {
    id
    province {
      id
      name
    }
    city {
      id
      name
    }
    district {
      id
      name
    }
    subDistrict {
      id
      name
    }
    postalCode
    latitude
    longitude
  }
`;

const members = `
  members {
    id
    szID
    email
    name
    storeName
    storeAddress
    storeTelephone
    phone
    phone2
    phone3
    whatsapp
    tvipCredit
    tvipPoint
    location ${location}
    active
  }
`;

const subSegment = `
  subSegment {
    id
    subSegmentCode
    title
    ${members}
    active
  }
`;

const subSegments = `
  subSegments {
    id
    subSegmentCode
    title
    ${members}
    active
  }
`;

const segments = `
  segments {
    id
    segmentID
    title
    ${subSegments}
    active
  }
`;

const channels = `
  channels {
    id
    channelID
    title
    ${segments}
    active
  }
`;

const top = `
  top {
    id
    userId
    contractNumber
    contractStart
    details
    paymentPeriod
    isCredit
    creditLimit
    invoiceType
    active
  }
`;

const depot = `
  depot {
    id
    title
    description
    cpName
    cpNumber
    coverage ${location}
  }
`;

const multipleDepot = `
  programDepot {
    id
    ${depot}
    maxQty
    limitType
  }
`;


const user = `
  user {
    id
    szID
    email
    name
    storeName
    storeAddress
    storeTelephone
    phone
    phone2
    phone3
    whatsapp
    tvipCredit
    tvipPoint
    location ${location}
    ${top}
    cluster {
      id
      currentCluster
    }
    ${depot}
    active
  }
`;

const rating = `
  rating {
    ${user}
    point
    review
  }
`;

const vehicleType = `
  vehicleType {
    id
    typeID
    typeName
    capacity
    info
    active
  }
`;

const vehicle = `
  vehicle {
    id
    ${vehicleType}
    brandName
    containerType
    loadType
    licensePlate
    vehicleStatus
    active
  }
`;

const driver = `
  driver {
    id
    driverCode
    driverName
    driverNIK
    displayPassword
    telephone
    gender
    birthDate
    joinDate
    resignDate
    ${depot}
    ${vehicle}
    active
  }
`;

const segment = `
  segment {
    id
    segmentID
    title
    ${subSegments}
    active
  }
`;

const product = `
  {
    id
    productID
    title
    nickname
    description
    stock
    photo
    dimension
    uom
    sku
    minimumStock
    defaultPrice
  }
`;

const products = `
  products ${product}
`;

const couponProducts = `
  products {
    id
    product ${product}
    quantity
  }
`;

const couponAdditionalProducts = `
  additionalProducts {
    id
    name
    quantity
  }
`;

const salesItems = `
  salesItems {
    id
    description
    ${products}
   }
`;

const transactionProducts = `
  products {
    productData ${product}
    unitQuantity
  }
  ${couponAdditionalProducts}
`;

const bottleGuarantee = `
  bottleGuarantee {
    id
    qty
    gallonType
    pricePerBottle
    active
  }
`;

const transactionItems = `
  transactionItems {
    id
    ${transactionProducts}
    description
    priceCut
    price
    quantity
  }
`;

const returnedTransactionItems = `
  returnedTransactionItems {
    id
    ${transactionItems.replace('transactionItems', 'transactionItem')}
    quantity
    returnReason
  }
`;

const canceledOrders = `
  canceledOrders {
    cancelReason
    deliveryItems {
      quantityDelivery
      transactionItem {
        id
        price
        priceCut
        ${transactionProducts}
      }
    }
  }
`;

const coupon = `
  coupon {
    id
    title
    description
    couponImage
    couponType
    couponRewardValue
    couponPrice
    couponLimit
    couponClaimCount
    couponValidDays
    startDate
    endDate
    createdAt
    active
  }
`;

const returnedItems = `
  returnedItems {
    id
    invoiceNumber
    ${returnedTransactionItems}
  }
`;

export const SUB_SEGMENT_FRAGMENT = gql`
  fragment SubSegmentData on SubSegment {
    id
    subSegmentCode
    title
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductCoupon on Product {
    id
  }
`;

export const MEMBERS_FRAGMENT = gql`
  fragment MemberData on User {
    id
    name
  }
`;

export const SEGMENT_FRAGMENT = gql`
  fragment SegmentData on Segment {
    id
    segmentID
    title
  }
`;

export const CHANNEL_FRAGMENT = gql`
  fragment ChannelData on Channel {
    id
    title
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment LocationData on Location {
    id
    province {
      id
      name
    }
    city {
      id
      name
    }
    district {
      id
      name
    }
    subDistrict {
      id
      name
    }
    postalCode
    latitude
    longitude
  }
`;

export const INVOICE_USER_FRAGMENT = gql`
  fragment InvoiceUserData on User {
    id
    email
    name
    storeName
    storeAddress
    storeTelephone
    phone
    phone2
    phone3
    whatsapp
    tvipCredit
    tvipPoint
    location {
      ...LocationData
    }
    active
  }
  ${LOCATION_FRAGMENT}
`;

export {
  bottleGuarantee,
  channels,
  depot,
  multipleDepot,
  coupon,
  driver,
  location,
  members,
  products,
  couponProducts,
  couponAdditionalProducts,
  rating,
  salesItems,
  segment,
  segments,
  subSegment,
  subSegments,
  top,
  transactionItems,
  returnedItems,
  returnedTransactionItems,
  user,
  vehicle,
  clusterObject,
  clusterGroup,
  canceledOrders,
};
