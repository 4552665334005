import React from 'react';

import { ChosenProps, Picker } from '.';
import { Option } from '../../core-ui';
import { ClusterName } from '../../graphql/queries';

const clusterOptions: Array<Option<ClusterName>> = [
  { value: 'DIAMOND', label: 'Diamond' },
  { value: 'GOLD', label: 'Gold' },
  { value: 'SILVER', label: 'Silver' },
  { value: 'BRONZE', label: 'Bronze' },
];

type ClusterPickerProps = { isFilter?: boolean } & ChosenProps;

export default function ClusterPicker(props: ClusterPickerProps) {
  return (
    <Picker
      label="Cluster"
      placeholder="Cluster"
      options={clusterOptions}
      {...props}
    />
  );
}
