import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';

export type GallonType = 'AQUA' | 'VIT';
export type GallonPrice = {
  id: string;
  gallonType: GallonType;
  price: number;
  active: boolean;
};

export type GallonPriceListResult = { gallonPrices: Array<GallonPrice> };
export type GallonPriceListData = DataValue<GallonPriceListResult, {}>;

export const GET_GALLON_PRICE_LIST = gql`
  query gallonPrices {
    gallonPrices {
      id
      gallonType
      price
      active
    }
  }
`;
