import React from 'react';
import { graphql } from 'react-apollo';

import {
  GET_PROVINCE_NAME_LIST,
  ProvinceListData,
} from '../../graphql/queries';

import { ChosenProps, Picker } from '.';

type ProvinceListProps = { provinceQuery: ProvinceListData };
type OwnProps = { isFilter?: boolean } & ChosenProps;
type Props = ProvinceListProps & OwnProps;

function ProvincePicker(props: Props) {
  const { provinceQuery, style, ...otherProps } = props;
  return (
    <Picker
      label="Provinsi"
      placeholder="Provinsi"
      style={style}
      isLoading={provinceQuery.loading}
      options={
        provinceQuery.provinces
          ? provinceQuery.provinces.map(({ id, name }) => ({
              value: id,
              label: name.toUpperCase(),
            }))
          : []
      }
      {...otherProps}
    />
  );
}

export default graphql<OwnProps, ProvinceListProps, {}, ProvinceListProps>(
  GET_PROVINCE_NAME_LIST,
  { name: 'provinceQuery' },
)(ProvincePicker);
