import React, { Component, Fragment } from 'react';

import { View, StyleSheet } from 'react-native';
import { withRouter, RouteComponentProps } from 'react-router';

import UserDetailModal from '../user/UserDetailModal';

import { Icon } from '../../core-ui';
import {
  Table,
  RowsPerPage,
  Query,
  DEFAULT_ROWS_PER_PAGE,
} from '../../components';
import { GRAY, PRIMARY } from '../../constants/colors';
import {
  GET_USERS,
  User,
  GetUsersResult,
  AccessProps,
  GetUsersParams,
} from '../../graphql/queries';
import { fetchMoreItems } from '../../helpers';
import { TOP_TABLE_STRUCTURE } from './constants/topTableStructure';

type Props = RouteComponentProps &
  AccessProps & {
    searchContent: string;
  };

type State = {
  resetPage: boolean;
  rowsPerPage: RowsPerPage;
  isDetailVisible: boolean;
  actionDataID: Nullable<string>;
  page: number;
};

export class NonTopList extends Component<Props, State> {
  state: State = {
    resetPage: false,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    isDetailVisible: false,
    actionDataID: null,
    page: 0,
  };

  componentDidUpdate(prevProps: Props) {
    const { searchContent: prevSearch } = prevProps;
    const { searchContent } = this.props;
    if (prevSearch !== searchContent) {
      this.setState({ resetPage: true });
    }
  }

  render() {
    const { rowsPerPage: firstCount } = this.state;

    const queryVariables = {
      where: this._getWhereVariable(),
      first: firstCount,
      skip: 0,
    };

    return (
      <Query<GetUsersResult>
        query={GET_USERS}
        variables={queryVariables}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({ loading, data, fetchMore }) => {
          if (data) {
            const userList = data.users.filter(({ top }) => !top);
            return (
              <>
                {this._renderTable(userList, fetchMore, data.count, loading)}
                {this._renderDetail(userList)}
              </>
            );
          }
        }}
      </Query>
    );
  }

  _renderTable = (
    data: Array<User>,
    fetchMore: (args: any) => void,
    dataCount: number,
    loading: boolean,
  ) => {
    const { history, access } = this.props;
    const { rowsPerPage, page, resetPage } = this.state;
    return (
      <Table
        data={data}
        dataCount={dataCount}
        page={page}
        onChangePage={(nextPage) => this.setState({ page: nextPage })}
        resetPage={resetPage}
        setResetPage={(reset) => this.setState({ resetPage: reset })}
        isLoading={loading}
        rowsPerPage={rowsPerPage}
        structure={{
          ...TOP_TABLE_STRUCTURE,
          actions: {
            noHeaderName: true,
            render: ({ id, szID }) => (
              <View style={styles.actions}>
                {access.create && (
                  <Icon
                    size="small"
                    name="person_add"
                    color={GRAY}
                    hoverColor={PRIMARY}
                    onPress={() => history.push(`/tops/${id}/assign`, { szID })}
                  />
                )}
                <Icon
                  size="small"
                  name="description"
                  color={GRAY}
                  hoverColor={PRIMARY}
                  onPress={() =>
                    this.setState({ isDetailVisible: true, actionDataID: id })
                  }
                />
              </View>
            ),
          },
        }}
        loadMore={({ skip, first }) => {
          fetchMoreItems<GetUsersResult, GetUsersParams>(fetchMore, {
            query: GET_USERS,
            variables: {
              where: this._getWhereVariable(),
              first,
              skip,
            },
            dataKey: 'users',
          });
        }}
        onChangeRowsPerPage={(amountRows) =>
          this.setState({ rowsPerPage: amountRows, page: 0 })
        }
      />
    );
  };

  _renderDetail = (data: Array<User>) => {
    const { isDetailVisible, actionDataID } = this.state;
    return (
      <UserDetailModal
        data={data}
        isVisible={isDetailVisible}
        dataID={actionDataID}
        onClose={() => this.setState({ isDetailVisible: false })}
      />
    );
  };

  _getWhereVariable = () => {
    const { searchContent } = this.props;
    const searchText = searchContent.toLowerCase();
    return {
      approvalKtp: 'APPROVED',
      top: null,
      OR: [
        {
          name_contains: searchText,
        },
        {
          phone_contains: searchText,
        },
        {
          storeName_contains: searchText,
        },
        {
          id_contains: searchText,
        },
      ],
    };
  };
}

const styles = StyleSheet.create({
  emptyScene: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});

export default withRouter(NonTopList);
